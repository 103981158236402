import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Message,
  TextArea,
  Segment,
  Header,
  Table,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class FaqSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    id: this.props.id,
    trailId: this.props.trailId,
    isLoading: false,
    form: {
      sequence: null,
      content: '',
      answer: '',
      steps: []
    },
    stepList: [],
    stepListSelected: [],
    lang: this.props.lang,
    langList: this.props.langList,
    langDefault: this.props.langDefault,
    formLang: {
      content: '',
      answer: ''
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.validateFields = this.validateFields.bind(this)
    this.getSelectedSteps = this.getSelectedSteps.bind(this)
    this.getStepListSelected = this.getStepListSelected.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormLangChange = this.handleFormLangChange.bind(this)
    this.receivedFaqLang = this.receivedFaqLang.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      var stepList = this.props.getFaqStepList()
      var stepListSelected
      if (this.props.viewMode === 'EDIT') {
        stepListSelected = this.getStepListSelected(
          stepList,
          this.props.editData.steps
        )
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          if (this.props.editData != null) {
            state.id = this.props.id
            state.form.sequence = this.props.editData.sequence
            state.form.content = this.props.editData.content
            state.form.answer = this.props.editData.answer
            state.form.steps = this.props.editData.steps
            state.stepList = stepList
            state.stepListSelected = stepListSelected
          }
          return state
        })
      } else {
        stepListSelected = this.getStepListSelected(stepList, null)
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.form.sequence = this.props.nextSequence
          state.form.content = ''
          state.form.answer = ''
          state.form.steps = []
          state.stepList = stepList
          state.stepListSelected = stepListSelected
          return state
        })
      }
    }
  }

  getStepListSelected(stepList, steps) {
    var stepListSelected = []
    for (var i = 0; i < stepList.length; i++) {
      var isSelected = false
      if (steps != null && steps.length > 0) {
        var stepId = parseInt(stepList[i].id)
        for (var y = 0; y < steps.length; y++) {
          if (stepId === steps[y]) {
            isSelected = true
            break
          }
        }
      }
      stepListSelected.push(isSelected)
    }
    return stepListSelected
  }

  getSelectedSteps() {
    var steps = []
    for (var i = 0; i < this.state.stepListSelected.length; i++) {
      if (
        this.state.stepListSelected[i] === true &&
        this.state.stepList[i] !== null
      ) {
        steps.push(parseInt(this.state.stepList[i].id))
      }
    }
    return steps
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        trailId: this.state.trailId,
        sequence: this.state.form.sequence,
        content: this.state.form.content,
        answer: this.state.form.answer,
        steps: this.getSelectedSteps()
      }
      if (this.props.viewMode === 'EDIT') {
        dataToSend.id = this.state.id
      }
      RequestManager.requestAPI(
        '/admin/trailfaq/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (this.validateFields()) {
      RequestManager.requestAPI(
        '/admin/trailfaq/save-lang',
        {
          id: this.state.id,
          language: this.state.lang,
          contentLang: this.state.formLang.content,
          answerLang: this.state.formLang.answer
        },
        this.receivedResponse
      ).then(()=> {
        this.setState(state => {
          state.lang = this.state.langDefault
          state.formLang.content = ''
          state.formLang.answer = ''
          return state
        })
      })
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  handleFormIndexCheck(event, index, fieldName) {
    this.setState(function (state) {
      if (fieldName === 'stepListSelected') {
        state.stepListSelected[index] = !state.stepListSelected[index]
      }
      return state
    })
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.content !== null &&
      this.state.form.content.length > 10 &&
      this.state.form.answer !== null &&
      this.state.form.answer.length > 10
    )
  }

  viewFaqLang(language) {
    RequestManager.requestAPI(
      '/admin/trailfaq/view-lang',
      {
        id: this.state.id,
        language
      },
      this.receivedFaqLang
    )
  }

  receivedFaqLang(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.formLang.content = data.contentLang !== null ? data.contentLang : ''
        state.formLang.answer = data.answerLang !== null ? data.answerLang : ''
        return state
      })
    }
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'large'
        }
      >
        <Modal.Header>
        <Grid relaxed columns="2">
            <Grid.Column width={9}>
              <Icon name="question" />
              {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} FAQ
            </Grid.Column>
            <Grid.Column textAlign="right" width={7}>
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon="world"
                size="tinny"
                value={this.state.lang}
                options={this.state.langList}
                placeholder="Choose Language"
                onChange={(event, { value }) => {
                  this.viewFaqLang(value)

                  this.setState((state) => {
                    state.lang = value
                    return state
                  })
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>
                Frequently asked question not registered
              </Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns={this.state.lang !== this.state.langDefault ? 2 : 1}>
              <Grid.Column>
                <Form.Field required>
                  <label>Question</label>
                  <TextArea
                    disabled={this.state.isLoading || this.state.lang !== this.state.langDefault}
                    icon="tag"
                    value={this.state.form.content}
                    onChange={(event) => {
                      this.handleFormChange(event, 'content')
                    }}
                    style={{ minHeight: 100 }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault &&
                <Grid.Column>
                  <Form.Field required>
                    <label>Question</label>
                    <TextArea
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.content}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'content')
                      }}
                      style={{ minHeight: 100 }}
                    />
                  </Form.Field>
                </Grid.Column>
              }
            </Grid>

            <Grid relaxed columns={this.state.lang !== this.state.langDefault ? 2 : 1}>
              <Grid.Column>
                <Form.Field required>
                  <label>Answer</label>
                  <TextArea
                    disabled={this.state.isLoading || this.state.lang !== this.state.langDefault}
                    icon="tag"
                    value={this.state.form.answer}
                    onChange={(event) => {
                      this.handleFormChange(event, 'answer')
                    }}
                    style={{ minHeight: 200 }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault &&
                <Grid.Column>
                  <Form.Field required>
                    <label>Answer</label>
                    <TextArea
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.answer}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'answer')
                      }}
                      style={{ minHeight: 200 }}
                    />
                  </Form.Field>
                </Grid.Column>
              }
            </Grid>
            {this.state.lang === this.state.langDefault &&
              <Grid relaxed columns="1">
                {this.state.stepList.length > 0 && (
                  <Grid.Column>
                    <Segment
                      secondary
                      compact
                      size="small"
                      attached="top"
                      disabled={this.state.isLoading}
                    >
                      <Grid relaxed columns="equal">
                        <Grid.Column
                          floated="left"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Header as="h4" size="small">
                            <Header.Content>Related steps</Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <Table
                      attached
                      compact="very"
                      singleLine
                      size="small"
                      className="LINK_UNDERLINE TABLE_FIXED"
                    >
                      <Table.Body>
                        {this.state.stepList.map(function (step, index) {
                          return (
                            <Table.Row key={step.id}>
                              <Table.Cell width={1}>
                                <Checkbox
                                  checked={this.state.stepListSelected[index]}
                                  onChange={(event) => {
                                    this.handleFormIndexCheck(
                                      event,
                                      index,
                                      'stepListSelected'
                                    )
                                  }}
                                />
                              </Table.Cell>
                              <Table.Cell>{step.title}</Table.Cell>
                            </Table.Row>
                          )
                        }, this)}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                )}
              </Grid>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
        <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.content = ''
                  state.formLang.answer = ''
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default FaqSave
