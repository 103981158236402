import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import { Icon, Header } from 'semantic-ui-react'
import './Tapume.css'

class Tapume extends React.Component {
  render() {
    document.title = 'Dashboard :: Pragma School'
    return (
      <>
        <div className="TAPUME_CONTAINER">
          <div className="TAPUME_CONTENT">
            <Icon name="rocket" size="massive" />
            <Header as="h1">Welcome!</Header>
            <Header as="h4">
              Choose a category on the side to get started.
            </Header>
          </div>
        </div>
      </>
    )
  }
}

export default Tapume
