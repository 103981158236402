import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import './FileView.css'

import moment from 'moment'
import 'moment/locale/pt-br'

import RequestManager from '../RequestManager'
import FileSave from './FileSave'

class FileView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    fileData: {},
    deleteModal: {
      isOpen: false
    },
    isModalOpen: false
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveFile = this.confirmRemoveFile.bind(this)
    this.fileDeleted = this.fileDeleted.bind(this)
    this.receivedFileData = this.receivedFileData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    moment.locale('pt-BR')
  }

  componentDidMount() {
    this.loadFileData()
  }

  loadFileData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/file/view',
      {
        id: this.state.id
      },
      this.receivedFileData
    )
  }

  receivedFileData(hasError, receivedData) {
    if (hasError) {
      this.goBackToFileList()
    } else {
      this.setState(function (state) {
        state.fileData = receivedData
        state.isLoading = false
        return state
      })
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  removeFile(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveFile() {
    RequestManager.requestAPI(
      '/admin/file/remove',
      {
        id: this.state.id
      },
      this.fileDeleted
    )
  }

  fileDeleted() {
    this.goBackToFileList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  goBackToFileList() {
    window.location.href = '/file/list'
  }

  displayDatetime(fieldTimestamp) {
    return this.state.fileData[fieldTimestamp] != null
      ? moment(this.state.fileData[fieldTimestamp]).format('lll')
      : '-'
  }

  onDoneModal() {
    this.loadFileData()
  }

  render() {
    document.title = 'Documents :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="file" />
                    <Header.Content>
                      {this.state.fileData.title}
                      <Header.Subheader>Document</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Code</label>
                      <div className="FORM_CONTENT">
                        {this.state.fileData.externalId}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Name</label>
                      <div className="FORM_CONTENT">
                        {this.state.fileData.name}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Path</label>
                      <div className="FORM_CONTENT">
                        <a
                          href={this.state.fileData.externalPath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.state.fileData.externalPath}
                        </a>
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Type</label>
                      <div className="FORM_CONTENT">
                        {this.state.fileData.mimeType}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Extension</label>
                      <div className="FORM_CONTENT">
                        {this.state.fileData.extension}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Created by</label>
                      <div className="FORM_CONTENT">
                        {this.state.fileData.createdBy.firstName +
                          ' ' +
                          this.state.fileData.createdBy.lastName}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Creation date</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('creationDate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>
          </div>

          <FileSave
            viewMode="EDIT"
            editData={this.state.fileData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => this.setModalVisible(false)}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this document?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveFile}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default FileView
