import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message
} from 'semantic-ui-react'

import RequestManager from './RequestManager'

class ChangePassword extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    errorTitle: '',
    errorMessage: '',
    errorField: '',
    form: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      this.setState(function (state) {
        state.viewMode = this.props.viewMode
        state.form.oldPassword = ''
        state.form.newPassword = ''
        state.form.confirmPassword = ''
        return state
      })
    }
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (this.validateFields()) {
      var dataToSend = {
        oldPassword: this.state.form.oldPassword,
        newPassword: this.state.form.newPassword
      }
      RequestManager.requestAPI(
        '/change-password',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(error, data) {
    var hasError = false
    var errorField = ''
    var errorTitle = ''
    var errorMessage = ''
    if (error) {
      if (error === 404) {
        hasError = true
        errorField = 'oldPassword'
        errorTitle = 'Invalid password!'
        errorMessage = 'The password entered does not match, please try again.'
      } else if (error === 402) {
        hasError = true
        errorField = 'newPassword'
        errorTitle = 'New Invalid Password!'
        errorMessage =
          'The new password entered is not valid, please try again.'
      }
    }
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = hasError
      state.errorField = errorField
      state.errorTitle = errorTitle
      state.errorMessage = errorMessage
      return state
    })
    if (!hasError) {
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    var hasError = false
    var errorField = null
    var errorTitle = null
    var errorMessage = null
    if (this.state.form.oldPassword <= '') {
      hasError = true
      errorField = 'oldPassword'
      errorTitle = 'Enter current password'
      errorMessage = 'Please fill in the required fields and try again.'
    } else if (this.state.form.newPassword <= '') {
      hasError = true
      errorField = 'newPassword'
      errorTitle = 'Enter the new password'
      errorMessage = 'Please fill in the required fields and try again.'
    } else if (
      this.state.form.newPassword !== this.state.form.confirmPassword
    ) {
      hasError = true
      errorField = 'confirmPassword'
      errorTitle = 'Invalid password confirmation'
      errorMessage =
        'Password confirmation must be the same as the new password.'
    }
    if (hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        state.errorField = errorField
        state.errorTitle = errorTitle
        state.errorMessage = errorMessage
        return state
      })
      return false
    }
    return true
  }

  handleFormChange(event, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[fieldName] = data
      return state
    })
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="lock" />
          Change Password
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>{this.state.errorTitle}</Message.Header>
              <p>{this.state.errorMessage}</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field
                  required
                  error={this.state.errorField === 'oldPassword'}
                >
                  <label>Current password</label>
                  <Input
                    maxLength="100"
                    disabled={this.state.isLoading}
                    icon="lock"
                    type="password"
                    value={this.state.form.oldPassword}
                    onChange={(event) => {
                      this.handleFormChange(event, 'oldPassword')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  required
                  error={this.state.errorField === 'newPassword'}
                >
                  <label>New Password</label>
                  <Input
                    maxLength="100"
                    disabled={this.state.isLoading}
                    icon="lock"
                    type="password"
                    value={this.state.form.newPassword}
                    onChange={(event) => {
                      this.handleFormChange(event, 'newPassword')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid relaxed columns="2">
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Form.Field
                  required
                  error={this.state.errorField === 'confirmPassword'}
                >
                  <label>Confirm the Password</label>
                  <Input
                    maxLength="100"
                    disabled={this.state.isLoading}
                    icon="lock"
                    type="password"
                    value={this.state.form.confirmPassword}
                    onChange={(event) => {
                      this.handleFormChange(event, 'confirmPassword')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ChangePassword
