import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Pagination,
  Segment,
  Table,
  Grid,
  Loader,
  Dimmer,
  Modal,
  Input,
  Dropdown
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import RequestManager from '../RequestManager'
import QuizQuestionSave from './QuizQuestionSave'

class QuizQuestionList extends React.Component {
  state = {
    isModalOpen: false,
    isLoading: false,
    questions: [],
    limit: 30,
    offset: 0,
    total: 0,
    totalPages: 0,
    activePage: 1,
    isListLoaded: false,
    search: '',
    filterQuestionType: '',
    filterCategoryId: null,
    categoryList: [],
    questionTypeList: [
      { key: 'MM', value: 'MM', text: 'MultipleAnswer' },
      { key: 'MS', value: 'MS', text: 'SingleAnswer' },
      { key: 'BT', value: 'BT', text: 'TrueFalse' },
      { key: 'BY', value: 'BY', text: 'YesNo' },
      { key: 'RA', value: 'RA', text: 'Agreement' },
      { key: 'RI', value: 'RI', text: 'Importance' },
      { key: 'RS', value: 'RS', text: 'Satisfaction' },
      { key: 'R0', value: 'R0', text: 'Scale 0-5' },
      { key: 'R1', value: 'R1', text: 'Scale 1-10' },
      { key: 'OL', value: 'OL', text: 'Open Line' },
      { key: 'OT', value: 'OT', text: 'Open Text' }
    ],
    deleteModal: {
      isOpen: false,
      id: null,
      name: '',
      email: ''
    }
  }

  constructor() {
    super()
    this.setModalVisible = this.setModalVisible.bind(this)
    this.receivedQuizQuestionList = this.receivedQuizQuestionList.bind(this)
    this.paginationClickHandler = this.paginationClickHandler.bind(this)
    this.refreshActivePage = this.refreshActivePage.bind(this)
    this.loadQuizCategoryList = this.loadQuizCategoryList.bind(this)
    this.receivedQuizCategoryData = this.receivedQuizCategoryData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveQuizQuestion = this.confirmRemoveQuizQuestion.bind(this)
    this.quizQuestionDeleted = this.quizQuestionDeleted.bind(this)
    this.loadSearch = this.loadSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  onDoneModal() {
    this.loadQuizQuestionList()
  }

  componentDidMount() {
    this.loadQuizQuestionList()
    this.loadQuizCategoryList()
  }

  loadQuizQuestionList() {
    RequestManager.requestAPI(
      '/admin/quiz/question/list',
      {
        profile: 'STDNT',
        offset: (this.state.activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedQuizQuestionList
    )
  }

  receivedQuizQuestionList(hasError, data) {
    this.updateStateQuizQuestions(data)
  }

  updateStateQuizQuestions(data) {
    this.setState(function (state) {
      state.isLoading = false
      state.isListLoaded = true
      state.questions = data.questions
      state.total = data.total
      state.totalPages = Math.ceil(data.count / this.state.limit)
      return state
    })
  }

  paginationClickHandler(e, { activePage }) {
    RequestManager.requestAPI(
      '/admin/quiz/question/list',
      {
        categoryId:
          this.state.filterCategoryId !== null
            ? parseInt(this.state.filterCategoryId)
            : null,
        questionType: this.state.filterQuestionType,
        search: this.state.search,
        offset: (activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedQuizQuestionList
    )
    this.refreshActivePage(activePage)
  }

  loadSearch() {
    RequestManager.requestAPI(
      '/admin/quiz/question/list',
      {
        categoryId:
          this.state.filterCategoryId !== null
            ? parseInt(this.state.filterCategoryId)
            : null,
        questionType: this.state.filterQuestionType,
        search: this.state.search,
        offset: 0,
        limit: this.state.limit
      },
      this.receivedQuizQuestionList
    )
    this.refreshActivePage(1)
  }

  clearSearch() {
    RequestManager.requestAPI(
      '/admin/quiz/question/list',
      {
        offset: 0,
        limit: this.state.limit,
        search: ''
      },
      this.receivedQuizQuestionList
    )
    this.setState(function (state) {
      state.filterQuestionType = ''
      state.filterCategoryId = null
      state.search = ''
      return state
    })
    this.refreshActivePage(1)
  }

  loadQuizCategoryList() {
    RequestManager.requestAPI(
      '/admin/quiz/category/list',
      {
        offset: 0,
        limit: this.state.limit,
        search: ''
      },
      this.receivedQuizCategoryData
    )
  }

  receivedQuizCategoryData(hasError, receivedData) {
    if (hasError) {
      return
    } else if (receivedData.categories !== null) {
      var categoryList = []
      for (var i = 0; i < receivedData.categories.length; i++) {
        var categoryId = receivedData.categories[i].id + ''
        categoryList.push({
          key: categoryId,
          value: categoryId,
          text: receivedData.categories[i].title
        })
      }
      this.setState(function (state) {
        state.categoryList = categoryList
        return state
      })
    }
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state[name] = data
      } else {
        state[name] = null
      }
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state[name] = data
      return state
    })
  }

  refreshActivePage(activePage) {
    this.setState(function (state) {
      state.activePage = activePage
      return state
    })
  }

  removeQuizQuestion(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveQuizQuestion() {
    RequestManager.requestAPI(
      '/admin/quiz/question/remove',
      {
        id: this.state.deleteModal.id
      },
      this.quizQuestionDeleted
    )
  }

  quizQuestionDeleted(hasError, data) {
    this.loadQuizQuestionList()
    this.closeDeleteModal()
  }

  openDeleteModal(index) {
    var selectedQuizQuestion = this.state.questions[index]
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      state.deleteModal.name =
        selectedQuizQuestion.firstName + ' ' + selectedQuizQuestion.lastName
      state.deleteModal.email = selectedQuizQuestion.email
      state.deleteModal.id = selectedQuizQuestion.id
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  getQuizQuestionType(type) {
    for (var i = 0; i < this.state.questionTypeList.length; i++) {
      if (this.state.questionTypeList[i].value === type) {
        return this.state.questionTypeList[i].text
      }
    }
    return ''
  }

  render() {
    document.title = 'Questions :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment color="black">
              <Grid relaxed columns="equal" className="PAGE_HEADER">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="question" />
                    <Header.Content>Questions</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    floated="right"
                    icon="plus"
                    content="New"
                    color="black"
                    size="small"
                    onClick={() => this.setModalVisible(true)}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed>
                <Grid.Column width={5}>
                  <Dropdown
                    value={this.state.filterCategoryId}
                    fluid
                    selection
                    placeholder="Category..."
                    onChange={(event, { value }) =>
                      this.handleValueChange(value, 'filterCategoryId')
                    }
                    options={this.state.categoryList}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Dropdown
                    value={this.state.filterQuestionType}
                    fluid
                    selection
                    placeholder="Type..."
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'filterQuestionType')
                    }}
                    options={this.state.questionTypeList}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                    }}
                  >
                    <Input size="mini" placeholder="Search" fluid action>
                      <input
                        value={this.state.search}
                        onChange={(event) => {
                          this.handleFormChange(event, 'search')
                        }}
                      />
                      <Button icon="search" onClick={this.loadSearch} />
                      <Button basic icon="eraser" onClick={this.clearSearch} />
                    </Input>
                  </form>
                </Grid.Column>
                <Grid.Column floated="right" width={3}>
                  <Pagination
                    disabled={this.state.totalPages <= 0}
                    floated="right"
                    size="mini"
                    boundaryRange={0}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={2}
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.paginationClickHandler}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            {this.state.questions &&
              <Table
                attached="bottom"
                compact
                singleLine
                color="black"
                size="small"
                className="LINK_UNDERLINE TABLE_FIXED"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                    <Table.HeaderCell width={9}>Questions</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Category</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.questions.length === 0 && this.state.isListLoaded && (
                    <Table.Row>
                      <Table.Cell colSpan="4" singleLine>
                        <Header
                          size="medium"
                          style={{ textAlign: 'center', padding: 10 }}
                        >
                          No records to be displayed.
                          <Header.Subheader>
                            Clear your search or enter a record.
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {this.state.questions.map(function (question, index) {
                    return (
                      <Table.Row key={question.id}>
                        <Table.Cell width={1}>
                          <Link to={'/quiz/question/view/' + question.id}>
                            {question.externalId}
                          </Link>
                        </Table.Cell>
                        <Table.Cell width={9} className="FORM_CONTENT">
                          {question.content}
                        </Table.Cell>
                        <Table.Cell with={3} className="FORM_CONTENT">
                          {question.categoryTitle}
                        </Table.Cell>
                        <Table.Cell with={1}>
                          {this.getQuizQuestionType(question.type)}
                        </Table.Cell>
                        <Table.Cell with={1}>
                          <Button
                            icon="trash"
                            basic
                            size="tiny"
                            floated="right"
                            color="red"
                            onClick={() => this.removeQuizQuestion(index)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }, this)}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5">
                      <Pagination
                        disabled={this.state.totalPages <= 0}
                        floated="right"
                        size="mini"
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={2}
                        totalPages={this.state.totalPages}
                        activePage={this.state.activePage}
                        onPageChange={this.paginationClickHandler}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            }
          </div>

          <QuizQuestionSave
            viewMode="NEW"
            open={this.state.isModalOpen}
            onDone={(id) => {
              this.onDoneModal(id)
            }}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this issue?"
            />
            <Modal.Content>
              <p>
                <i>{this.state.deleteModal.externalId}</i>
                <br />
                <b>{this.state.deleteModal.content}</b>
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveQuizQuestion}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default QuizQuestionList
