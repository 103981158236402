import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import './UserView.css'

import moment from 'moment'
import 'moment/locale/pt-br'

import RequestManager from '../RequestManager'
import UserSave from './UserSave'

class UserList extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    userData: {
      firstName: '',
      lastName: ''
    },
    deleteModal: {
      isOpen: false
    },
    isModalOpen: false
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveUser = this.confirmRemoveUser.bind(this)
    this.userDeleted = this.userDeleted.bind(this)
    this.receivedUserData = this.receivedUserData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    moment.locale('pt-BR')
  }

  componentDidMount() {
    this.loadUserData()
  }

  loadUserData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/user/view',
      {
        id: this.state.id
      },
      this.receivedUserData
    )
  }

  receivedUserData(hasError, receivedData) {
    if (hasError) {
      this.goBackToUserList()
    } else {
      this.setState(function (state) {
        state.userData = receivedData
        state.isLoading = false
        return state
      })
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  removeUser(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveUser() {
    RequestManager.requestAPI(
      '/admin/user/remove',
      {
        id: this.state.id
      },
      this.userDeleted
    )
  }

  userDeleted(hasError, data) {
    this.goBackToUserList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  goBackToUserList() {
    window.location.href = '/user/list'
  }

  getProfileName() {
    switch (this.state.userData.profile) {
      case 'ADMIN':
        return 'Admin'
      case 'STDNT':
        return 'Student'
      default:
        return ''
    }
  }

  getGenderName() {
    switch (this.state.userData.gender) {
      case 'F':
        return 'Female'
      case 'M':
        return 'Male'
      default:
        return 'Other'
    }
  }

  getStatusName() {
    switch (this.state.userData.status) {
      case 'A':
        return 'Active'
      case 'B':
        return 'Blocked'
      case 'C':
        return 'Canceled'
      case 'E':
        return 'Expired'
      default:
        return ''
    }
  }

  displayDatetime(fieldTimestamp) {
    return this.state.userData[fieldTimestamp] != null
      ? moment(this.state.userData[fieldTimestamp]).format('lll')
      : '-'
  }

  onDoneModal() {
    this.loadUserData()
  }

  render() {
    document.title = this.state.userData.firstName + ' :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="user" />
                    <Header.Content>
                      {this.state.userData.firstName}{' '}
                      {this.state.userData.lastName}
                      <Header.Subheader>User</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Email</label>
                      <div className="FORM_CONTENT">
                        {this.state.userData.email}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Profile</label>
                      <div className="FORM_CONTENT">
                        {this.getProfileName()}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Gender</label>
                      <div className="FORM_CONTENT">{this.getGenderName()}</div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Status</label>
                      <div className="FORM_CONTENT">{this.getStatusName()}</div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last Login</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastSignon')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Creation Date</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('creationDate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last Update</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastUpdate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
              <Segment disabled={this.state.isLoading}>
                <Header as="h3">Permissions</Header>
                <Grid relaxed columns="5">
                  <Grid.Column>
                    <Form.Checkbox
                      checked={this.state.userData.securityManagement}
                      readOnly
                      label="User"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Checkbox
                      checked={this.state.userData.studentAdministration}
                      readOnly
                      label="Student"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Checkbox
                      checked={this.state.userData.journeyMentorship}
                      readOnly
                      label="Mentor"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Checkbox
                      checked={this.state.userData.contentManagement}
                      readOnly
                      label="Content"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Checkbox
                      checked={this.state.userData.recruiterSupport}
                      readOnly
                      label="Recruiter"
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Segment>
          </div>

          <UserSave
            viewMode="EDIT"
            editData={this.state.userData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this user?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveUser}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default UserList
