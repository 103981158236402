import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Message,
  Dropdown,
  Input
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class QuizCategorySave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    trailId: this.props.trailId,
    isLoading: false,
    form: {
      hasError: false,
      categoryId: null,
      questions: null,
      points: null,
      sequence: null
    },
    categoryList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadCategoryList = this.loadCategoryList.bind(this)
    this.receivedCategoryList = this.receivedCategoryList.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          if (this.props.editData != null) {
            state.form.categoryId = this.props.editData.id
            state.form.questions = this.props.editData.questions
            state.form.points = this.props.editData.points
            state.form.sequence = this.props.editData.sequence
            state.categoryList = [
              {
                key: this.props.editData.id,
                value: this.props.editData.id,
                text:
                  this.props.editData.externalId +
                  ' - ' +
                  this.props.editData.title
              }
            ]
          }
          return state
        })
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.form.categoryId = null
          state.form.questions = null
          state.form.points = null
          state.form.sequence = this.props.nextSequence
          return state
        })
        this.loadCategoryList('')
      }
    }
  }

  loadCategoryList(searchText) {
    RequestManager.requestResourceList(
      'quiz/category',
      searchText,
      this.receivedCategoryList
    )
  }

  receivedCategoryList(hasError, data) {
    var categories = []
    if (!hasError) {
      for (var index in data.categories) {
        categories.push({
          key: data.categories[index].id,
          value: data.categories[index].id,
          text:
            data.categories[index].externalId +
            ' - ' +
            data.categories[index].title
        })
      }
    }
    this.setState(function (state) {
      state.categoryList = categories
      return state
    })
  }

  handleFormChange(event, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[fieldName] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        trailId: parseInt(this.state.trailId),
        categoryId: parseInt(this.state.form.categoryId),
        questions: parseInt(this.state.form.questions),
        points: parseInt(this.state.form.points),
        sequence: parseInt(this.state.form.sequence)
      }
      RequestManager.requestAPI(
        '/admin/trailquiz/category/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.categoryId !== null &&
      this.state.form.questions !== null &&
      this.state.form.points !== null
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="globe" />
          {this.state.viewMode === 'EDIT' ? 'Edit' : 'Add'} Category
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Category not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="1">
              <Grid.Column>
                <Form.Field required>
                  <label>Category</label>
                  <Dropdown
                    disabled={
                      this.state.isLoading || this.state.viewMode === 'EDIT'
                    }
                    value={this.state.form.categoryId}
                    fluid
                    selection
                    search
                    clearable
                    onSearchChange={(event, { searchQuery }) => {
                      this.loadCategoryList(searchQuery)
                    }}
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'categoryId')
                    }}
                    options={this.state.categoryList}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Questions</label>
                  <Input
                    disabled={this.state.isLoading}
                    value={this.state.form.questions}
                    type="number"
                    onChange={(event) => {
                      this.handleFormChange(event, 'questions', 3)
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Points</label>
                  <Input
                    disabled={this.state.isLoading}
                    value={this.state.form.points}
                    type="number"
                    onChange={(event) => {
                      this.handleFormChange(event, 'points', 4)
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default QuizCategorySave
