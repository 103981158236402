import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Button,
  Form,
  Grid,
  Segment,
  TextArea,
  Label,
  Message
} from 'semantic-ui-react'
import RequestManager from '../RequestManager'

class SkillGenerator extends React.Component {
  state = {
    isLoading: false,
    hasError: false,
    candidate_id: [],
    count: 0
  }

  constructor(state) {
    super(state)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  generateSkills() {
    if (this.state.candidate_id.length > 0) {
      this.setLoadingState(true)
      let promise = Promise.resolve()
      this.state.candidate_id.forEach((candidateId) => {
        promise = promise.then(() => {
          RequestManager.requestAPI(
            '/admin/skill/create',
            { candidateId },
            this.receivedResponse
          )

          return new Promise((resolve) => {
            setTimeout(resolve, 2000)
          })
        })
      })
      this.setLoadingState(false)
    }
  }

  receivedResponse(hasError, data) {
    const pre = document.getElementById('list')
    const div = document.createElement('div')

    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
    }

    if (data.candidate_id) {
      div.appendChild(
        document.createTextNode(
          `Skills created for the candidate_id: ${data.candidate_id}`
        )
      )
      pre.appendChild(div)
      this.setCountState(this.state.count + 1)
    }
  }

  setCandidates(candidates) {
    this.setState(function (state) {
      state.candidate_id =
        candidates !== ''
          ? candidates.split(',').map((candidate) => Number(candidate))
          : []
      return state
    })
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  setCountState(count) {
    this.setState(function (state) {
      state.count = count
      return state
    })
  }

  clearIds() {
    const pre = document.getElementById('list')
    const skills = document.getElementById('skills')
    pre.innerText = ''
    skills.value = ''
    this.setCandidates('')
    this.setCountState(0)
  }

  render() {
    document.title = 'Skills Generator :: Pragma School'
    return (
      <Segment placeholder>
        <Message>
          <Message.Header>To generate the skills you must!</Message.Header>
          <Message.List>
            <Message.Item>
              Insert candidate_ids separated by "," (comma)
            </Message.Item>
            <Message.Item>Click generate</Message.Item>
          </Message.List>
        </Message>
        <Grid columns={3}>
          <Grid.Column width={9}>
            <Form>
              <TextArea
                placeholder="Example: 1001, 1002..."
                rows={3}
                id="skills"
                style={{ display: 'flex', maxWidth: '100%' }}
                onBlur={(event) => this.setCandidates(event.target.value)}
              />
            </Form>
          </Grid.Column>
          {this.state.candidate_id.length > 0 && (
            <Grid.Column width={2}>
              <Button
                loading={this.state.isLoading}
                onClick={() => {
                  this.generateSkills()
                }}
              >
                Generate
              </Button>
            </Grid.Column>
          )}
        </Grid>

        <Segment.Group>
          <Segment>
            <Button
              compact
              size="small"
              floated="right"
              onClick={() => {
                this.clearIds()
              }}
            >
              Clear
            </Button>
            Skills generated for{' '}
            {this.state.count > 0 ? (
              <Label circular>{this.state.count}</Label>
            ) : (
              0
            )}{' '}
            candidate(s).
          </Segment>
          <Segment secondary>
            <pre id="list"></pre>
          </Segment>
        </Segment.Group>
      </Segment>
    )
  }
}

export default SkillGenerator
