import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Button,
  Grid,
  Segment,
  Select,
  Message,
  TextArea,
  Input
} from 'semantic-ui-react'
import RequestManager from '../RequestManager'
import './styles.css'

class ImportExport extends React.Component {
  state = {
    isLoading: false,
    hasError: false,
    isJourney: false,
    journeyId: null,
    trailId: null,
    show: false,
    action: '',
    fileData: '',
    fileTitle: '',
    options: []
  }

  constructor(state) {
    super(state)
    this.importData = this.importData.bind(this)
    this.exportData = this.exportData.bind(this)
    this.loadJourneyList = this.loadJourneyList.bind(this)
    this.loadTrailList = this.loadTrailList.bind(this)
    this.receivedJourneyData = this.receivedJourneyData.bind(this)
    this.receivedTrailData = this.receivedTrailData.bind(this)
    this.receivedImportData = this.receivedImportData.bind(this)
    this.receivedExportData = this.receivedExportData.bind(this)
    this.generateFile = this.generateFile.bind(this)
    this.clearData = this.clearData.bind(this)
  }

  importData() {
    const file = document.querySelector('#text').value
    console.log(file);
    // RequestManager.requestAPI(
    //   '/admin/dataloader/content-import',
    //   {file},
    //   this.receivedImportData
    // )
  }

  exportData() {
    this.setState(state => {
      state.isLoading = true
      return state
    })
    RequestManager.requestAPI(
      '/admin/dataloader/content-export',
      {
        journeyId: this.state.journeyId,
        trailId: this.state.trailId
      },
      this.receivedExportData
    )
  }

  loadJourneyList() {
    RequestManager.requestAPI(
      '/admin/journey/list',
      {},
      this.receivedJourneyData
    )
  }

  loadTrailList() {
    RequestManager.requestAPI(
      '/admin/trail/list',
      {limit: 400},
      this.receivedTrailData
    )
  }

  receivedJourneyData(hasError, data) {
    if(!hasError) {
      this.setState(state => {
        state.options = data.journeys.map(journey => {
          return { key: journey.id, value: journey.id, text: journey.externalId + ' - ' + journey.title}
        })
        return state
      })
    }
  }

  receivedTrailData(hasError, data) {
    if(!hasError) {
      this.setState(state => {
        state.options = data.trails.map(trail => {
          return { key: trail.id, value: trail.id, text: trail.externalId + ' - ' + trail.title}
        })
        return state
      })
    }
  }

  receivedExportData(hasError, data) {
    this.setState(state => {
      state.isLoading = false
      return state
    })

    const txtArea = document.querySelector("#text")

    txtArea.value = data.data

    txtArea.setAttribute('disabled', true)
  }

  receivedImportData(hasError, data) {}

  receivedFile(event) {
    const files = event.target.files
    const txtArea = document.querySelector("#text")

    const reader = new FileReader()

    reader.onload = (event) => {
      txtArea.value = event.target.result
    }
    reader.readAsText(files[0])
    txtArea.setAttribute('disabled', true)
  }

  clearData() {
    const txtArea = document.querySelector("#text")
    if(txtArea) {
      txtArea.value = ''
      txtArea.removeAttribute('disabled')
    }
  }

  generateFile() {

    const data = document.querySelector('#text').value
    const textToBLOB = new Blob([data], { type: 'json/application' })
    const sFileName = (this.state.fileTitle ? this.state.fileTitle  : 'file') + '.json'

    let newLink = document.createElement("a")
    newLink.download = sFileName

    if (window.webkitURL !== null) {
      newLink.href = window.webkitURL.createObjectURL(textToBLOB)
    }
    else {
      newLink.href = window.URL.createObjectURL(textToBLOB)
      newLink.style.display = "none"
      document.body.appendChild(newLink)
    }

    newLink.click()
  }

  render() {
    return (
      <Segment placeholder>
        <Message>
          <Message.Header>Import or Export data</Message.Header>
          <Message.List>
            <Message.Item>Select action Import or Export</Message.Item>
            <Message.Item>If action was Import, choose a file in *.json* format or past the code on the text area and click on import</Message.Item>
            <Message.Item>If action was Export, choose the type ( journey or trail ) and then select your journey or trail and click on request</Message.Item>
          </Message.List>
        </Message>
        <Grid columns={this.state.action === 'Export' ? 4 : 3}>
          <Grid.Column>
            <Select
              placeholder="Select the action"
              value={this.state.action}
              options={[
                { key: 'Export', value: 'Export', text: 'Export' },
                { key: 'Import', value: 'Import', text: 'Import' }
              ]}
              style={{ display: 'flex', maxWidth: '100%' }}
              onChange={(_, {value}) => {
                this.setState(state => {
                  state.action = value
                  state.show = value === 'Import'
                  return state
                })

                if(value === 'Import') this.clearData()
              }}
            />
          </Grid.Column>
          {this.state.action === 'Export' &&
            <Grid.Column>
              <Select
                placeholder="Select the type"
                id="type"
                options={[
                  { key: 'Journey', value: 'Journey', text: 'Journey' },
                  { key: 'Trail', value: 'Trail', text: 'Trail' }
                ]}
                style={{ display: 'flex', maxWidth: '100%' }}
                onChange={(_, {value}) => {
                  if(value === 'Journey') this.loadJourneyList()
                  else if( value === 'Trail') this.loadTrailList()
                  this.setState(state => {
                    state.isJourney = value === 'Journey'
                    return state
                  })
                }}
              />
            </Grid.Column>
          }
          {this.state.action === 'Export' &&
            <Grid.Column>
              <Select
                placeholder="Choose a option"
                search
                id="option"
                options={this.state.options}
                style={{ display: 'flex', maxWidth: '100%' }}
                onChange={(event, {value}) => {
                  const title = event.currentTarget.textContent
                  this.setState(state => {
                    state.journeyId = this.state.isJourney ? value : null
                    state.trailId = this.state.isJourney ? null : value
                    state.fileTitle = title
                    return state
                  })
                }}
              />
            </Grid.Column>
          }
          {this.state.action === 'Export' &&
            <Grid.Column>
              <Button
                loading={this.state.isLoading}
                content='Request'
                floated='right'
                onClick={()=> {
                  this.exportData()
                  this.setState(state => {
                    state.show = true
                    return state
                  })
                }}
              />
            </Grid.Column>
          }
          {this.state.action === 'Import' &&
            <Grid.Column>
              <Input
                type='file'
                onChange={this.receivedFile}
              />
            </Grid.Column>
          }
          {this.state.action === 'Import' &&
            <Grid.Column >
              <Button
                icon='upload'
                loading={this.state.isLoading}
                onClick={this.importData}
                floated='right'
              />
            </Grid.Column>
          }
        </Grid>

        {this.state.show &&
          <Segment.Group>
            <Segment>
              <Grid columns="2">
                <Grid.Column>
                  Content: {this.state.fileTitle ? this.state.fileTitle : 'File'}
                </Grid.Column>
                <Grid.Column>
                  <Button.Group
                    basic size='small'
                    floated='right'
                    style={{ marginBottom: '1rem' }}
                  >
                    {this.state.action === 'Export' &&
                      <>
                        <Button
                          icon='copy'
                          id='download'
                          onClick={() => {
                            const txtArea = document.querySelector("#text")
                            navigator.clipboard.writeText(txtArea.value)
                          }}
                        />
                        <Button
                          icon='download'
                          id='download'
                          onClick={this.generateFile}
                        />
                      </>
                    }
                    {this.state.action === 'Import' &&
                      <Button
                        compact
                        size="small"
                        onClick={this.clearData}
                        floated="right"
                        icon="eraser"
                      />
                    }
                  </Button.Group>
                </Grid.Column>
              </Grid>
              <TextArea
                rows={20}
                id="text"
              />
            </Segment>
          </Segment.Group>
        }
      </Segment>
    )
  }
}

export default ImportExport
