import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Table,
  Ref
} from 'semantic-ui-react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './JourneyView.css'

import DatetimeManager from '../DatetimeManager'
import RequestManager from '../RequestManager'

import JourneySave from './JourneySave'
import JourneyTrailSave from './JourneyTrailSave'

class JourneyView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    journeyData: {},
    trails: [],
    trailViewMode: 'NEW',
    trailId: null,
    usedTrailsList: [],
    isTrailModalOpen: false,
    trailMaxSequence: 0,
    trailNextSequence: 0,
    needTrailListRefresh: false,
    deleteModal: {
      isOpen: false
    },
    trailDeleteModal: {
      isOpen: false,
      id: null
    },
    langDefault: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    lang: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    langList: this.props.languages.map((lang) => {
      return {
        key: lang.code,
        value: lang.code,
        text: lang.description
      }
    })
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.journeyDeleted = this.journeyDeleted.bind(this)
    this.receivedJourneyData = this.receivedJourneyData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.loadTrailData = this.loadTrailData.bind(this)
    this.receivedTrailData = this.receivedTrailData.bind(this)
    this.openTrailDeleteModal = this.openTrailDeleteModal.bind(this)
    this.journeyDeleted = this.journeyDeleted.bind(this)
    this.saveOrder = this.saveOrder.bind(this)
    this.receivedSequenceUpdate = this.receivedSequenceUpdate.bind(this)
    this.onDoneJourneyModal = this.onDoneJourneyModal.bind(this)
    this.confirmRemoveJourney = this.confirmRemoveJourney.bind(this)
    this.journeyDeleted = this.journeyDeleted.bind(this)
    this.confirmRemoveTrail = this.confirmRemoveTrail.bind(this)
    this.trailDeleted = this.trailDeleted.bind(this)
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const data = this.reorder(
      this.state.trails,
      result.source.index,
      result.destination.index
    )
    this.setState(function (state) {
      state.trails = data
      state.needTrailListRefresh = true
      return state
    })
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      background: isDragging && '#EFEFEF',
      border: isDragging && '1px solid #777777',
      ...draggableStyle
    }
  }

  componentDidMount() {
    this.loadJourneyData()
  }

  loadTrailData() {
    RequestManager.requestAPI(
      '/admin/journeytrail/list',
      {
        journeyId: this.state.id
      },
      this.receivedTrailData
    )
  }

  receivedTrailData(hasError, receivedData) {
    if (!hasError) {
      var trails = [],
        trailMaxSequence = 0,
        usedTrailsList = []
      for (var index in receivedData.journeyTrails) {
        var trail = receivedData.journeyTrails[index]
        var key = trail.journeyId + '-' + trail.trailId,
          trailId = parseInt(trail.trailId),
          sequence = trail.sequence
        trails.push({
          key: key,
          id: trail.trailId + '',
          sequence: sequence,
          tagAlong: trail.tagAlong,
          externalId: trail.trailExternalId,
          title: trail.trailTitle,
          type: trail.trailType,
          projectId: trail.projectId,
          projectTitle: trail.projectTitle,
          completeData: trail
        })
        if (usedTrailsList.indexOf(trailId) < 0) {
          usedTrailsList.push(trailId)
        }
        if (sequence > trailMaxSequence) {
          trailMaxSequence = sequence
        }
      }
      this.setState(function (state) {
        state.isLoading = false
        state.trails = trails
        state.trailMaxSequence = trailMaxSequence
        state.usedTrailsList = usedTrailsList

        return state
      })
    }
  }

  openTrailDeleteModal(index) {
    var selectedTrail = this.state.trails[index]
    this.setState(function (state) {
      state.trailDeleteModal.isOpen = true
      state.trailDeleteModal.id = selectedTrail.id
      state.trailDeleteModal.name =
        selectedTrail.externalId + ' - ' + selectedTrail.title
      return state
    })
  }

  loadJourneyData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/journey/view',
      {
        id: this.state.id
      },
      this.receivedJourneyData
    )
  }

  receivedJourneyData(hasError, receivedData) {
    if (hasError) {
      this.goBackToJourneyList()
    } else {
      this.setState(function (state) {
        state.journeyData = receivedData
        state.isLoading = false
        return state
      })
      this.loadTrailData()
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  setModalTrailVisible(isVisible, viewMode, stepType, completeData) {
    var nextSequence = this.state.trailMaxSequence + 1
    this.setState(function (state) {
      state.trailViewMode = viewMode ? viewMode : 'NEW'
      if (completeData) {
        state.trailId = completeData.trailId
      } else {
        state.trailId = null
      }
      state.trailData = completeData
      state.trailNextSequence = nextSequence
      state.isTrailModalOpen = isVisible
      return state
    })
  }

  removeJourney(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveJourney() {
    RequestManager.requestAPI(
      '/admin/journey/remove',
      {
        id: this.state.id
      },
      this.journeyDeleted
    )
  }

  journeyDeleted(hasError, data) {
    this.goBackToJourneyList()
  }

  confirmRemoveTrail() {
    RequestManager.requestAPI(
      '/admin/journeytrail/remove',
      {
        journeyId: parseInt(this.state.id),
        trailId: parseInt(this.state.trailDeleteModal.id)
      },
      this.trailDeleted
    )
  }

  trailDeleted(hasError, data) {
    if (hasError) {
      this.goBackToJourneyList()
    } else {
      this.closeDeleteModal()
      this.loadTrailData()
    }
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      state.trailDeleteModal.isOpen = false
      return state
    })
  }

  goBackToJourneyList() {
    window.location.href = '/journey/list'
  }

  displayType(type) {
    switch (type) {
      case 'D':
        return 'Discovery'
      case 'C':
        return 'Challenge'
      case 'Q':
        return 'Quiz'
      default:
        return '-'
    }
  }

  displayTagAlong(tagAlong) {
    if (tagAlong) {
      return 'Tag Along'
    }
    return 'Pattern'
  }

  displayDatetime(value) {
    return value != null ? DatetimeManager.formatTimestamp(value) : '-'
  }

  displayDuration(minutes) {
    if (minutes != null) {
      var hours = 0
      if (minutes >= 60) {
        hours = parseInt(minutes / 60)
        minutes = minutes % 60
      }
      return (
        (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '') +
        (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : '')
      )
    }
    return null
  }

  onDoneModal() {
    this.loadJourneyData()
  }

  onDoneJourneyModal() {
    this.loadTrailData()
  }

  saveOrder() {
    var toSend = {
      journeytrails: []
    }
    for (var i = 0; i < this.state.trails.length; i++) {
      toSend.journeytrails.push({
        journeyId: parseFloat(this.state.id),
        trailId: parseFloat(this.state.trails[i].id),
        sequence: i + 1
      })
    }
    RequestManager.requestAPI(
      '/admin/journeytrail/sequenceupdate',
      toSend,
      this.receivedSequenceUpdate
    )
  }

  receivedSequenceUpdate(hasError, data) {
    if (!hasError) {
      this.loadTrailData()
      this.setState(function (state) {
        state.needTrailListRefresh = false
        return state
      })
    }
  }

  render() {
    document.title = 'Journey :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="globe" />
                    <Header.Content>
                      {this.state.journeyData.title}
                      <Header.Subheader>Journey</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="4">
                  <Grid.Column>
                    <Form.Field>
                      <label>Code</label>
                      <div className="FORM_CONTENT">
                        {this.state.journeyData.externalId}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Time (estimated)</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(
                          this.state.journeyData.estimatedTime
                        )}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Project Environment</label>
                      <div>
                        {this.state.journeyData.workspaceExternalId > ' '
                          ? this.state.journeyData.workspaceExternalId +
                            ' - ' +
                            this.state.journeyData.workspaceTitle
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Description</label>
                      <div>{this.state.journeyData.description}</div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Prerequisite</label>
                      <div>
                        {this.state.journeyData.prerequisiteExternalId > ' '
                          ? this.state.journeyData.prerequisiteExternalId +
                            ' - ' +
                            this.state.journeyData.prerequisiteTitle
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="4">
                  <Grid.Column>
                    <Form.Field>
                      <label>Online Time</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(
                          this.state.journeyData.onlineHours
                        )}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Working Time</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(this.state.journeyData.workHours)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Credits (hours)</label>
                      <div className="FORM_CONTENT">
                        {this.state.journeyData.creditHours}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="4">
                  <Grid.Column>
                    <Form.Field>
                      <label>Last Update</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime(
                          this.state.journeyData.lastUpdate
                        )}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Updated by</label>
                      <div className="FORM_CONTENT">
                        {this.state.journeyData.updatedBy}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column
                  floated="left"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Header as="h4">
                    <Icon name="road" />
                    <Header.Content>Trails</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    basic
                    icon="edit"
                    content="New"
                    color="black"
                    size="tiny"
                    onClick={() => {
                      this.setModalTrailVisible(
                        true,
                        'NEW',
                        'knowledgeSteps',
                        null
                      )
                    }}
                  />
                  {this.state.needTrailListRefresh && (
                    <Button
                      floated="right"
                      basic
                      icon="save"
                      content="Save Order"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.saveOrder()
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Segment>

            <DragDropContext
              onDragEnd={(result) => {
                this.onDragEnd(result)
              }}
            >
              <Table
                attached
                compact
                singleLine
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Link</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Project</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Time</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Release</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="tableBody">
                  {(provided, snapshot) => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {this.state.trails.map(function (trail, index) {
                          return (
                            <Draggable
                              key={trail.key}
                              draggableId={trail.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Ref innerRef={provided.innerRef}>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={1}>
                                      {trail.sequence}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                      {trail.externalId}
                                    </Table.Cell>
                                    <Table.Cell className="FORM_CONTENT">
                                      <span
                                        onClick={() => {
                                          this.setModalTrailVisible(
                                            true,
                                            'EDIT',
                                            '',
                                            trail.completeData
                                          )
                                        }}
                                      >
                                        {trail.title}
                                      </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <a
                                        href={`/trail/view/${trail.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Icon name="external alternate" />
                                      </a>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {this.displayType(trail.type)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {trail.projectTitle}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                      {this.displayDuration(
                                        trail.completeData.estimatedTime
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {this.displayTagAlong(trail.tagAlong)}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                      <Button
                                        icon="trash"
                                        basic
                                        size="tiny"
                                        floated="right"
                                        color="red"
                                        onClick={() =>
                                          this.openTrailDeleteModal(index)
                                        }
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Ref>
                              )}
                            </Draggable>
                          )
                        }, this)}
                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </div>

          <JourneySave
            viewMode="EDIT"
            editData={this.state.journeyData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => this.setModalVisible(false)}
            lang={this.state.lang}
            langDefault={this.state.langDefault}
            langList={this.state.langList}
          />

          <JourneyTrailSave
            id={this.state.trailId}
            journeyId={this.state.id}
            viewMode={this.state.trailViewMode}
            usedTrailsList={this.state.usedTrailsList}
            editData={this.state.trailData}
            nextSequence={this.state.trailNextSequence}
            open={this.state.isTrailModalOpen}
            onDone={this.onDoneJourneyModal}
            onCancelClick={() => this.setModalTrailVisible(false)}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this journey?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveJourney}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.trailDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header icon="trash" content="Do you want to remove this Track?" />
            <Modal.Content>
              <h2>{this.state.trailDeleteModal.name}</h2>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveTrail}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default JourneyView
