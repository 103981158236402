import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Message,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class JourneySave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    id: this.props.id,
    ventureId: this.props.ventureId,
    usedJourneysList: [],
    isLoading: false,
    form: {
      hasError: false,
      type: 'D',
      journeyId: null
    },
    journeyList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadJourneyList = this.loadJourneyList.bind(this)
    this.receivedJourneyList = this.receivedJourneyList.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.type = this.props.type
          state.usedJourneysList = this.props.usedJourneysList
          if (this.props.editData != null) {
            state.id = this.props.id
            state.form.type = this.props.editData.type
            state.form.journeyId = this.props.editData.journeyId
            state.form.sequence = this.props.editData.sequence
          }
          return state
        })
        this.loadJourneyList(
          this.props.editData !== null &&
            this.props.editData.journeyExternalId !== null
            ? this.props.editData.journeyExternalId
            : ''
        )
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.usedJourneysList = this.props.usedJourneysList
          state.form.type = 'D'
          state.form.journeyId = null
          state.form.sequence = this.props.nextSequence
          return state
        })
        this.loadJourneyList('')
      }
    }
  }

  loadJourneyList(searchText) {
    RequestManager.requestResourceList(
      'journey',
      searchText,
      this.receivedJourneyList
    )
  }

  receivedJourneyList(hasError, data) {
    var journeys = []
    if (!hasError) {
      for (var video in data.journeys) {
        if (
          (this.props.viewMode === 'NEW' &&
            this.state.usedJourneysList.indexOf(data.journeys[video].id) < 0) ||
          this.props.viewMode === 'EDIT'
        ) {
          journeys.push({
            key: data.journeys[video].id,
            value: data.journeys[video].id,
            text:
              data.journeys[video].externalId +
              ' - ' +
              data.journeys[video].title
          })
        }
      }
    }
    this.setState(function (state) {
      state.journeyList = journeys
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        type: this.state.form.type,
        sequence: parseInt(this.state.form.sequence),
        ventureId: parseInt(this.state.ventureId),
        journeyId: parseInt(this.state.form.journeyId)
      }
      if (this.props.viewMode === 'EDIT') {
        dataToSend.journeyId = parseInt(this.state.id)
      }
      RequestManager.requestAPI(
        '/admin/venturejourney/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return this.state.form.type > ' ' && this.state.form.journeyId !== null
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="globe" />
          {(this.state.viewMode === 'EDIT' ? 'Edit' : 'New') + ' Journey'}
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Unregistered journey</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Type</label>
                  <Dropdown
                    disabled={this.state.isLoading}
                    value={this.state.form.type}
                    fluid
                    selection
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'type')
                    }}
                    options={[
                      { key: 'D', value: 'D', text: 'Discover' },
                      { key: 'I', value: 'I', text: 'Ignite' },
                      { key: 'A', value: 'A', text: 'Accelerate' },
                      { key: 'L', value: 'L', text: 'Launch' }
                    ]}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Journeys</label>
                  <Dropdown
                    disabled={
                      this.state.isLoading || this.state.viewMode === 'EDIT'
                    }
                    value={this.state.form.journeyId}
                    fluid
                    selection
                    search
                    clearable
                    onSearchChange={(event, { searchQuery }) => {
                      this.loadJourneyList(searchQuery)
                    }}
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'journeyId')
                    }}
                    options={this.state.journeyList}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default JourneySave
