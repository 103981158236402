import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Pagination,
  Segment,
  Table,
  Grid,
  Loader,
  Dimmer,
  Modal,
  Input
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './UserList.css'

import RequestManager from '../RequestManager'
import UserSave from './UserSave'

class UserList extends React.Component {
  state = {
    isModalOpen: false,
    isLoading: false,
    users: [],
    limit: 30,
    offset: 0,
    total: 0,
    totalPages: 0,
    activePage: 1,

    isListLoaded: false,
    search: '',

    deleteModal: {
      isOpen: false,
      id: null,
      name: '',
      email: ''
    }
  }

  constructor() {
    super()
    this.setModalVisible = this.setModalVisible.bind(this)
    this.receivedUserList = this.receivedUserList.bind(this)
    this.paginationClickHandler = this.paginationClickHandler.bind(this)
    this.refreshActivePage = this.refreshActivePage.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveUser = this.confirmRemoveUser.bind(this)
    this.userDeleted = this.userDeleted.bind(this)

    this.loadSearch = this.loadSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  onDoneModal() {
    this.loadUserList()
  }

  componentDidMount() {
    this.loadUserList()
  }

  loadUserList() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'ADMIN',
        offset: (this.state.activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedUserList
    )
  }

  receivedUserList(hasError, data) {
    this.updateStateUsers(data)
  }

  updateStateUsers(data) {
    this.setState(function (state) {
      state.isLoading = false
      state.isListLoaded = true
      state.users = data.users
      state.total = data.total
      state.totalPages = Math.ceil(data.count / this.state.limit)
      return state
    })
  }

  paginationClickHandler(e, { activePage }) {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'ADMIN',
        offset: (activePage - 1) * this.state.limit,
        limit: this.state.limit,
        search: this.state.search
      },
      this.receivedUserList
    )
    this.refreshActivePage(activePage)
  }

  loadSearch() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'ADMIN',
        offset: 0,
        limit: this.state.limit,
        search: this.state.search
      },
      this.receivedUserList
    )
    this.refreshActivePage(1)
  }

  clearSearch() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'ADMIN',
        offset: 0,
        limit: this.state.limit,
        search: ''
      },
      this.receivedUserList
    )
    this.setState(function (state) {
      state.search = ''
      return state
    })
    this.refreshActivePage(1)
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state[name] = data
      return state
    })
  }

  refreshActivePage(activePage) {
    this.setState(function (state) {
      state.activePage = activePage
      return state
    })
  }

  removeUser(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveUser() {
    RequestManager.requestAPI(
      '/admin/user/remove',
      {
        id: this.state.deleteModal.id
      },
      this.userDeleted
    )
  }

  userDeleted(hasError, data) {
    this.loadUserList()
    this.closeDeleteModal()
  }

  openDeleteModal(index) {
    var selectedUser = this.state.users[index]
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      state.deleteModal.name =
        selectedUser.firstName + ' ' + selectedUser.lastName
      state.deleteModal.email = selectedUser.email
      state.deleteModal.id = selectedUser.id
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  getStatusName(status) {
    switch (status) {
      case 'A':
        return 'Active'
      case 'B':
        return 'Blocked'
      case 'C':
        return 'Canceled'
      case 'E':
        return 'Expired'
      default:
        return ''
    }
  }

  render() {
    document.title = 'Users :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment color="black">
              <Grid relaxed columns="equal" className="PAGE_HEADER">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="users" />
                    <Header.Content>
                      Users
                      <Header.Subheader>All Users</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    floated="right"
                    icon="plus"
                    content="New"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed>
                <Grid.Column width={6}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                    }}
                  >
                    <Input size="mini" placeholder="Search" fluid action>
                      <input
                        value={this.state.search}
                        onChange={(event) => {
                          this.handleFormChange(event, 'search')
                        }}
                      />
                      <Button icon="search" onClick={this.loadSearch} />
                      <Button basic icon="eraser" onClick={this.clearSearch} />
                    </Input>
                  </form>
                </Grid.Column>
                <Grid.Column floated="right">
                  <Pagination
                    disabled={this.state.totalPages <= 0}
                    floated="right"
                    size="mini"
                    boundaryRange={0}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={2}
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.paginationClickHandler}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            {this.state.users &&
              <Table
                attached="bottom"
                compact
                singleLine
                color="black"
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>Email</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.users.length === 0 && this.state.isListLoaded && (
                    <Table.Row>
                      <Table.Cell colSpan="4" singleLine>
                        <Header
                          size="medium"
                          style={{ textAlign: 'center', padding: 10 }}
                        >
                          No records to be displayed.
                          <Header.Subheader>
                            Clear your search or enter a record.
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {this.state.users.map(function (user, index) {
                    return (
                      <Table.Row key={user.id}>
                        <Table.Cell width={1}>
                          <Link to={'/user/view/' + user.id}>{user.email}</Link>
                        </Table.Cell>
                        <Table.Cell>
                          {user.firstName + ' ' + user.lastName}
                        </Table.Cell>
                        <Table.Cell>{this.getStatusName(user.status)}</Table.Cell>
                        <Table.Cell>
                          <Button
                            icon="trash"
                            basic
                            size="tiny"
                            floated="right"
                            color="red"
                            onClick={() => {
                              this.removeUser(index)
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }, this)}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5">
                      <Pagination
                        disabled={this.state.totalPages <= 0}
                        floated="right"
                        size="mini"
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={2}
                        totalPages={this.state.totalPages}
                        activePage={this.state.activePage}
                        onPageChange={this.paginationClickHandler}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            }
          </div>

          <UserSave
            viewMode="NEW"
            open={this.state.isModalOpen}
            onDone={(id) => {
              this.onDoneModal(id)
            }}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this user?"
            />
            <Modal.Content>
              <p>
                <b>
                  {this.state.deleteModal.name}{' '}
                  <small>(#{this.state.deleteModal.id})</small>
                </b>
                <br />
                <i>{this.state.deleteModal.email}</i>
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveUser}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default UserList
