import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Table,
  Ref
} from 'semantic-ui-react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './VentureView.css'

import DatetimeManager from '../DatetimeManager'
import RequestManager from '../RequestManager'

import VentureSave from './VentureSave'
import JourneySave from './JourneySave'

class VentureView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    ventureData: {},
    journeys: [],
    journeyViewMode: 'NEW',
    journeyId: null,
    usedJourneysList: [],
    isJourneyModalOpen: false,
    journeyMaxSequence: 1,
    journeyNextSequence: 1,
    needJourneyListRefresh: false,
    deleteModal: {
      isOpen: false
    },
    journeyDeleteModal: {
      isOpen: false,
      id: null
    }
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveVenture = this.confirmRemoveVenture.bind(this)
    this.ventureDeleted = this.ventureDeleted.bind(this)
    this.receivedVentureData = this.receivedVentureData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.loadVentureJourneyData = this.loadVentureJourneyData.bind(this)
    this.receivedVentureJourneyData = this.receivedVentureJourneyData.bind(this)
    this.openJourneyDeleteModal = this.openJourneyDeleteModal.bind(this)
    this.confirmRemoveStep = this.confirmRemoveStep.bind(this)
    this.journeyDeleted = this.journeyDeleted.bind(this)
    this.saveOrder = this.saveOrder.bind(this)
    this.receivedSequenceUpdate = this.receivedSequenceUpdate.bind(this)
    this.onDoneJourneyModal = this.onDoneJourneyModal.bind(this)
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const data = this.reorder(
      this.state.journeys,
      result.source.index,
      result.destination.index
    )
    this.setState(function (state) {
      state.journeys = data
      state.needJourneyListRefresh = true
      return state
    })
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      background: isDragging && '#EFEFEF',
      border: isDragging && '1px solid #777777',
      ...draggableStyle
    }
  }

  componentDidMount() {
    this.loadVentureData()
  }

  loadVentureJourneyData() {
    RequestManager.requestAPI(
      '/admin/venturejourney/list',
      {
        ventureId: this.state.id
      },
      this.receivedVentureJourneyData
    )
  }

  receivedVentureJourneyData(hasError, receivedData) {
    if (!hasError) {
      var journeys = [],
        journeyMaxSequence = 0,
        usedJourneysList = []
      for (var index in receivedData.ventureJourneys) {
        var key =
            receivedData.ventureJourneys[index].ventureId +
            '-' +
            receivedData.ventureJourneys[index].journeyId,
          journeyId = parseInt(receivedData.ventureJourneys[index].journeyId),
          sequence = receivedData.ventureJourneys[index].sequence
        journeys.push({
          key: key,
          id: receivedData.ventureJourneys[index].journeyId + '',
          sequence: receivedData.ventureJourneys[index].sequence,
          title: receivedData.ventureJourneys[index].journeyTitle,
          completeData: receivedData.ventureJourneys[index]
        })
        if (usedJourneysList.indexOf(journeyId) < 0) {
          usedJourneysList.push(journeyId)
        }
        if (sequence > journeyMaxSequence) {
          journeyMaxSequence = sequence
        }
      }
      this.setState(function (state) {
        state.isLoading = false
        state.journeys = journeys
        state.journeyMaxSequence = journeyMaxSequence
        state.usedJourneysList = usedJourneysList
        return state
      })
    }
  }

  openJourneyDeleteModal(journeyId) {
    this.setState(function (state) {
      state.journeyDeleteModal.isOpen = true
      state.journeyDeleteModal.id = journeyId
      return state
    })
  }

  loadVentureData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/venture/view',
      {
        id: this.state.id
      },
      this.receivedVentureData
    )
  }

  receivedVentureData(hasError, receivedData) {
    if (hasError) {
      this.goBackToVentureList()
    } else {
      this.setState(function (state) {
        state.ventureData = receivedData
        state.isLoading = false
        return state
      })
      this.loadVentureJourneyData()
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  setModalJourneyVisible(isVisible, viewMode, stepType, completeData) {
    var nextSequence = this.state.journeyMaxSequence + 1
    this.setState(function (state) {
      state.journeyViewMode = viewMode ? viewMode : 'NEW'
      if (completeData) {
        state.journeyId = completeData.journeyId
      } else {
        state.journeyId = null
      }
      state.journeyData = completeData
      state.journeyNextSequence = nextSequence
      state.isJourneyModalOpen = isVisible
      return state
    })
  }

  removeVenture(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveVenture() {
    RequestManager.requestAPI(
      '/admin/venture/remove',
      {
        id: this.state.id
      },
      this.ventureDeleted
    )
  }

  confirmRemoveStep() {
    RequestManager.requestAPI(
      '/admin/venturejourney/remove',
      {
        ventureId: parseInt(this.state.id),
        journeyId: parseInt(this.state.journeyDeleteModal.id)
      },
      this.journeyDeleted
    )
  }

  journeyDeleted(hasError, data) {
    if (hasError) {
      this.goBackToVentureList()
    } else {
      this.closeDeleteModal()
      this.loadVentureJourneyData()
    }
  }

  ventureDeleted(hasError, data) {
    this.goBackToVentureList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      state.journeyDeleteModal.isOpen = false
      return state
    })
  }

  goBackToVentureList() {
    window.location.href = '/venture/list'
  }

  displayDatetime(fieldTimestamp) {
    return this.state.ventureData[fieldTimestamp] != null
      ? DatetimeManager.formatTimestamp(this.state.ventureData[fieldTimestamp])
      : '-'
  }

  onDoneModal() {
    this.loadVentureData()
  }

  onDoneJourneyModal() {
    this.loadVentureJourneyData()
  }

  saveOrder() {
    var toSend = {
      venturejourneys: []
    }
    for (var i = 0; i < this.state.journeys.length; i++) {
      toSend.venturejourneys.push({
        ventureId: parseFloat(this.state.id),
        journeyId: parseFloat(this.state.journeys[i].id),
        sequence: i + 1
      })
    }
    RequestManager.requestAPI(
      '/admin/venturejourney/sequenceupdate',
      toSend,
      this.receivedSequenceUpdate
    )
  }

  receivedSequenceUpdate(hasError, data) {
    if (!hasError) {
      this.loadVentureJourneyData()
      this.setState(function (state) {
        state.needJourneyListRefresh = false
        return state
      })
    }
  }

  displayType(type) {
    switch (type) {
      case 'D':
        return 'Discovery'
      case 'I':
        return 'Ignite'
      case 'A':
        return 'Accelerate'
      case 'L':
        return 'Launch'
      default:
        return '-'
    }
  }

  displayDuration(minutes) {
    if (minutes != null) {
      var hours = 0
      if (minutes >= 60) {
        hours = parseInt(minutes / 60)
        minutes = minutes % 60
      }
      return (
        (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '') +
        (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : '')
      )
    }
    return null
  }

  render() {
    document.title = 'Venture :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="trophy" />
                    <Header.Content>
                      {this.state.ventureData.title}
                      <Header.Subheader>Venture</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Code</label>
                      <div className="FORM_CONTENT">
                        {this.state.ventureData.externalId}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Credits (Hours)</label>
                      <div className="FORM_CONTENT">
                        {this.state.ventureData.creditHours}
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Created by</label>
                      <div className="FORM_CONTENT">
                        {this.state.ventureData.createdBy.firstName +
                          ' ' +
                          this.state.ventureData.createdBy.lastName}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Created on</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('creationDate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Last modified by</label>
                      <div className="FORM_CONTENT">
                        {this.state.ventureData.updatedBy != null
                          ? this.state.ventureData.updatedBy.firstName +
                            ' ' +
                            this.state.ventureData.updatedBy.lastName
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last modification on</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastUpdate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column
                  floated="left"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Header as="h4">
                    <Icon name="globe" />
                    <Header.Content>Journeys</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    basic
                    icon="edit"
                    content="New"
                    color="black"
                    size="tiny"
                    onClick={() => {
                      this.setModalJourneyVisible(
                        true,
                        'NEW',
                        'knowledgeSteps',
                        null
                      )
                    }}
                  />
                  {this.state.needJourneyListRefresh ? (
                    <Button
                      floated="right"
                      basic
                      icon="save"
                      content="Save Order"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.saveOrder()
                      }}
                    />
                  ) : null}
                </Grid.Column>
              </Grid>
            </Segment>

            <DragDropContext
              onDragEnd={(result) => {
                this.onDragEnd(result)
              }}
            >
              <Table
                attached
                compact
                singleLine
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell>Code</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="tableBody">
                  {(provided, snapshot) => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {this.state.journeys.map(function (journey, index) {
                          return (
                            <Draggable
                              key={journey.key}
                              draggableId={journey.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Ref innerRef={provided.innerRef}>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={1}>
                                      {journey.sequence}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {journey.completeData.journeyExternalId}
                                    </Table.Cell>
                                    <Table.Cell className="FORM_CONTENT">
                                      <span
                                        onClick={() => {
                                          this.setModalJourneyVisible(
                                            true,
                                            'EDIT',
                                            '',
                                            journey.completeData
                                          )
                                        }}
                                      >
                                        {journey.title}
                                      </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {this.displayType(
                                        journey.completeData.type
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {this.displayDuration(
                                        journey.completeData.estimatedTime
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        icon="trash"
                                        basic
                                        size="tiny"
                                        floated="right"
                                        color="red"
                                        onClick={() => {
                                          this.openJourneyDeleteModal(
                                            journey.completeData.journeyId
                                          )
                                        }}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Ref>
                              )}
                            </Draggable>
                          )
                        }, this)}
                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </div>

          <VentureSave
            viewMode="EDIT"
            editData={this.state.ventureData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <JourneySave
            id={this.state.journeyId}
            ventureId={this.state.id}
            viewMode={this.state.journeyViewMode}
            usedJourneysList={this.state.usedJourneysList}
            editData={this.state.journeyData}
            nextSequence={this.state.journeyNextSequence}
            open={this.state.isJourneyModalOpen}
            onDone={this.onDoneJourneyModal}
            onCancelClick={() => {
              this.setModalJourneyVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this venture?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveVenture}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.journeyDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this journey?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveStep}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default VentureView
