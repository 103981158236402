import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import CodeBlock from '../CodeBlock'
import '../Markdown.css'

import moment from 'moment'
import 'moment/locale/pt-br'

import RequestManager from '../RequestManager'
import QuizQuestionSave from './QuizQuestionSave'

class QuizQuestionView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    questionData: {},
    questionTypeList: [
      { key: 'MM', value: 'MM', text: 'MultipleAnswer' },
      { key: 'MS', value: 'MS', text: 'SingleAnswer' },
      { key: 'BT', value: 'BT', text: 'TrueFalse' },
      { key: 'BY', value: 'BY', text: 'YesNo' },
      { key: 'RA', value: 'RA', text: 'Agreement' },
      { key: 'RI', value: 'RI', text: 'Importance' },
      { key: 'RS', value: 'RS', text: 'Satisfaction' },
      { key: 'R0', value: 'R0', text: 'Scale 0-5' },
      { key: 'R1', value: 'R1', text: 'Scale 1-10' },
      { key: 'OL', value: 'OL', text: 'Open Line' },
      { key: 'OT', value: 'OT', text: 'Open Text' }
    ],
    deleteModal: {
      isOpen: false
    },
    isModalOpen: false,
    langDefault: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    lang: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    langList: this.props.languages.map((lang) => {
      return {
        key: lang.code,
        value: lang.code,
        text: lang.description
      }
    })
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveQuestion = this.confirmRemoveQuestion.bind(this)
    this.questionDeleted = this.questionDeleted.bind(this)
    this.receivedQuestionData = this.receivedQuestionData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    moment.locale('pt-BR')
  }

  componentDidMount() {
    this.loadQuestionData()
  }

  loadQuestionData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/quiz/question/view',
      {
        id: this.state.id
      },
      this.receivedQuestionData
    )
  }

  receivedQuestionData(hasError, receivedData) {
    if (hasError) {
      this.goBackToQuestionList()
    } else {
      this.setState(function (state) {
        state.questionData = receivedData
        state.isLoading = false
        return state
      })
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  removeQuestion(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveQuestion() {
    RequestManager.requestAPI(
      '/admin/quiz/question/remove',
      {
        id: this.state.id
      },
      this.questionDeleted
    )
  }

  questionDeleted(hasError, data) {
    this.goBackToQuestionList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  goBackToQuestionList() {
    window.location.href = '/quiz/question/list'
  }

  getQuestionType(type) {
    for (var i = 0; i < this.state.questionTypeList.length; i++) {
      if (this.state.questionTypeList[i].value === type) {
        return this.state.questionTypeList[i].text
      }
    }
    return ''
  }

  displayDatetime(fieldTimestamp) {
    return this.state.questionData[fieldTimestamp] != null
      ? moment(this.state.questionData[fieldTimestamp]).format('lll')
      : '-'
  }

  indexToChar(index) {
    return String.fromCharCode(65 + index)
  }

  onDoneModal() {
    this.loadQuestionData()
  }

  renderQuestion() {
    var content = '### ' + this.state.questionData.content + '\n\n'
    if (
      this.state.questionData.answers != null &&
      this.state.questionData.answers.length > 0
    ) {
      for (var i = 0; i < this.state.questionData.answers.length; i++) {
        var answer = this.state.questionData.answers[i]
        content +=
          '**' +
          String.fromCharCode(65 + answer.sequence) +
          '**. ' +
          answer.answer +
          (answer.isCorrect ? ' **\\[X\\]**' : '') +
          '\n\n'
      }
    }
    if (
      this.state.questionData.answer !== null &&
      this.state.questionData.answer.length > 10
    ) {
      content += '\n\n## Answer:\n\n' + this.state.questionData.answer
    }
    content = content.replaceAll('__', '\\_\\_')
    return (
      <ReactMarkdown
        source={content}
        className="markdown-body"
        renderers={{
          link: (props) => (
            <a href={props.href} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          ),
          image: Image,
          code: CodeBlock
        }}
      />
    )
  }

  render() {
    document.title = this.state.questionData.externalId + ' :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="question" />
                    <Header.Content>
                      {this.state.questionData.externalId}
                      <Header.Subheader>Question</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="3">
                  <Grid.Column>
                    <Form.Field>
                      <label>Category</label>
                      <div className="FORM_CONTENT">
                        {this.state.questionData.categoryId === null
                          ? '-'
                          : this.state.questionData.categoryTitle}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Type</label>
                      <div className="FORM_CONTENT">
                        {this.getQuestionType(this.state.questionData.type)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last Update</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastUpdate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="1">
                  <Grid.Column>{this.renderQuestion()}</Grid.Column>
                </Grid>
              </Form>
            </Segment>
          </div>

          <QuizQuestionSave
            viewMode="EDIT"
            editData={this.state.questionData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
            lang={this.state.lang}
            langDefault={this.state.langDefault}
            langList={this.state.langList}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this issue?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveQuestion}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

function Image(props) {
  return <img {...props} alt="" style={{ maxWidth: '100%' }} />
}

export default QuizQuestionView
