import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class VideoSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      externalId: '',
      externalPath: '',
      duration: 0,
      title: ''
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.externalId = this.props.editData.externalId
          state.form.externalPath = this.props.editData.externalPath
          state.form.duration = this.props.editData.duration
          state.form.title = this.props.editData.title
          return state
        })
      } else {
        this.setState(function (state) {
          state.form.externalId = ''
          state.form.externalPath = ''
          state.form.duration = 0
          state.form.title = ''
          return state
        })
      }
    }
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        externalId: this.state.form.externalId,
        externalPath: this.state.form.externalPath,
        duration: parseFloat(this.state.form.duration),
        title: this.state.form.title
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/video/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.externalId !== '' &&
      this.state.form.externalPath !== '' &&
      this.state.form.duration !== '' &&
      this.state.form.title !== ''
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="film" />
          {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Video
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Video not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={this.state.isLoading}
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => {
                      this.handleFormChange(event, 'title')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Code</label>
                  <Input
                    disabled={this.state.isLoading}
                    icon="key"
                    value={this.state.form.externalId}
                    onChange={(event) => {
                      this.handleFormChange(event, 'externalId')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Path</label>
                  <Input
                    disabled={this.state.isLoading}
                    icon="linkify"
                    value={this.state.form.externalPath}
                    onChange={(event) => {
                      this.handleFormChange(event, 'externalPath')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Time</label>
                  <Input
                    disabled={this.state.isLoading}
                    icon="clock"
                    type="number"
                    value={this.state.form.duration}
                    onChange={(event) => {
                      this.handleFormChange(event, 'duration')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default VideoSave
