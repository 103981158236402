import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Table,
  Ref,
  Dropdown
} from 'semantic-ui-react'

import ReactMarkdown from 'react-markdown'
import CodeBlock from '../CodeBlock'
import '../Markdown.css'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './ProjectView.css'

import DatetimeManager from '../DatetimeManager'
import RequestManager from '../RequestManager'

import ProjectSave from './ProjectSave'
import TopicSave from './TopicSave'

class ProjectView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    projectData: {},
    topics: [],
    topicsMaxSequence: 0,
    needTopicRefresh: false,
    deleteModal: {
      isOpen: false
    },
    topicDeleteModal: {
      isOpen: false,
      id: null
    },
    isModalOpen: false,
    topicId: null,
    modalViewMode: 'NEW',
    topicData: {},
    nextSequence: 0,
    isTopicModalOpen: false,

    previewModal: {
      isOpen: false,
      isLoading: true,
      content: ''
    },

    langDefault: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    lang: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    langList: this.props.languages.map((lang) => {
      return {
        key: lang.code,
        value: lang.code,
        text: lang.description
      }
    })
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveProject = this.confirmRemoveProject.bind(this)
    this.confirmRemoveTopic = this.confirmRemoveTopic.bind(this)
    this.projectDeleted = this.projectDeleted.bind(this)
    this.topicDeleted = this.topicDeleted.bind(this)
    this.receivedProjectData = this.receivedProjectData.bind(this)
    this.receivedTopicData = this.receivedTopicData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.reorder = this.reorder.bind(this)
    this.onDoneTopicModal = this.onDoneTopicModal.bind(this)
    this.saveOrder = this.saveOrder.bind(this)
    this.receivedSequenceUpdate = this.receivedSequenceUpdate.bind(this)
    this.changePreviewSate = this.changePreviewSate.bind(this)
    this.receivedPreviewData = this.receivedPreviewData.bind(this)
  }

  loadPreview() {
    RequestManager.requestAPI(
      '/admin/project/content-preview',
      {
        id: this.state.id,
        language: this.state.lang
      },
      this.receivedPreviewData
    )
  }

  receivedPreviewData(hasError, receivedData) {
    if (!hasError) {
      let content = '# ' + receivedData.title + '\n'

      receivedData.topics.forEach((element) => {
        content += '## ' + element.title + '\n'
        content += element.content + '\n\n'
        content += '\n\n--------\n\n'
      })

      this.setState(function (state) {
        state.isLoading = false
        state.previewModal.content = content
        state.previewModal.isOpen = true
        state.previewModal.isLoading = false
        return state
      })
    }
  }

  changePreviewSate(openSate, loadingState, content) {
    this.setState(function (state) {
      state.previewModal.isOpen = openSate
      state.previewModal.isLoading = loadingState
      state.previewModal.content = content
      return state
    })
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd(result, listName) {
    if (!result.destination) {
      return
    }
    const data = this.reorder(
      this.state[listName],
      result.source.index,
      result.destination.index
    )
    this.setState(function (state) {
      state[listName] = data
      state.needTopicRefresh = true
      return state
    })
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      background: isDragging && '#EFEFEF',
      border: isDragging && '1px solid #777777',
      ...draggableStyle
    }
  }

  componentDidMount() {
    this.loadProjectData()
  }

  loadTopicData() {
    RequestManager.requestAPI(
      '/admin/projecttopic/list',
      {
        projectId: this.state.id
      },
      this.receivedTopicData
    )
  }

  receivedTopicData(hasError, receivedData) {
    if (!hasError) {
      var topics = [],
        topicsMaxSequence = 0
      for (var index in receivedData.topics) {
        var listItem = {
          key: receivedData.topics[index].id,
          id: receivedData.topics[index].id + '',
          sequence: receivedData.topics[index].sequence,
          title: receivedData.topics[index].title,
          completeData: receivedData.topics[index]
        }
        topics.push(listItem)
        if (topicsMaxSequence < listItem.sequence) {
          topicsMaxSequence = listItem.sequence
        }
      }
      this.setState(function (state) {
        state.topics = topics
        state.topicsMaxSequence = topicsMaxSequence
        state.isLoading = false
        return state
      })
    }
  }

  loadProjectData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/project/view',
      {
        id: this.state.id
      },
      this.receivedProjectData
    )
  }

  receivedProjectData(hasError, receivedData) {
    if (hasError) {
      this.goBackToProjectList()
    } else {
      this.setState(function (state) {
        state.projectData = receivedData
        state.isLoading = false
        return state
      })
      this.loadTopicData()
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  setModalTopicVisible(isVisible, viewMode, completeData) {
    var nextSequence = this.state.topicsMaxSequence + 1
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      if (completeData) {
        state.topicId = completeData.id
      }
      state.topicData = completeData
      state.nextSequence = nextSequence
      state.isTopicModalOpen = isVisible
      return state
    })
  }

  removeProject(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveProject() {
    RequestManager.requestAPI(
      '/admin/project/remove',
      {
        id: this.state.id
      },
      this.projectDeleted
    )
  }

  confirmRemoveTopic() {
    RequestManager.requestAPI(
      '/admin/projecttopic/remove',
      {
        id: parseInt(this.state.topicDeleteModal.id)
      },
      this.topicDeleted
    )
  }

  topicDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadTopicData()
  }

  projectDeleted(hasError, data) {
    this.goBackToProjectList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  openTopicDeleteModal(topicId) {
    this.setState(function (state) {
      state.topicDeleteModal.isOpen = true
      state.topicDeleteModal.id = topicId
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      state.topicDeleteModal.isOpen = false
      return state
    })
  }

  goBackToProjectList() {
    window.location.href = '/project/list'
  }

  displayDatetime(fieldTimestamp) {
    return this.state.projectData[fieldTimestamp] != null
      ? DatetimeManager.formatTimestamp(this.state.projectData[fieldTimestamp])
      : '-'
  }

  displayType(type) {
    switch (type) {
      case 'D':
        return 'Discovery'
      case 'C':
        return 'Challenge'
      default:
        return '-'
    }
  }

  onDoneModal() {
    this.loadProjectData()
  }

  onDoneTopicModal() {
    this.loadTopicData()
  }

  saveOrder() {
    var toSend = [],
      dataToOrganize = this.state.topics
    for (var i = 0; i < dataToOrganize.length; i++) {
      toSend.push({
        id: parseFloat(dataToOrganize[i].id),
        sequence: i + 1
      })
    }
    var payload = {}
    payload.topics = toSend
    RequestManager.requestAPI(
      '/admin/projecttopic/sequenceupdate',
      payload,
      (hasError, data) => this.receivedSequenceUpdate(hasError, data)
    )
  }

  receivedSequenceUpdate(hasError, data) {
    if (!hasError) {
      this.loadTopicData()
      this.setState(function (state) {
        state.needTopicRefresh = false
        return state
      })
    }
  }

  render() {
    document.title = 'Project :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="road" />
                    <Header.Content>
                      {this.state.projectData.title}
                      <Header.Subheader>Project</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                  <Button
                    floated="right"
                    icon="eye"
                    content="Preview"
                    size="small"
                    onClick={() => {
                      this.loadPreview()
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="1">
                  <Grid.Column>
                    <Form.Field>
                      <label>Code</label>
                      <div className="FORM_CONTENT">
                        {this.state.projectData.externalId}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Description</label>
                      <div>{this.state.projectData.description}</div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Created by</label>
                      <div className="FORM_CONTENT">
                        {this.state.projectData.createdBy.firstName +
                          ' ' +
                          this.state.projectData.createdBy.lastName}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Creation Date</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('creationDate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Last modified by</label>
                      <div className="FORM_CONTENT">
                        {this.state.projectData.updatedBy != null
                          ? this.state.projectData.updatedBy.firstName +
                            ' ' +
                            this.state.projectData.updatedBy.lastName
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last modified on</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastUpdate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column
                  floated="left"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Header as="h4">
                    <Icon name="play" />
                    <Header.Content>Topics</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    basic
                    icon="edit"
                    content="New"
                    color="black"
                    size="tiny"
                    onClick={() => {
                      this.setModalTopicVisible(true, 'NEW', null)
                    }}
                  />
                  {this.state.needTopicRefresh && (
                    <Button
                      floated="right"
                      basic
                      icon="save"
                      content="Save Order"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.saveOrder('topics')
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Segment>

            <DragDropContext
              onDragEnd={(result) => {
                this.onDragEnd(result, 'topics')
              }}
            >
              <Table
                attached
                compact
                singleLine
                size="small"
                className="LINK_UNDERLINE TABLE_FIXED"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="tableBody">
                  {(provided, snapshot) => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {this.state.topics.map(function (topic, index) {
                          return (
                            <Draggable
                              key={topic.key}
                              draggableId={topic.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Ref innerRef={provided.innerRef}>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={1}>
                                      {topic.sequence}
                                    </Table.Cell>
                                    <Table.Cell className="FORM_CONTENT">
                                      <span
                                        onClick={() => {
                                          this.setModalTopicVisible(
                                            true,
                                            'EDIT',
                                            topic.completeData
                                          )
                                        }}
                                      >
                                        {topic.title}
                                      </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        icon="trash"
                                        basic
                                        size="tiny"
                                        floated="right"
                                        color="red"
                                        onClick={() => {
                                          this.openTopicDeleteModal(topic.id)
                                        }}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Ref>
                              )}
                            </Draggable>
                          )
                        }, this)}
                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </div>

          <ProjectSave
            viewMode="EDIT"
            editData={this.state.projectData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
            lang={this.state.lang}
            langDefault={this.state.langDefault}
            langList={this.state.langList}
          />

          <TopicSave
            viewMode={this.state.modalViewMode}
            editData={this.state.topicData}
            projectId={this.state.id}
            nextSequence={this.state.nextSequence}
            id={this.state.topicId}
            open={this.state.isTopicModalOpen}
            onDone={this.onDoneTopicModal}
            onCancelClick={() => {
              this.setModalTopicVisible(false)
            }}
            lang={this.state.lang}
            langDefault={this.state.langDefault}
            langList={this.state.langList}
          />

          <Modal
            open={this.state.previewModal.isOpen}
            className="animated fadeIn"
          >
            <Modal.Header>
            <Grid relaxed columns={this.state.lang !== this.state.langDefault ? 2 : 1}>
                <Grid.Column width={9}>
                  <Icon name="eye" /> Content Preview
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  <Dropdown
                    button
                    className="icon"
                    floating
                    labeled
                    icon="world"
                    size="tinny"
                    value={this.state.lang}
                    options={this.state.langList}
                    placeholder="Choose Language"
                    onChange={(event, { value }) => {
                      this.loadPreview()
                      this.setState((state) => {
                        state.lang = value
                        return state
                      })
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Header>
            <Modal.Content>
              {this.state.previewModal.isLoading && (
                <Dimmer active className="animated fadeIn fast">
                  <Loader />
                </Dimmer>
              )}
              <ReactMarkdown
                source={this.state.previewModal.content}
                className="markdown-body"
                renderers={{
                  link: (props) => (
                    <a
                      href={props.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  ),
                  image: Image,
                  code: CodeBlock
                }}
              />
            </Modal.Content>
            <Modal.Actions>

              <Button
                onClick={() => {
                  this.changePreviewSate(false, false, '')
                  if(this.state.lang !== this.state.langDefault) {
                    this.setState(state => {
                      state.lang = this.state.langDefault
                      return state
                    })
                  }
                }}
              >
                Back
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this Project?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveProject}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.topicDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this topic?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveTopic}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

function Image(props) {
  return <img {...props} alt="" style={{ maxWidth: '100%' }} />
}

export default ProjectView
