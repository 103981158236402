import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Message,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class SkillSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    trailId: this.props.trailId,
    recordType: this.props.recordType,
    isLoading: false,
    form: {
      hasError: false,
      skillId: null,
      level: null
    },
    skillList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadSkillList = this.loadSkillList.bind(this)
    this.receivedSkillList = this.receivedSkillList.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.recordType = this.props.recordType
          if (this.props.editData != null) {
            state.form.skillId = this.props.editData.skillId
            state.form.level = this.props.editData.level
          }
          return state
        })
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.recordType = this.props.recordType
          state.form.skillId = null
          state.form.level = null
          return state
        })
      }
      this.loadSkillList('')
    }
  }

  loadSkillList(searchText) {
    RequestManager.requestResourceList(
      'skill',
      searchText,
      this.receivedSkillList
    )
  }

  receivedSkillList(hasError, data) {
    var skills = []
    if (!hasError) {
      for (var skill in data.skills) {
        skills.push({
          key: data.skills[skill].id,
          value: data.skills[skill].id,
          text:
            data.skills[skill].group +
            ' > ' +
            data.skills[skill].category +
            ' > ' +
            data.skills[skill].title
        })
      }
    }
    this.setState(function (state) {
      state.skillList = skills
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        trailId: parseInt(this.state.trailId),
        recordType: this.state.recordType,
        skillId: parseInt(this.state.form.skillId),
        level: parseInt(this.state.form.level)
      }
      RequestManager.requestAPI(
        '/admin/trailskill/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return this.state.form.skillId !== null && this.state.form.level !== null
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="globe" />
          {(this.state.viewMode === 'EDIT' ? 'Edit' : 'New') +
            (this.state.recordType === 'P' ? ' Prerequisite' : ' Objective')}
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>
                {this.state.recordType === 'P' ? 'Prerequisite' : 'Objective'}{' '}
                not registered
              </Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="1">
              <Grid.Column>
                <Form.Field required>
                  <label>Competence</label>
                  <Dropdown
                    disabled={
                      this.state.isLoading || this.state.viewMode === 'EDIT'
                    }
                    value={this.state.form.skillId}
                    fluid
                    selection
                    search
                    clearable
                    onSearchChange={(event, { searchQuery }) => {
                      this.loadSkillList(searchQuery)
                    }}
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'skillId')
                    }}
                    options={this.state.skillList}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Proficiency</label>
                  <Dropdown
                    disabled={this.state.isLoading}
                    value={this.state.form.type}
                    fluid
                    selection
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'level')
                    }}
                    options={[
                      { key: '1', value: '1', text: '1-Basic' },
                      { key: '2', value: '2', text: '2-Practical' },
                      { key: '3', value: '3', text: '3-Advanced' }
                    ]}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default SkillSave
