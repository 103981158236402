import cookie from 'react-cookies'

class RequestManager {
  static sessionId = null
  static accessToken = null

  static async requestAPI(url, data, callback) {
    this.validateSessionCookie()
    let response = await fetch(process.env.REACT_APP_API_DOMAIN + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.buildHeaders()
    })
    let receivedData = await response.json()
    if (!response.ok) {
      callback(true, {})
    } else if (typeof receivedData.error != 'undefined') {
      callback(receivedData.error, receivedData)
    } else {
      callback(false, receivedData)
    }
  }

  static async requestResourceList(type, stringSearch, callback) {
    this.validateSessionCookie()
    let response = await fetch(
      process.env.REACT_APP_API_DOMAIN + '/admin/' + type + '/list',
      {
        method: 'POST',
        body: JSON.stringify({
          limit: 5,
          search: stringSearch
        }),
        headers: this.buildHeaders()
      }
    )
    let receivedData = await response.json()
    if (!response.ok) {
      callback(true, {})
    } else if (typeof receivedData.error != 'undefined') {
      callback(receivedData.error, receivedData)
    } else {
      callback(false, receivedData)
    }
  }

  static validateSessionCookie() {
    this.sessionId = cookie.load('session-id')
    this.accessToken = cookie.load('access-token')
    if (this.sessionId === undefined || this.accessToken === undefined) {
      this.goBackToLogin()
    }
  }

  static goBackToLogin() {
    cookie.remove('session-id', RequestManager.cookieConfig)
    cookie.remove('access-token', RequestManager.cookieConfig)
    window.location.href = process.env.REACT_APP_LOGIN_DOMAIN
  }

  static buildHeaders() {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json;charset=UTF-8')
    myHeaders.append('Session-id', this.sessionId)
    myHeaders.append('Access-token', this.accessToken)
    return myHeaders
  }

  static logout() {
    this.requestAPI('/logout', {}, this.goBackToLogin)
  }

  static cookieConfig = {
    path: '/',
    domain: process.env.NODE_ENV === 'production' ? 'pragma.school' : null
  }
}

export default RequestManager
