import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Table,
  Ref,
  Dropdown
} from 'semantic-ui-react'

import ReactMarkdown from 'react-markdown'
import CodeBlock from '../CodeBlock'
import '../Markdown.css'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './TrailView.css'

import DatetimeManager from '../DatetimeManager'
import RequestManager from '../RequestManager'

import TrailSave from './TrailSave'
import StepSave from './StepSave'
import ChallengeSave from './ChallengeSave'
import SkillSave from './SkillSave'
import FaqSave from './FaqSave'
import QuizCategorySave from './QuizCategorySave'
import QuizQuestionSave from './QuizQuestionSave'

class TrailView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    trailData: {},

    knowledgeSteps: [],
    knowledgeStepsMaxSequence: 0,
    needKnowledgeStepRefresh: false,

    resourcesSteps: [],
    resourcesStepsMaxSequence: 0,
    needResourceStepsRefresh: false,

    challenges: [],
    challengesMaxSequence: 0,
    needChallengeRefresh: false,

    questions: [],
    questionsMaxSequence: 0,
    needQuestionRefresh: false,

    quizCategories: [],
    quizCategoryMaxSequence: 0,
    needQuizCategoryRefresh: false,

    quizQuestions: [],
    quizQuestionsMaxSequence: 0,
    needQuizQuestionRefresh: false,

    prerequisiteSkills: [],
    outcomeSkills: [],

    faqs: [],
    faqMaxSequence: 0,
    needFaqRefresh: false,

    deleteModal: {
      isOpen: false
    },
    stepDeleteModal: {
      isOpen: false,
      id: null
    },
    challengeDeleteModal: {
      isOpen: false,
      id: null
    },
    skillDeleteModal: {
      isOpen: false,
      recordType: null,
      skillId: null
    },
    faqDeleteModal: {
      isOpen: false,
      recordType: null,
      faqId: null
    },
    quizCategoryDeleteModal: {
      isOpen: false,
      categoryId: null,
      externalId: null
    },
    quizQuestionDeleteModal: {
      isOpen: false,
      questionId: null,
      externalId: null
    },
    isModalOpen: false,
    stepId: null,
    stepType: '',
    stepData: {},
    challengeId: null,
    challengeType: '',
    challengeData: {},
    skillId: null,
    skillRecordType: '',
    skillData: {},
    faqId: null,
    faqData: {},
    modalViewMode: 'NEW',
    nextSequence: 0,
    isStepModalOpen: false,
    isChallengeModalOpen: false,
    isSkillModalOpen: false,
    isFaqModalOpen: false,
    isQuizCategoryModalOpen: false,
    isQuizQuestionModalOpen: false,
    previewModal: {
      isOpen: false,
      isLoading: true,
      content: ''
    },
    langDefault: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    lang: this.props.languages.filter((lang) => lang.isBaseLang)[0].code,
    langList: this.props.languages.map((lang) => {
      return {
        key: lang.code,
        value: lang.code,
        text: lang.description
      }
    })
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveTrail = this.confirmRemoveTrail.bind(this)
    this.confirmRemoveStep = this.confirmRemoveStep.bind(this)
    this.trailDeleted = this.trailDeleted.bind(this)
    this.stepDeleted = this.stepDeleted.bind(this)
    this.receivedTrailData = this.receivedTrailData.bind(this)
    this.receivedStepData = this.receivedStepData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.reorder = this.reorder.bind(this)
    this.onDoneStepModal = this.onDoneStepModal.bind(this)
    this.saveOrder = this.saveOrder.bind(this)
    this.receivedSequenceUpdate = this.receivedSequenceUpdate.bind(this)

    this.loadChallengeData = this.loadChallengeData.bind(this)
    this.receivedChallengeData = this.receivedChallengeData.bind(this)
    this.challengeDeleted = this.challengeDeleted.bind(this)
    this.confirmRemoveChallenge = this.confirmRemoveChallenge.bind(this)
    this.onDoneChallengeModal = this.onDoneChallengeModal.bind(this)

    this.loadSkillData = this.loadSkillData.bind(this)
    this.receivedSkillData = this.receivedSkillData.bind(this)
    this.skillDeleted = this.skillDeleted.bind(this)
    this.confirmRemoveSkill = this.confirmRemoveSkill.bind(this)
    this.onDoneSkillModal = this.onDoneSkillModal.bind(this)

    this.loadFaqData = this.loadFaqData.bind(this)
    this.receivedFaqData = this.receivedFaqData.bind(this)
    this.faqDeleted = this.faqDeleted.bind(this)
    this.confirmRemoveFaq = this.confirmRemoveFaq.bind(this)
    this.onDoneFaqModal = this.onDoneFaqModal.bind(this)
    this.getFaqStepList = this.getFaqStepList.bind(this)
    this.getFaqPreview = this.getFaqPreview.bind(this)

    this.loadQuizData = this.loadQuizData.bind(this)
    this.receivedQuizData = this.receivedQuizData.bind(this)
    this.quizDeleted = this.quizDeleted.bind(this)
    this.confirmRemoveQuizQuestion = this.confirmRemoveQuizQuestion.bind(this)
    this.confirmRemoveQuizCategory = this.confirmRemoveQuizCategory.bind(this)
    this.onDoneQuizModal = this.onDoneQuizModal.bind(this)
    this.receivedPreviewData = this.receivedPreviewData.bind(this)
  }

  getFaqPreview(stepId) {
    var content = ''
    if (this.state.faqs !== null && this.state.faqs.length > 0) {
      for (var i = 0; i < this.state.faqs.length; i++) {
        var question = this.state.faqs[i].completeData
        if (question.steps !== null && question.steps.length > 0) {
          for (var y = 0; y < question.steps.length; y++) {
            if (stepId === question.steps[y]) {
              content +=
                '#### ' + question.content + '\n\n' + question.answer + '\n\n'
              break
            }
          }
        }
      }
    }
    if (content.length > 10) {
      return '### FAQ:\n\n' + content
    }
    return ''
  }

  loadPreview() {
    RequestManager.requestAPI(
      '/admin/trail/content-preview',
      {
        id: this.state.id,
        language: this.state.lang
      },
      this.receivedPreviewData
    )
  }

  receivedPreviewData(hasError, receivedData) {
    if (!hasError) {
      let content = '# ' + receivedData.title + '\n'

      receivedData.steps.forEach((element) => {
        content += '## ' + element.title + '\n'
        content += element.content + '\n\n'

        if (element.questions && element.questions.length > 0) {
          content += '\n\n--------\n\n'

          element.questions.forEach((question, index) => {
            content += '#### ' + (index + 1) + ') ' + question.content + '\n\n'
            question.answers.forEach((answer, index) => {
              content +=
                '**' +
                String.fromCharCode(65 + index) +
                '**. ' +
                answer.answer +
                (answer.isCorrect ? ' **\\[correct\\]**' : '') +
                '\n\n'
            })
            content += '\n\n'
          })
        }
      })

      if (receivedData.resources.length > 0) {
        content += '\n\n--------\n\n'
        content += '## Resources\n'
      }

      receivedData.resources.forEach((element) => {
        content += '* [' + element.title + '](' + element.content + ')\n'
      })

      this.setState(function (state) {
        state.isLoading = false
        state.previewModal.content = content
        state.previewModal.isOpen = true
        state.previewModal.isLoading = false
        return state
      })
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd(result, listName) {
    if (!result.destination) {
      return
    }
    const data = this.reorder(
      this.state[listName],
      result.source.index,
      result.destination.index
    )
    this.setState(function (state) {
      state[listName] = data
      switch (listName) {
        case 'knowledgeSteps':
          state.needKnowledgeStepRefresh = true
          break
        case 'resourcesSteps':
          state.needResourceStepsRefresh = true
          break
        case 'challenges':
          state.needChallengeRefresh = true
          break
        case 'questions':
          state.needQuestionRefresh = true
          break
        case 'faqs':
          state.needFaqRefresh = true
          break
        case 'quizCategories':
          state.needQuizCategoryRefresh = true
          break
        case 'quizQuestions':
          state.needQuizQuestionRefresh = true
          break
        default:
          break
      }
      return state
    })
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      background: isDragging && '#EFEFEF',
      border: isDragging && '1px solid #777777',
      ...draggableStyle
    }
  }

  componentDidMount() {
    this.loadTrailData()
  }

  loadStepData() {
    RequestManager.requestAPI(
      '/admin/trailstep/list',
      {
        trailId: this.state.id
      },
      this.receivedStepData
    )
  }

  receivedStepData(hasError, receivedData) {
    if (!hasError) {
      var knowledgeSteps = [],
        knowledgeStepsMaxSequence = 0,
        resourcesSteps = [],
        resourcesStepsMaxSequence = 0
      for (var index in receivedData.steps) {
        var listItem = {
          key: receivedData.steps[index].id,
          id: receivedData.steps[index].id + '',
          sequence: receivedData.steps[index].sequence,
          title: receivedData.steps[index].title,
          completeData: receivedData.steps[index]
        }
        switch (receivedData.steps[index].type) {
          case 'K':
            knowledgeSteps.push(listItem)
            if (knowledgeStepsMaxSequence < listItem.sequence) {
              knowledgeStepsMaxSequence = listItem.sequence
            }
            break
          case 'R':
            resourcesSteps.push(listItem)
            if (resourcesStepsMaxSequence < listItem.sequence) {
              resourcesStepsMaxSequence = listItem.sequence
            }
            break
          default:
            break
        }
      }
      this.setState(function (state) {
        state.knowledgeSteps = knowledgeSteps
        state.knowledgeStepsMaxSequence = knowledgeStepsMaxSequence
        state.resourcesSteps = resourcesSteps
        state.resourcesStepsMaxSequence = resourcesStepsMaxSequence

        state.isLoading = false
        return state
      })
    }
  }

  loadChallengeData() {
    RequestManager.requestAPI(
      '/admin/trailchallenge/list',
      {
        trailId: this.state.id
      },
      this.receivedChallengeData
    )
  }

  receivedChallengeData(hasError, receivedData) {
    if (hasError) {
      this.goBackToTrailList()
    } else {
      var challenges = [],
        challengesMaxSequence = 0,
        questions = [],
        questionsMaxSequence = 0
      for (var index in receivedData.challenges) {
        var listItem = {
          key: receivedData.challenges[index].id,
          id: receivedData.challenges[index].id + '',
          sequence: receivedData.challenges[index].sequence,
          title: receivedData.challenges[index].content,
          completeData: receivedData.challenges[index]
        }
        switch (receivedData.challenges[index].type) {
          case 'A':
            challenges.push(listItem)
            if (challengesMaxSequence < listItem.sequence) {
              challengesMaxSequence = listItem.sequence
            }
            break
          case 'M':
          case 'C':
            questions.push(listItem)
            if (questionsMaxSequence < listItem.sequence) {
              questionsMaxSequence = listItem.sequence
            }
            break
          default:
            break
        }
      }
      this.setState(function (state) {
        state.challenges = challenges
        state.challengesMaxSequence = challengesMaxSequence
        state.questions = questions
        state.questionsMaxSequence = questionsMaxSequence
        state.isLoading = false
        return state
      })
    }
  }

  loadSkillData() {
    RequestManager.requestAPI(
      '/admin/trailskill/list',
      {
        trailId: this.state.id
      },
      this.receivedSkillData
    )
  }

  receivedSkillData(hasError, receivedData) {
    if (hasError) {
      this.goBackToTrailList()
    } else {
      this.setState(function (state) {
        state.prerequisiteSkills = receivedData.prerequisites
        state.outcomeSkills = receivedData.outcomes
        state.isLoading = false
        return state
      })
    }
  }

  loadFaqData() {
    RequestManager.requestAPI(
      '/admin/trailfaq/list',
      {
        trailId: this.state.id
      },
      this.receivedFaqData
    )
  }

  receivedFaqData(hasError, receivedData) {
    if (!hasError) {
      var faqs = [],
        faqMaxSequence = 0
      for (var index in receivedData.questions) {
        var sequence = receivedData.questions[index].sequence
        if (faqMaxSequence < sequence) {
          faqMaxSequence = sequence
        }
        faqs.push({
          key: receivedData.questions[index].id,
          id: receivedData.questions[index].id + '',
          sequence: sequence,
          title: receivedData.questions[index].content,
          completeData: receivedData.questions[index]
        })
      }
      this.setState(function (state) {
        state.faqs = faqs
        state.faqMaxSequence = faqMaxSequence
        state.isLoading = false
        return state
      })
    }
  }

  loadQuizData() {
    RequestManager.requestAPI(
      '/admin/trailquiz/list',
      {
        trailId: this.state.id
      },
      this.receivedQuizData
    )
  }

  receivedQuizData(hasError, receivedData) {
    if (hasError) {
      this.goBackToTrailList()
    } else {
      this.setState(function (state) {
        state.quizCategories = receivedData.categories
        state.quizQuestions = receivedData.questions
        state.isLoading = false
        return state
      })
    }
  }

  loadTrailData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/trail/view',
      {
        id: this.state.id
      },
      this.receivedTrailData
    )
  }

  receivedTrailData(hasError, receivedData) {
    if (hasError) {
      this.goBackToTrailList()
    } else {
      this.setState(function (state) {
        state.trailData = receivedData
        state.isLoading = false
        return state
      })
      this.loadStepData()
      this.loadSkillData()
      if (receivedData.type === 'Q') {
        this.loadQuizData()
      } else {
        this.loadChallengeData()
        this.loadFaqData()
      }
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  setModalStepVisible(isVisible, viewMode, stepType, completeData) {
    var nextSequence = 0
    switch (stepType) {
      case 'knowledgeSteps':
        nextSequence = this.state.knowledgeStepsMaxSequence + 1
        break
      case 'resourcesSteps':
        nextSequence = this.state.resourcesStepsMaxSequence + 1
        break
      default:
        break
    }
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      if (completeData) {
        state.stepId = completeData.id
      }
      state.stepData = completeData
      state.stepType = stepType
      state.nextSequence = nextSequence
      state.isStepModalOpen = isVisible
      return state
    })
  }

  setModalChallengeVisible(isVisible, viewMode, type, completeData) {
    var nextSequence = 0
    switch (type) {
      case 'questions':
        nextSequence = this.state.questionsMaxSequence + 1
        break
      case 'challenges':
        nextSequence = this.state.challengesMaxSequence + 1
        break
      default:
        break
    }
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      if (completeData) {
        state.challengeId = completeData.id
      }
      state.challengeData = completeData
      state.challengeType = type
      state.nextSequence = nextSequence
      state.isChallengeModalOpen = isVisible
      return state
    })
  }

  setModalSkillVisible(isVisible, viewMode, recordType, skillData) {
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      state.skillData = skillData
      state.skillRecordType = recordType
      if (skillData) state.skillId = skillData.id
      state.isSkillModalOpen = isVisible
      return state
    })
  }

  setModalFaqVisible(isVisible, viewMode, completeData) {
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      state.faqData = completeData
      state.nextSequence = state.faqMaxSequence + 1
      if (completeData) state.faqId = completeData.id
      state.isFaqModalOpen = isVisible
      return state
    })
  }

  setModalQuizCategoryVisible(isVisible, viewMode, quizCategoryData) {
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      state.quizCategoryData = quizCategoryData
      if (quizCategoryData) state.quizCategoryId = quizCategoryData.id
      state.nextSequence =
        state.quizCategories !== null ? state.quizCategories.length + 1 : 1
      state.isQuizCategoryModalOpen = isVisible
      return state
    })
  }

  setModalQuizQuestionVisible(isVisible, viewMode, quizQuestionData) {
    this.setState(function (state) {
      state.modalViewMode = viewMode ? viewMode : 'NEW'
      state.quizQuestionData = quizQuestionData
      if (quizQuestionData) state.quizQuestionId = quizQuestionData.id
      state.nextSequence =
        state.quizQuestions !== null ? state.quizQuestions.length + 1 : 1
      state.isQuizQuestionModalOpen = isVisible
      return state
    })
  }

  removeTrail(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveTrail() {
    RequestManager.requestAPI(
      '/admin/trail/remove',
      {
        id: this.state.id
      },
      this.trailDeleted
    )
  }

  confirmRemoveChallenge() {
    RequestManager.requestAPI(
      '/admin/trailchallenge/remove',
      {
        trailId: this.state.id,
        id: parseInt(this.state.challengeDeleteModal.id)
      },
      this.challengeDeleted
    )
  }

  confirmRemoveSkill() {
    RequestManager.requestAPI(
      '/admin/trailskill/remove',
      {
        trailId: this.state.id,
        recordType: this.state.skillDeleteModal.recordType,
        skillId: parseInt(this.state.skillDeleteModal.skillId)
      },
      this.skillDeleted
    )
  }

  confirmRemoveFaq() {
    RequestManager.requestAPI(
      '/admin/trailfaq/remove',
      {
        trailId: this.state.id,
        id: parseInt(this.state.faqDeleteModal.id)
      },
      this.faqDeleted
    )
  }

  confirmRemoveQuizCategory() {
    RequestManager.requestAPI(
      '/admin/trailquiz/category/remove',
      {
        trailId: this.state.id,
        categoryId: parseInt(this.state.quizCategoryDeleteModal.categoryId)
      },
      this.quizDeleted
    )
  }

  confirmRemoveQuizQuestion() {
    RequestManager.requestAPI(
      '/admin/trailquiz/question/remove',
      {
        trailId: this.state.id,
        questionId: parseInt(this.state.quizQuestionDeleteModal.questionId)
      },
      this.quizDeleted
    )
  }

  challengeDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadChallengeData()
  }

  skillDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadSkillData()
  }

  faqDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadFaqData()
  }

  quizDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadQuizData()
  }

  confirmRemoveStep() {
    RequestManager.requestAPI(
      '/admin/trailstep/remove',
      {
        id: parseInt(this.state.stepDeleteModal.id)
      },
      this.stepDeleted
    )
  }

  stepDeleted(hasError, data) {
    this.closeDeleteModal()
    this.loadStepData()
  }

  trailDeleted(hasError, data) {
    this.goBackToTrailList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  openStepDeleteModal(stepId) {
    this.setState(function (state) {
      state.stepDeleteModal.isOpen = true
      state.stepDeleteModal.id = stepId
      return state
    })
  }

  openChallengeDeleteModal(challengeId) {
    this.setState(function (state) {
      state.challengeDeleteModal.isOpen = true
      state.challengeDeleteModal.id = challengeId
      return state
    })
  }

  openSkillDeleteModal(recordType, skillId) {
    this.setState(function (state) {
      state.skillDeleteModal.isOpen = true
      state.skillDeleteModal.recordType = recordType
      state.skillDeleteModal.skillId = skillId
      return state
    })
  }

  openFaqDeleteModal(faqId) {
    this.setState(function (state) {
      state.faqDeleteModal.isOpen = true
      state.faqDeleteModal.id = faqId
      return state
    })
  }

  openQuizCategoryDeleteModal(category) {
    this.setState(function (state) {
      state.quizCategoryDeleteModal.isOpen = true
      state.quizCategoryDeleteModal.categoryId = category.id
      state.quizCategoryDeleteModal.externalId = category.externalId
      return state
    })
  }

  openQuizQuestionDeleteModal(question) {
    this.setState(function (state) {
      state.quizQuestionDeleteModal.isOpen = true
      state.quizQuestionDeleteModal.questionId = question.id
      state.quizQuestionDeleteModal.externalId = question.externalId
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      state.stepDeleteModal.isOpen = false
      state.challengeDeleteModal.isOpen = false
      state.skillDeleteModal.isOpen = false
      state.faqDeleteModal.isOpen = false
      state.quizCategoryDeleteModal.isOpen = false
      state.quizQuestionDeleteModal.isOpen = false
      return state
    })
  }

  goBackToTrailList() {
    window.location.href = '/trail/list'
  }

  getFaqStepList() {
    var faqStepList = []
    if (
      this.state.knowledgeSteps !== null &&
      this.state.knowledgeSteps.length > 0
    ) {
      for (var i = 0; i < this.state.knowledgeSteps.length; i++) {
        faqStepList.push({
          id: this.state.knowledgeSteps[i].id,
          title: this.state.knowledgeSteps[i].title
        })
      }
    }
    return faqStepList
  }

  displayDatetime(fieldTimestamp) {
    return this.state.trailData[fieldTimestamp] != null
      ? DatetimeManager.formatTimestamp(this.state.trailData[fieldTimestamp])
      : '-'
  }

  displayDuration(minutes) {
    if (minutes != null && minutes > 0) {
      var hours = 0
      if (minutes >= 60) {
        hours = parseInt(minutes / 60)
        minutes = minutes % 60
      }
      return (
        (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '') +
        (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : '')
      )
    }
    return '-'
  }

  displayType(type) {
    switch (type) {
      case 'D':
        return 'Discovery'
      case 'C':
        return 'Challenge'
      case 'Q':
        return 'Quiz'
      default:
        return '-'
    }
  }

  displayQuizType(type) {
    switch (type) {
      case 'A':
        return 'Assessment'
      case 'E':
        return 'Exam'
      case 'P':
        return 'Practice'
      case 'S':
        return 'Survey'
      default:
        return '-'
    }
  }

  displayQuizQuestionOrder(order) {
    switch (order) {
      case 'C':
        return 'Category'
      case 'R':
        return 'Random'
      case 'S':
        return 'Sequence'
      default:
        return '-'
    }
  }

  displayLevel(level) {
    switch (level) {
      case 1:
        return '1-Basic'
      case 2:
        return '2-Practical'
      case 3:
        return '3-Advanced'
      default:
        return '-'
    }
  }

  onDoneModal() {
    this.loadTrailData()
  }

  onDoneStepModal() {
    this.loadStepData()
  }

  onDoneChallengeModal() {
    this.loadChallengeData()
  }

  onDoneSkillModal() {
    this.loadSkillData()
  }

  onDoneFaqModal() {
    this.loadFaqData()
  }

  onDoneQuizModal() {
    this.loadQuizData()
  }

  saveOrder(stepType) {
    var toSend = [],
      dataToOrganize = [],
      reqType = ''
    switch (stepType) {
      case 'knowledgeSteps':
        dataToOrganize = this.state.knowledgeSteps
        reqType = 'trailstep'
        break
      case 'resourcesSteps':
        dataToOrganize = this.state.resourcesSteps
        reqType = 'trailstep'
        break
      case 'questions':
        dataToOrganize = this.state.questions
        reqType = 'trailchallenge'
        break
      case 'challenges':
        dataToOrganize = this.state.challenges
        reqType = 'trailchallenge'
        break
      case 'faqs':
        dataToOrganize = this.state.faqs
        reqType = 'trailfaq'
        break
      case 'quizCategories':
        dataToOrganize = this.state.quizCategories
        reqType = 'trailquiz/category'
        break
      case 'quizQuestions':
        dataToOrganize = this.state.quizQuestions
        reqType = 'trailquiz/question'
        break
      default:
        break
    }
    for (var i = 0; i < dataToOrganize.length; i++) {
      toSend.push({
        id: parseFloat(dataToOrganize[i].id),
        sequence: i + 1
      })
    }
    var payload = {}
    payload.trailId = this.state.id
    if (reqType === 'trailchallenge') {
      payload.challenges = toSend
    } else if (reqType === 'trailstep') {
      payload.steps = toSend
    } else if (reqType === 'trailfaq') {
      payload.questions = toSend
    } else if (reqType === 'trailquiz/category') {
      payload.categories = toSend
    } else if (reqType === 'trailquiz/question') {
      payload.questions = toSend
    }
    RequestManager.requestAPI(
      '/admin/' + reqType + '/sequenceupdate',
      payload,
      (hasError, data) => this.receivedSequenceUpdate(hasError, data, reqType)
    )
  }

  viewTrailLang(language) {
    RequestManager.requestAPI(
      '/admin/trail/content-preview',
      {
        id: this.state.id,
        language
      },
      this.receivedPreviewData
    )
  }

  receivedSequenceUpdate(hasError, data, reqType) {
    if (!hasError) {
      switch (reqType) {
        case 'trailstep':
          this.loadStepData()
          break
        case 'trailchallenge':
          this.loadChallengeData()
          break
        case 'trailfaq':
          this.loadFaqData()
          break
        case 'trailquiz/category':
        case 'trailquiz/question':
          this.loadQuizData()
          break
        default:
          break
      }
      this.setState(function (state) {
        state.needKnowledgeStepRefresh = false
        state.needResourceStepsRefresh = false
        state.needChallengeRefresh = false
        state.needQuestionRefresh = false
        state.needFaqRefresh = false
        state.needQuizCategoryRefresh = false
        state.needQuizQuestionRefresh = false
        return state
      })
    }
  }

  renderChallenge() {
    return (
      <>
        <Segment attached="top" color="black">
          <Grid relaxed columns="equal">
            <Grid.Column
              floated="left"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Header as="h4">
                <Icon name="trophy" />
                <Header.Content>Challenges</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached>
          {((this.state.trailData.challengeTitle !== null &&
            this.state.trailData.challengeTitle > ' ') ||
            (this.state.trailData.challengeIntro !== null &&
              this.state.trailData.challengeIntro > ' ')) && (
            <Grid relaxed columns="1">
              <Grid.Column>
                {this.state.trailData.challengeTitle !== null &&
                  this.state.trailData.challengeTitle > ' ' && (
                    <Header as="h3">
                      <Header.Content>
                        {this.state.trailData.challengeTitle}
                      </Header.Content>
                    </Header>
                  )}
                {this.state.trailData.challengeIntro !== null &&
                  this.state.trailData.challengeIntro > ' ' && (
                    <Form.Field>
                      {this.state.trailData.challengeIntro}
                    </Form.Field>
                  )}
              </Grid.Column>
            </Grid>
          )}
          <Grid relaxed columns="1">
            <Grid.Column>
              <Segment
                secondary
                compact
                size="small"
                attached="top"
                disabled={this.state.isLoading}
              >
                <Grid relaxed columns="equal">
                  <Grid.Column
                    floated="left"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Header as="h4" size="small">
                      <Header.Content>Activities</Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column size={2}>
                    <Button
                      floated="right"
                      basic
                      icon="plus"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.setModalChallengeVisible(
                          true,
                          'NEW',
                          'challenges',
                          null
                        )
                      }}
                    />
                    {this.state.needChallengeRefresh ? (
                      <Button
                        floated="right"
                        basic
                        icon="save"
                        content="Save Order"
                        color="black"
                        size="tiny"
                        onClick={() => {
                          this.saveOrder('challenges')
                        }}
                      />
                    ) : null}
                  </Grid.Column>
                </Grid>
              </Segment>
              {this.state.challenges.length > 0 && (
                <DragDropContext
                  onDragEnd={(result) => {
                    this.onDragEnd(result, 'challenges')
                  }}
                >
                  <Table
                    attached
                    compact
                    singleLine
                    size="small"
                    className="LINK_UNDERLINE TABLE_FIXED"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>#</Table.HeaderCell>
                        <Table.HeaderCell>Contents</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Points</Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Droppable droppableId="tableBody">
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body {...provided.droppableProps}>
                            {this.state.challenges.map(function (
                              challenge,
                              index
                            ) {
                              return (
                                <Draggable
                                  key={challenge.key}
                                  draggableId={challenge.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <Table.Row
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Table.Cell width={1}>
                                          {challenge.sequence}
                                        </Table.Cell>
                                        <Table.Cell className="FORM_CONTENT">
                                          <span
                                            onClick={() => {
                                              this.setModalChallengeVisible(
                                                true,
                                                'EDIT',
                                                'challenges',
                                                challenge.completeData
                                              )
                                            }}
                                          >
                                            {challenge.title}
                                          </span>
                                        </Table.Cell>
                                        <Table.Cell width={2}>
                                          {challenge.completeData.points}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Button
                                            icon="trash"
                                            basic
                                            size="tiny"
                                            floated="right"
                                            color="red"
                                            onClick={() => {
                                              this.openChallengeDeleteModal(
                                                challenge.id
                                              )
                                            }}
                                          />
                                        </Table.Cell>
                                      </Table.Row>
                                    </Ref>
                                  )}
                                </Draggable>
                              )
                            },
                            this)}
                            {provided.placeholder}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              )}
            </Grid.Column>

            <Grid.Column>
              <Segment
                secondary
                compact
                size="small"
                attached="top"
                disabled={this.state.isLoading}
              >
                <Grid relaxed columns="equal">
                  <Grid.Column
                    floated="left"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Header as="h4" size="small">
                      <Header.Content>Questions</Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column size={2}>
                    <Button
                      floated="right"
                      basic
                      icon="plus"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.setModalChallengeVisible(
                          true,
                          'NEW',
                          'questions',
                          null
                        )
                      }}
                    />
                    {this.state.needQuestionRefresh && (
                      <Button
                        floated="right"
                        basic
                        icon="save"
                        content="Save Order"
                        color="black"
                        size="tiny"
                        onClick={() => {
                          this.saveOrder('questions')
                        }}
                      />
                    )}
                  </Grid.Column>
                </Grid>
              </Segment>
              {this.state.questions.length > 0 && (
                <DragDropContext
                  onDragEnd={(result) => {
                    this.onDragEnd(result, 'questions')
                  }}
                >
                  <Table
                    attached
                    compact
                    singleLine
                    size="small"
                    className="LINK_UNDERLINE TABLE_FIXED"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>#</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Connected with</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Points</Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Droppable droppableId="tableBody">
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body {...provided.droppableProps}>
                            {this.state.questions.map(function (
                              question,
                              index
                            ) {
                              return (
                                <Draggable
                                  key={question.key}
                                  draggableId={question.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <Table.Row
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Table.Cell width={1}>
                                          {question.sequence}
                                        </Table.Cell>
                                        <Table.Cell className="FORM_CONTENT">
                                          <span
                                            onClick={() => {
                                              this.setModalChallengeVisible(
                                                true,
                                                'EDIT',
                                                'questions',
                                                question.completeData
                                              )
                                            }}
                                          >
                                            {question.title}
                                          </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {question.completeData.stepTitle}
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                          {question.completeData.points}
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                          <Button
                                            icon="trash"
                                            basic
                                            size="tiny"
                                            floated="right"
                                            color="red"
                                            onClick={() => {
                                              this.openChallengeDeleteModal(
                                                question.id
                                              )
                                            }}
                                          />
                                        </Table.Cell>
                                      </Table.Row>
                                    </Ref>
                                  )}
                                </Draggable>
                              )
                            },
                            this)}
                            {provided.placeholder}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    )
  }

  renderQuiz() {
    return (
      <>
        <Segment attached="top" color="black">
          <Grid relaxed columns="equal">
            <Grid.Column
              floated="left"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Header as="h4">
                <Icon name="trophy" />
                <Header.Content>Quiz</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
        {((this.state.trailData.challengeTitle !== null &&
          this.state.trailData.challengeTitle > ' ') ||
          (this.state.trailData.challengeIntro !== null &&
            this.state.trailData.challengeIntro > ' ')) && (
          <Segment attached>
            <Grid relaxed columns="1">
              <Grid.Column>
                {this.state.trailData.challengeTitle !== null &&
                  this.state.trailData.challengeTitle > ' ' && (
                    <Header as="h3">
                      <Header.Content>
                        {this.state.trailData.challengeTitle}
                      </Header.Content>
                    </Header>
                  )}
                {this.state.trailData.challengeIntro !== null &&
                  this.state.trailData.challengeIntro > ' ' && (
                    <Form.Field>
                      {this.state.trailData.challengeIntro}
                    </Form.Field>
                  )}
              </Grid.Column>
            </Grid>
          </Segment>
        )}
        <Segment attached>
          <Form>
            <Grid relaxed columns="5">
              <Grid.Column>
                <Form.Field>
                  <label>Type</label>
                  <div className="FORM_CONTENT">
                    {this.displayQuizType(this.state.trailData.quizType)}
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Order</label>
                  <div className="FORM_CONTENT">
                    {this.displayQuizQuestionOrder(
                      this.state.trailData.quizQuestionOrder
                    )}
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Time limit</label>
                  <div className="FORM_CONTENT">
                    {this.displayDuration(this.state.trailData.quizTimeLimit)}
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Score for Approval</label>
                  <div className="FORM_CONTENT">
                    {this.state.trailData.quizPassingScore}
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Allow redo?</label>
                  <div className="FORM_CONTENT">
                    {this.state.trailData.quizRetakeAllowed ? 'Yes' : 'No'}
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
          <Grid relaxed columns="1">
            <Grid.Column>
              <Segment
                secondary
                compact
                size="small"
                attached="top"
                disabled={this.state.isLoading}
              >
                <Grid relaxed columns="equal">
                  <Grid.Column
                    floated="left"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Header as="h4" size="small">
                      <Header.Content>Categories</Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column size={2}>
                    <Button
                      floated="right"
                      basic
                      icon="plus"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.setModalQuizCategoryVisible(true, 'NEW', null)
                      }}
                    />
                    {this.state.needQuizCategoryRefresh ? (
                      <Button
                        floated="right"
                        basic
                        icon="save"
                        content="Save Order"
                        color="black"
                        size="tiny"
                        onClick={() => {
                          this.saveOrder('quizCategories')
                        }}
                      />
                    ) : null}
                  </Grid.Column>
                </Grid>
              </Segment>
              {this.state.quizCategories.length > 0 && (
                <DragDropContext
                  onDragEnd={(result) => {
                    this.onDragEnd(result, 'quizCategories')
                  }}
                >
                  <Table
                    attached
                    compact
                    singleLine
                    size="small"
                    className="LINK_UNDERLINE TABLE_FIXED"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Questions</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Points</Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Droppable droppableId="tableBody">
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body {...provided.droppableProps}>
                            {this.state.quizCategories.map(function (
                              category,
                              index
                            ) {
                              return (
                                <Draggable
                                  key={category.id}
                                  draggableId={category.externalId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <Table.Row
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Table.Cell width={1}>
                                          {category.externalId}
                                        </Table.Cell>
                                        <Table.Cell className="FORM_CONTENT">
                                          <span
                                            onClick={() => {
                                              this.setModalQuizCategoryVisible(
                                                true,
                                                'EDIT',
                                                category
                                              )
                                            }}
                                          >
                                            {category.title}
                                          </span>
                                        </Table.Cell>
                                        <Table.Cell width={2}>
                                          {category.questions}
                                        </Table.Cell>
                                        <Table.Cell width={2}>
                                          {category.points}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Button
                                            icon="trash"
                                            basic
                                            size="tiny"
                                            floated="right"
                                            color="red"
                                            onClick={() => {
                                              this.openQuizCategoryDeleteModal(
                                                category
                                              )
                                            }}
                                          />
                                        </Table.Cell>
                                      </Table.Row>
                                    </Ref>
                                  )}
                                </Draggable>
                              )
                            },
                            this)}
                            {provided.placeholder}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              )}
            </Grid.Column>

            <Grid.Column>
              <Segment
                secondary
                compact
                size="small"
                attached="top"
                disabled={this.state.isLoading}
              >
                <Grid relaxed columns="equal">
                  <Grid.Column
                    floated="left"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Header as="h4" size="small">
                      <Header.Content>Questions</Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column size={2}>
                    <Button
                      floated="right"
                      basic
                      icon="plus"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.setModalQuizQuestionVisible(true, 'NEW', null)
                      }}
                    />
                    {this.state.needQuizQuestionRefresh ? (
                      <Button
                        floated="right"
                        basic
                        icon="save"
                        content="Save Order"
                        color="black"
                        size="tiny"
                        onClick={() => {
                          this.saveOrder('quizQuestions')
                        }}
                      />
                    ) : null}
                  </Grid.Column>
                </Grid>
              </Segment>
              {this.state.quizQuestions.length > 0 && (
                <DragDropContext
                  onDragEnd={(result) => {
                    this.onDragEnd(result, 'quizQuestions')
                  }}
                >
                  <Table
                    attached
                    compact
                    singleLine
                    size="small"
                    className="LINK_UNDERLINE TABLE_FIXED"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                        <Table.HeaderCell width={8}>Question</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Category</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Points</Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Droppable droppableId="tableBody">
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body {...provided.droppableProps}>
                            {this.state.quizQuestions.map(function (
                              question,
                              index
                            ) {
                              return (
                                <Draggable
                                  key={question.id}
                                  draggableId={question.externalId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <Table.Row
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Table.Cell width={1}>
                                          {question.externalId}
                                        </Table.Cell>
                                        <Table.Cell
                                          width={8}
                                          className="FORM_CONTENT"
                                        >
                                          <span
                                            onClick={() => {
                                              this.setModalQuizQuestionVisible(
                                                true,
                                                'EDIT',
                                                question
                                              )
                                            }}
                                          >
                                            {question.content}
                                          </span>
                                        </Table.Cell>
                                        <Table.Cell width={3}>
                                          {question.categoryTitle}
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                          {question.points}
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                          <Button
                                            icon="trash"
                                            basic
                                            size="tiny"
                                            floated="right"
                                            color="red"
                                            onClick={() => {
                                              this.openQuizQuestionDeleteModal(
                                                question
                                              )
                                            }}
                                          />
                                        </Table.Cell>
                                      </Table.Row>
                                    </Ref>
                                  )}
                                </Draggable>
                              )
                            },
                            this)}
                            {provided.placeholder}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    )
  }

  renderFaq() {
    return (
      <>
        <Segment attached="top" color="black">
          <Grid relaxed columns="equal">
            <Grid.Column
              floated="left"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Header as="h4">
                <Icon name="question" />
                <Header.Content>FAQ</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column size={2}>
              <Button
                floated="right"
                basic
                icon="edit"
                content="New"
                color="black"
                size="tiny"
                onClick={() => {
                  this.setModalFaqVisible(true, 'NEW', null)
                }}
              />
              {this.state.needFaqRefresh ? (
                <Button
                  floated="right"
                  basic
                  icon="save"
                  content="Save Order"
                  color="black"
                  size="tiny"
                  onClick={() => {
                    this.saveOrder('faqs')
                  }}
                />
              ) : null}
            </Grid.Column>
          </Grid>
        </Segment>
        <DragDropContext
          onDragEnd={(result) => {
            this.onDragEnd(result, 'faqs')
          }}
        >
          <Table
            attached
            compact
            singleLine
            size="small"
            className="LINK_UNDERLINE TABLE_FIXED"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>#</Table.HeaderCell>
                <Table.HeaderCell>Question</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Droppable droppableId="tableBody">
              {(provided, snapshot) => (
                <Ref innerRef={provided.innerRef}>
                  <Table.Body {...provided.droppableProps}>
                    {this.state.faqs.map(function (faq, index) {
                      return (
                        <Draggable
                          key={faq.key}
                          draggableId={faq.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Ref innerRef={provided.innerRef}>
                              <Table.Row
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Table.Cell width={1}>
                                  {faq.sequence}
                                </Table.Cell>
                                <Table.Cell className="FORM_CONTENT">
                                  <span
                                    onClick={() => {
                                      this.setModalFaqVisible(
                                        true,
                                        'EDIT',
                                        faq.completeData
                                      )
                                    }}
                                  >
                                    {faq.title}
                                  </span>
                                </Table.Cell>
                                <Table.Cell>
                                  <Button
                                    icon="trash"
                                    basic
                                    size="tiny"
                                    floated="right"
                                    color="red"
                                    onClick={() => {
                                      this.openFaqDeleteModal(faq.id)
                                    }}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            </Ref>
                          )}
                        </Draggable>
                      )
                    }, this)}
                    {provided.placeholder}
                  </Table.Body>
                </Ref>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </>
    )
  }

  render() {
    document.title = 'Trail :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="road" />
                    <Header.Content>
                      {this.state.trailData.title}
                      <Header.Subheader>Trail</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                  <Button
                    floated="right"
                    icon="eye"
                    content="Preview"
                    size="small"
                    onClick={() => {
                      this.loadPreview()
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="3">
                  <Grid.Column>
                    <Form.Field>
                      <label>Code</label>
                      <div className="FORM_CONTENT">
                        {this.state.trailData.externalId}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Type</label>
                      <div className="FORM_CONTENT">
                        {this.displayType(this.state.trailData.type)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  {this.state.trailData.type !== 'Q' && (
                    <Grid.Column>
                      <Form.Field>
                        <label>Project</label>
                        <div className="FORM_CONTENT">
                          {this.state.trailData.projectExternalId !== null
                            ? this.state.trailData.projectExternalId +
                              ' - ' +
                              this.state.trailData.projectTitle
                            : 'Uninformed'}
                        </div>
                      </Form.Field>
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <Form.Field>
                      <label>Estimated time</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(
                          this.state.trailData.estimatedTime
                        )}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Online time</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(this.state.trailData.onlineTime)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Working time</label>
                      <div className="FORM_CONTENT">
                        {this.displayDuration(this.state.trailData.workTime)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Background image</label>
                      <div className="FORM_CONTENT">
                        {this.state.trailData.backgroundImageData !== null
                          ? this.state.trailData.backgroundImageData.title
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>

                <Grid relaxed columns="1">
                  <Grid.Column>
                    <Segment
                      secondary
                      compact
                      size="small"
                      attached="top"
                      disabled={this.state.isLoading}
                    >
                      <Grid relaxed columns="equal">
                        <Grid.Column
                          floated="left"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Header as="h4" size="small">
                            <Header.Content>Objectives</Header.Content>
                          </Header>
                        </Grid.Column>
                        <Grid.Column size={2}>
                          <Button
                            floated="right"
                            basic
                            icon="plus"
                            color="black"
                            size="tiny"
                            onClick={() => {
                              this.setModalSkillVisible(true, 'NEW', 'O', null)
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    {this.state.outcomeSkills.length > 0 && (
                      <Table
                        attached
                        compact="very"
                        singleLine
                        size="small"
                        className="LINK_UNDERLINE TABLE_FIXED"
                      >
                        <Table.Body>
                          {this.state.outcomeSkills.map(function (skill) {
                            return (
                              <Table.Row key={skill.id}>
                                <Table.Cell width={5} className="FORM_CONTENT">
                                  {skill.group} &gt; {skill.category} &gt;{' '}
                                  <span
                                    onClick={() => {
                                      this.setModalPrerequisiteVisible(
                                        true,
                                        'EDIT',
                                        'O',
                                        skill
                                      )
                                    }}
                                  >
                                    {skill.title}
                                  </span>
                                </Table.Cell>
                                <Table.Cell width={1}>
                                  {this.displayLevel(skill.level)}
                                </Table.Cell>
                                <Table.Cell width={1}>
                                  <Button
                                    icon="trash"
                                    basic
                                    size="tiny"
                                    floated="right"
                                    color="red"
                                    onClick={() => {
                                      this.openSkillDeleteModal('O', skill.id)
                                    }}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          }, this)}
                        </Table.Body>
                      </Table>
                    )}
                  </Grid.Column>

                  <Grid.Column>
                    <Segment
                      secondary
                      compact
                      size="small"
                      attached="top"
                      disabled={this.state.isLoading}
                    >
                      <Grid relaxed columns="equal">
                        <Grid.Column
                          floated="left"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Header as="h4" size="small">
                            <Header.Content>Prerequisite</Header.Content>
                          </Header>
                        </Grid.Column>
                        <Grid.Column size={2}>
                          <Button
                            floated="right"
                            basic
                            icon="plus"
                            color="black"
                            size="tiny"
                            onClick={() => {
                              this.setModalSkillVisible(true, 'NEW', 'P', null)
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    {this.state.prerequisiteSkills.length > 0 && (
                      <Table
                        attached
                        compact="very"
                        singleLine
                        size="small"
                        className="LINK_UNDERLINE TABLE_FIXED"
                      >
                        <Table.Body>
                          {this.state.prerequisiteSkills.map(function (skill) {
                            return (
                              <Table.Row key={skill.id}>
                                <Table.Cell width={5} className="FORM_CONTENT">
                                  {skill.group} &gt; {skill.category} &gt;{' '}
                                  <span
                                    onClick={() => {
                                      this.setModalPrerequisiteVisible(
                                        true,
                                        'EDIT',
                                        'P',
                                        skill
                                      )
                                    }}
                                  >
                                    {skill.title}
                                  </span>
                                </Table.Cell>
                                <Table.Cell width={1}>
                                  {this.displayLevel(skill.level)}
                                </Table.Cell>
                                <Table.Cell width={1}>
                                  <Button
                                    icon="trash"
                                    basic
                                    size="tiny"
                                    floated="right"
                                    color="red"
                                    onClick={() => {
                                      this.openSkillDeleteModal('P', skill.id)
                                    }}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          }, this)}
                        </Table.Body>
                      </Table>
                    )}
                  </Grid.Column>
                </Grid>

                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Created by</label>
                      <div className="FORM_CONTENT">
                        {this.state.trailData.createdBy.firstName +
                          ' ' +
                          this.state.trailData.createdBy.lastName}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Created on</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('creationDate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <label>Last modified by</label>
                      <div className="FORM_CONTENT">
                        {this.state.trailData.updatedBy != null
                          ? this.state.trailData.updatedBy.firstName +
                            ' ' +
                            this.state.trailData.updatedBy.lastName
                          : '-'}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last modified on</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime('lastUpdate')}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column
                  floated="left"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Header as="h4">
                    <Icon name="play" />
                    <Header.Content>Steps</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    basic
                    icon="edit"
                    content="New"
                    color="black"
                    size="tiny"
                    onClick={() => {
                      this.setModalStepVisible(
                        true,
                        'NEW',
                        'knowledgeSteps',
                        null
                      )
                    }}
                  />
                  {this.state.needKnowledgeStepRefresh && (
                    <Button
                      floated="right"
                      basic
                      icon="save"
                      content="Save Order"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.saveOrder('knowledgeSteps')
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Segment>
            <DragDropContext
              onDragEnd={(result) => {
                this.onDragEnd(result, 'knowledgeSteps')
              }}
            >
              <Table
                attached
                compact
                singleLine
                size="small"
                className="LINK_UNDERLINE TABLE_FIXED"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="tableBody">
                  {(provided) => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {this.state.knowledgeSteps.map(function (step, index) {
                          return (
                            <Draggable
                              key={step.key}
                              draggableId={step.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Ref innerRef={provided.innerRef}>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={1}>
                                      {step.sequence}
                                    </Table.Cell>
                                    <Table.Cell className="FORM_CONTENT">
                                      <span
                                        onClick={() => {
                                          this.setModalStepVisible(
                                            true,
                                            'EDIT',
                                            'knowledgeSteps',
                                            step.completeData
                                          )
                                        }}
                                      >
                                        {step.title}
                                      </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        icon="trash"
                                        basic
                                        size="tiny"
                                        floated="right"
                                        color="red"
                                        onClick={() => {
                                          this.openStepDeleteModal(step.id)
                                        }}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Ref>
                              )}
                            </Draggable>
                          )
                        }, this)}
                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>

            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column
                  floated="left"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Header as="h4">
                    <Icon name="linkify" />
                    <Header.Content>Resources</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    basic
                    icon="edit"
                    content="New"
                    color="black"
                    size="tiny"
                    onClick={() => {
                      this.setModalStepVisible(
                        true,
                        'NEW',
                        'resourcesSteps',
                        null
                      )
                    }}
                  />
                  {this.state.needResourceStepsRefresh && (
                    <Button
                      floated="right"
                      basic
                      icon="save"
                      content="Save Order"
                      color="black"
                      size="tiny"
                      onClick={() => {
                        this.saveOrder('resourcesSteps')
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Segment>
            <DragDropContext
              onDragEnd={(result) => {
                this.onDragEnd(result, 'resourcesSteps')
              }}
            >
              <Table
                attached
                compact
                singleLine
                size="small"
                className="LINK_UNDERLINE TABLE_FIXED"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Connected with</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Time</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="tableBody">
                  {(provided) => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {this.state.resourcesSteps.map(function (step, index) {
                          return (
                            <Draggable
                              key={step.key}
                              draggableId={step.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Ref innerRef={provided.innerRef}>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={1}>
                                      {step.sequence}
                                    </Table.Cell>
                                    <Table.Cell className="FORM_CONTENT">
                                      <span
                                        onClick={() => {
                                          this.setModalStepVisible(
                                            true,
                                            'EDIT',
                                            'resourcesSteps',
                                            step.completeData
                                          )
                                        }}
                                      >
                                        {step.title}
                                      </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {step.completeData.parentStepTitle}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                      {this.displayDuration(
                                        step.completeData.estimatedTime
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        icon="trash"
                                        basic
                                        size="tiny"
                                        floated="right"
                                        color="red"
                                        onClick={() => {
                                          this.openStepDeleteModal(step.id)
                                        }}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Ref>
                              )}
                            </Draggable>
                          )
                        }, this)}
                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>

            {this.state.trailData.type !== 'Q' && this.renderChallenge()}

            {this.state.trailData.type === 'Q' && this.renderQuiz()}

            {this.state.trailData.type !== 'Q' && this.renderFaq()}
          </div>

          <TrailSave
            viewMode="EDIT"
            editData={this.state.trailData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
            lang={this.state.lang}
            langList={this.state.langList}
            langDefault={this.state.langDefault}
          />

          <StepSave
            viewMode={this.state.modalViewMode}
            editData={this.state.stepData}
            trailId={this.state.id}
            type={this.state.stepType}
            nextSequence={this.state.nextSequence}
            id={this.state.stepId}
            open={this.state.isStepModalOpen}
            onDone={this.onDoneStepModal}
            onCancelClick={() => {
              this.setModalStepVisible(false)
            }}
            lang={this.state.lang}
            langList={this.state.langList}
            langDefault={this.state.langDefault}
          />

          <ChallengeSave
            viewMode={this.state.modalViewMode}
            editData={this.state.challengeData}
            trailId={this.state.id}
            type={this.state.challengeType}
            nextSequence={this.state.nextSequence}
            id={this.state.challengeId}
            open={this.state.isChallengeModalOpen}
            onDone={this.onDoneChallengeModal}
            onCancelClick={() => {
              this.setModalChallengeVisible(false)
            }}
            lang={this.state.lang}
            langList={this.state.langList}
            langDefault={this.state.langDefault}
          />

          <SkillSave
            viewMode={this.state.modalViewMode}
            editData={this.state.skillData}
            trailId={this.state.id}
            recordType={this.state.skillRecordType}
            id={this.state.skillId}
            open={this.state.isSkillModalOpen}
            onDone={this.onDoneSkillModal}
            onCancelClick={() => {
              this.setModalSkillVisible(false)
            }}
          />

          <FaqSave
            viewMode={this.state.modalViewMode}
            editData={this.state.faqData}
            getFaqStepList={this.getFaqStepList}
            trailId={this.state.id}
            nextSequence={this.state.nextSequence}
            id={this.state.faqId}
            open={this.state.isFaqModalOpen}
            onDone={this.onDoneFaqModal}
            onCancelClick={() => {
              this.setModalFaqVisible(false)
            }}
            lang={this.state.lang}
            langList={this.state.langList}
            langDefault={this.state.langDefault}
          />

          <QuizCategorySave
            viewMode={this.state.modalViewMode}
            editData={this.state.quizCategoryData}
            trailId={this.state.id}
            nextSequence={this.state.nextSequence}
            id={this.state.quizCategoryId}
            open={this.state.isQuizCategoryModalOpen}
            onDone={this.onDoneQuizModal}
            onCancelClick={() => {
              this.setModalQuizCategoryVisible(false)
            }}
          />

          <QuizQuestionSave
            viewMode={this.state.modalViewMode}
            editData={this.state.quizQuestionData}
            trailId={this.state.id}
            nextSequence={this.state.nextSequence}
            id={this.state.quizQuestionId}
            open={this.state.isQuizQuestionModalOpen}
            onDone={this.onDoneQuizModal}
            onCancelClick={() => {
              this.setModalQuizQuestionVisible(false)
            }}
          />

          <Modal
            open={this.state.previewModal.isOpen}
            className="animated fadeIn"
            size="large"
          >
            <Modal.Header>
              <Grid relaxed columns={this.state.lang !== this.state.langDefault ? 2 : 1}>
                <Grid.Column width={9}>
                  <Icon name="eye" /> Content Preview
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  <Dropdown
                    button
                    className="icon"
                    floating
                    labeled
                    icon="world"
                    size="tinny"
                    value={this.state.lang}
                    options={this.state.langList}
                    placeholder="Choose Language"
                    onChange={(event, { value }) => {
                      this.viewTrailLang(value)
                      this.setState((state) => {
                        state.lang = value
                        return state
                      })
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Header>
            <Modal.Content>
              {this.state.previewModal.isLoading && (
                <Dimmer active className="animated fadeIn fast">
                  <Loader />
                </Dimmer>
              )}
              <ReactMarkdown
                source={this.state.previewModal.content}
                className="markdown-body"
                renderers={{
                  link: (props) => (
                    <a
                      href={props.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  ),
                  image: Image,
                  code: CodeBlock
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => {
                  this.setState(function (state) {
                    state.isLoading = false
                    state.previewModal.content = ''
                    state.previewModal.isOpen = false
                    state.lang = this.state.langDefault
                    return state
                  })
                }}
              >
                Back
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this track?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveTrail}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.stepDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this step?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveStep}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.challengeDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this challenge?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveChallenge}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.skillDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content={
                'Do you really want to delete this ' +
                (this.state.skillDeleteModal.recordType === 'P'
                  ? 'Prerequisite'
                  : 'Objective') +
                '?'
              }
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveSkill}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.faqDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content={'Do you really want to delete this FAQ?'}
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveFaq}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.quizCategoryDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content={
                'Do you really want to remove the Category ' +
                this.state.quizCategoryDeleteModal.externalId +
                ' of the questionnaire?'
              }
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveQuizCategory}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.quizQuestionDeleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content={
                'Do you really want to remove the Question ' +
                this.state.quizQuestionDeleteModal.externalId +
                ' of the questionnaire?'
              }
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveQuizQuestion}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

function Image(props) {
  return <img {...props} alt="" style={{ maxWidth: '100%' }} />
}

export default TrailView
