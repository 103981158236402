import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  Dropdown,
  Segment,
  Header,
  Table,
  Checkbox,
  TextArea
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class ChallengeSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    id: this.props.id,
    trailId: this.props.trailId,
    type: this.props.type,
    isLoading: false,
    formLang: {
      content: '',
      answers: [],
      evaluations: []
    },
    form: {
      hasError: false,
      errorMessage: null,
      type: '',
      stepId: null,
      points: 0,
      content: '',
      sequence: null,
      answers: [],
      evaluations: []
    },

    lang: this.props.lang,
    langList: this.props.langList,
    langDefault: this.props.langDefault,

    stepList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.addNewLine = this.addNewLine.bind(this)
    this.removeLine = this.removeLine.bind(this)
    this.handleFormIndexChange = this.handleFormIndexChange.bind(this)
    this.handleValueIndexChange = this.handleValueIndexChange.bind(this)
    this.loadStepList = this.loadStepList.bind(this)
    this.receivedStepList = this.receivedStepList.bind(this)
    this.receivedStepLangData = this.receivedStepLangData.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.type = this.props.type
          if (this.props.editData != null) {
            state.id = this.props.id
            state.form.type = this.props.editData.type
            state.form.points = this.props.editData.points
            state.form.sequence = this.props.editData.sequence
            state.form.stepId = this.props.editData.stepId
            state.form.content = this.props.editData.content
            state.form.answers = this.props.editData.answers
            state.form.evaluations = this.props.editData.evaluations
          }
          return state
        })
        this.loadStepList(
          this.props.editData.stepId === null
            ? ''
            : this.props.editData.stepTitle
        )
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.type = this.props.type
          state.form.type = ''
          state.form.points = 0
          state.form.sequence = this.props.nextSequence
          state.form.stepId = null
          state.form.content = ''
          state.form.answers = [{ answer: '', isCorrect: false }]
          state.form.evaluations = [
            { type: 'M', content: '', message: '', details: '' }
          ]
          return state
        })
        this.loadStepList('')
      }
    }
  }

  loadStepList(searchText, fieldName) {
    RequestManager.requestAPI(
      '/admin/trailstep/list',
      {
        trailId: this.state.trailId,
        limit: 5,
        search: searchText
      },
      this.receivedStepList
    )
  }

  receivedStepList(hasError, data) {
    var steps = []
    if (!hasError) {
      for (var index in data.steps) {
        var step = data.steps[index]
        if (step.type === 'K') {
          steps.push({
            key: step.id,
            value: step.id,
            text: step.title
          })
        }
      }
    }
    this.setState(function (state) {
      state.stepList = steps
      return state
    })
  }

  loadStepLangData(language) {
    RequestManager.requestAPI(
      '/admin/trailchallenge/view-lang',
      {
        id: this.props.id,
        language
      },
      this.receivedStepLangData
    )
  }

  receivedStepLangData(hasError, receivedData) {
    if (!hasError) {
      let arrTemp = []
      this.props.editData.answers.forEach((answer, index) => {
        const obj = {
          answerLang: '',
          sequence: index + 1,
          isCorrect: answer.isCorrect
        }
        arrTemp.push(obj)
      })

      if (receivedData.answers && receivedData.answers.length > 0) {
        arrTemp.map((arr, index) => {
          arr.answerLang = receivedData.answers[index].answerLang
            ? receivedData.answers[index].answerLang
            : ''
          arr.sequence = receivedData.answers[index].sequence
            ? receivedData.answers[index].sequence
            : index + 1
          return arr
        })
      }

      this.setState(function (state) {
        state.isLoading = false
        state.formLang.content = receivedData.contentLang
          ? receivedData.contentLang
          : ''
        state.formLang.answers = arrTemp
        return state
      })
    }
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        state.errorMessage = null
        return state
      })
    } else {
      var dataToSend = {
        trailId: this.state.trailId,
        type: this.state.form.type,
        content: this.state.form.content,
        sequence: this.state.form.sequence,
        stepId: this.state.form.stepId,
        answers: this.state.form.answers,
        evaluations: this.state.form.evaluations,
        points: parseFloat(this.state.form.points)
      }
      for (var i = 0; i < dataToSend.answers.length; i++) {
        dataToSend.answers[i]['sequence'] = i + 1
      }
      for (i = 0; i < dataToSend.evaluations.length; i++) {
        dataToSend.evaluations[i]['sequence'] = i + 1
      }
      if (this.state.type === 'challenges') {
        dataToSend.type = 'A'
      }
      if (this.props.viewMode === 'EDIT') {
        dataToSend.id = this.state.id
      }
      RequestManager.requestAPI(
        '/admin/trailchallenge/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        state.errorMessage = null
        return state
      })
    } else {
      var dataToSend = {
        id: this.props.id,
        language: this.state.lang,
        contentLang: this.state.formLang.content,
        answers: this.state.formLang.answers
      }
      RequestManager.requestAPI(
        '/admin/trailchallenge/save-lang',
        dataToSend,
        this.receivedResponse
      ).then(() => {
        this.setState((state) => {
          state.lang = this.state.langDefault
          state.formLang.content = ''
          state.formLang.answers = []
          return state
        })
      })
    }
  }

  indexToChar(index) {
    return String.fromCharCode(65 + index)
  }

  getTitleIcon(type) {
    switch (type) {
      case 'challenges':
        return 'flag'
      case 'questions':
        return 'question'
      default:
        return ''
    }
  }

  getTitleName(type) {
    switch (type) {
      case 'challenges':
        return 'Challenge'
      case 'questions':
        return 'Questions'
      default:
        return ''
    }
  }

  receivedResponse(hasError, data) {
    if (hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        state.errorMessage = hasError.toString()
        return state
      })
    } else if (data.errorCode !== null && data.errorCode > 0) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        state.errorMessage = data.errorMessage
        return state
      })
    } else {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        state.errorMessage = null
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    var optionsSelected = 0
    if (this.state.type === 'questions') {
      for (var i = 0; i < this.state.form.answers.length; i++) {
        if (this.state.form.answers[i].isCorrect) {
          optionsSelected += 1
        }
      }
    }
    if (this.state.type === 'challenges') {
      return this.state.form.content !== ''
    } else {
      return (
        this.state.form.content !== '' &&
        this.state.form.type !== '' &&
        (optionsSelected === 1 ||
          (optionsSelected > 1 && this.state.form.type !== 'M'))
      )
    }
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleValueIndexChange(value, index, name) {
    const data = value
    this.setState(function (state) {
      if (state.type === 'questions') {
        if (data > ' ') {
          state.form.answers[index][name] = data
        } else {
          state.form.answers[index][name] = null
        }
      } else if (state.type === 'challenges') {
        if (data > ' ') {
          state.form.evaluations[index][name] = data
        } else {
          state.form.evaluations[index][name] = null
        }
      }
      return state
    })
  }

  handleFormChange(event, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[fieldName] = data
      return state
    })
  }

  handleFormLangChange(event, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.formLang[fieldName] = data
      return state
    })
  }

  handleFormIndexChange(event, index, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      if (state.type === 'questions') {
        state.form.answers[index][fieldName] = data
      } else if (state.type === 'challenges') {
        state.form.evaluations[index][fieldName] = data
      }
      return state
    })
  }

  handleFormIndexLangChange(event, index, fieldName, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      if (state.type === 'questions') {
        state.formLang.answers[index][fieldName] = data
      } else if (state.type === 'challenges') {
        state.formLang.evaluations[index][fieldName] = data
      }
      return state
    })
  }

  handleFormIndexCheck(event, index, fieldName) {
    this.setState(function (state) {
      if (state.type === 'questions') {
        state.form.answers[index][fieldName] =
          !state.form.answers[index][fieldName]
      } else if (state.type === 'challenges') {
        state.form.evaluations[index][fieldName] =
          !state.form.evaluations[index][fieldName]
      }
      return state
    })
  }

  addNewLine() {
    this.setState(function (state) {
      if (state.type === 'questions') {
        state.form.answers.push({ answer: '', isCorrect: false })
      } else if (state.type === 'challenges') {
        state.form.evaluations.push({
          type: 'M',
          content: '',
          message: '',
          details: ''
        })
      }
      return state
    })
  }

  removeLine(index) {
    this.setState(function (state) {
      if (state.type === 'questions') {
        state.form.answers.splice(index, 1)
      } else if (state.type === 'challenges') {
        state.form.evaluations.splice(index, 1)
      }
      return state
    })
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'small'
        }
      >
        <Modal.Header>
          <Grid relaxed columns="2">
            <Grid.Column width={9}>
              <Icon name={this.getTitleIcon(this.props.type)} />
              {(this.state.viewMode === 'EDIT' ? 'Edit' : 'Create') +
                ' ' +
                this.getTitleName(this.props.type)}
            </Grid.Column>
            <Grid.Column textAlign="right" width={7}>
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon="world"
                size="tinny"
                value={this.state.lang}
                options={this.state.langList}
                placeholder="Choose Language"
                onChange={(event, { value }) => {
                  this.loadStepLangData(value)
                  this.setState((state) => {
                    state.lang = value
                    return state
                  })
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>
                {this.getTitleName(this.props.type)} was not recorded
              </Message.Header>
              <p>
                {this.state.errorMessage !== null &&
                this.state.errorMessage > ' '
                  ? this.state.errorMessage
                  : 'Check the required fields and try again'}
              </p>
            </Message>
          )}
          <Form>
            {this.props.type === 'challenges' ? (
              <>
                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Form.Field required>
                      <label>Content</label>
                      <TextArea
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        icon="tag"
                        value={this.state.form.content}
                        onChange={(event) => {
                          this.handleFormChange(event, 'content')
                        }}
                        style={{ minHeight: 280 }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field required>
                        <label>Content</label>
                        <TextArea
                          disabled={this.state.isLoading}
                          icon="tag"
                          value={this.state.formLang.content}
                          onChange={(event) => {
                            this.handleFormLangChange(event, 'content')
                          }}
                          style={{ minHeight: 280 }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>

                {this.state.lang === this.state.langDefault && (
                  <Grid relaxed columns="2">
                    <Grid.Column>
                      <Form.Field required>
                        <label>Points</label>
                        <Input
                          disabled={this.state.isLoading}
                          icon="star"
                          value={this.state.form.points}
                          type="number"
                          onChange={(event) => {
                            this.handleFormChange(event, 'points', 8)
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>&nbsp;</label>
                        <Button
                          floated="right"
                          disabled={this.state.isLoading}
                          onClick={this.addNewLine}
                        >
                          Add Verification
                        </Button>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                )}

                {this.state.lang === this.state.langDefault &&
                  this.state.form.evaluations.map(function (elem, index) {
                    return (
                      <Segment
                        key={index}
                        secondary
                        compact
                        size="small"
                        attached="top"
                        disabled={this.state.isLoading}
                      >
                        <Grid relaxed columns="2">
                          <Grid.Column>
                            <Dropdown
                              disabled={this.state.isLoading}
                              value={elem.type}
                              fluid
                              selection
                              onChange={(event, { value }) => {
                                this.handleValueIndexChange(
                                  value,
                                  index,
                                  'type'
                                )
                              }}
                              options={[
                                { key: 'M', value: 'M', text: 'Metadata' },
                                { key: 'Q', value: 'Q', text: 'Query' },
                                { key: 'A', value: 'A', text: 'ApexClass' },
                                { key: 'U', value: 'U', text: 'UnitTest' }
                              ]}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Button
                              icon="trash"
                              basic
                              size="tiny"
                              floated="right"
                              color="red"
                              onClick={() => this.removeLine(index)}
                            />
                          </Grid.Column>
                        </Grid>
                        <Grid columns="1">
                          <Grid.Column>
                            <Form.Field>
                              <label>Parameters (JSON)</label>
                              <TextArea
                                disabled={this.state.isLoading}
                                value={elem.content}
                                onChange={(event) => {
                                  this.handleFormIndexChange(
                                    event,
                                    index,
                                    'content'
                                  )
                                }}
                                style={{ minHeight: 120 }}
                              />
                            </Form.Field>
                            {elem.type === 'Q' && (
                              <>
                                <Form.Field>
                                  <label>Error Message</label>
                                  <Input
                                    maxLength="255"
                                    disabled={this.state.isLoading}
                                    value={elem.message}
                                    onChange={(event) => {
                                      this.handleFormIndexChange(
                                        event,
                                        index,
                                        'message'
                                      )
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>More details</label>
                                  <TextArea
                                    disabled={this.state.isLoading}
                                    value={elem.details}
                                    onChange={(event) => {
                                      this.handleFormIndexChange(
                                        event,
                                        index,
                                        'details'
                                      )
                                    }}
                                    style={{ minHeight: 80 }}
                                  />
                                </Form.Field>
                              </>
                            )}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    )
                  }, this)}
              </>
            ) : (
              <>
                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Form.Field required>
                      <label>Question</label>
                      <TextArea
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        icon="tag"
                        value={this.state.form.content}
                        onChange={(event) => {
                          this.handleFormChange(event, 'content')
                        }}
                        maxLength="65000"
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field required>
                        <label>Question</label>
                        <TextArea
                          disabled={this.state.isLoading}
                          icon="tag"
                          value={this.state.formLang.content}
                          onChange={(event) => {
                            this.handleFormLangChange(event, 'content')
                          }}
                          maxLength="65000"
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>
                {this.state.lang === this.state.langDefault && (
                  <Grid relaxed columns="2">
                    <Grid.Column>
                      <Form.Field required>
                        <label>Points</label>
                        <Input
                          disabled={this.state.isLoading}
                          icon="star"
                          value={this.state.form.points}
                          type="number"
                          onChange={(event) => {
                            this.handleFormChange(event, 'points', 8)
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field required>
                        <label>Type</label>
                        <Dropdown
                          disabled={this.state.isLoading}
                          value={this.state.form.type}
                          fluid
                          selection
                          onChange={(event, { value }) => {
                            this.handleValueChange(value, 'type')
                          }}
                          options={[
                            { key: 'M', value: 'M', text: 'MultipleChoice' },
                            { key: 'C', value: 'C', text: 'Checkbox' }
                          ]}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                )}

                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Segment
                      secondary
                      compact
                      size="small"
                      attached="top"
                      disabled={this.state.isLoading}
                    >
                      <Grid relaxed columns="equal">
                        <Grid.Column
                          floated="left"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Header as="h4" size="small">
                            <Icon name="list" size="tiny" />
                            <Header.Content>List of options</Header.Content>
                          </Header>
                        </Grid.Column>
                        {this.state.lang === this.state.langDefault && (
                          <Grid.Column size={2}>
                            <Button
                              floated="right"
                              basic
                              icon="plus"
                              color="black"
                              size="tiny"
                              onClick={this.addNewLine}
                            />
                          </Grid.Column>
                        )}
                      </Grid>
                    </Segment>
                    <Table
                      attached
                      compact="very"
                      singleLine
                      size="small"
                      className="LINK_UNDERLINE TABLE_FIXED"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={1}>?</Table.HeaderCell>
                          <Table.HeaderCell>Answer</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Correct</Table.HeaderCell>
                          {this.state.lang === this.state.langDefault && (
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                          )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.form.answers.map(function (elem, index) {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell width={1}>
                                {this.indexToChar(index)}
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.lang !== this.state.langDefault
                                  }
                                  maxLength="255"
                                  value={elem.answer}
                                  onChange={(event) => {
                                    this.handleFormIndexChange(
                                      event,
                                      index,
                                      'answer',
                                      255
                                    )
                                  }}
                                  fluid
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Checkbox
                                  checked={elem.isCorrect}
                                  disabled={
                                    this.state.lang !== this.state.langDefault
                                  }
                                  onChange={(event) => {
                                    this.handleFormIndexCheck(
                                      event,
                                      index,
                                      'isCorrect'
                                    )
                                  }}
                                />
                              </Table.Cell>
                              {this.state.lang === this.state.langDefault && (
                                <Table.Cell width={1}>
                                  <Button
                                    icon="trash"
                                    basic
                                    size="tiny"
                                    floated="right"
                                    color="red"
                                    onClick={() => this.removeLine(index)}
                                  />
                                </Table.Cell>
                              )}
                            </Table.Row>
                          )
                        }, this)}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Segment
                        secondary
                        compact
                        size="small"
                        attached="top"
                        disabled={this.state.isLoading}
                      >
                        <Grid relaxed columns="equal">
                          <Grid.Column
                            floated="left"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Header as="h4" size="small">
                              <Icon name="list" size="tiny" />
                              <Header.Content>List of options</Header.Content>
                            </Header>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                      <Table
                        attached
                        compact="very"
                        singleLine
                        size="small"
                        className="LINK_UNDERLINE TABLE_FIXED"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={1}>?</Table.HeaderCell>
                            <Table.HeaderCell>Answer</Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                              Correct
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.formLang.answers.map(function (
                            elem,
                            index
                          ) {
                            return (
                              <Table.Row key={index}>
                                <Table.Cell width={1}>
                                  {this.indexToChar(index)}
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    disabled={this.state.isLoading}
                                    maxLength="255"
                                    value={elem.answerLang}
                                    onChange={(event) => {
                                      this.handleFormIndexLangChange(
                                        event,
                                        index,
                                        'answerLang',
                                        255
                                      )
                                    }}
                                    fluid
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Checkbox
                                    checked={elem.isCorrect}
                                    disabled={
                                      this.state.lang !== this.state.langDefault
                                    }
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          },
                          this)}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  )}
                </Grid>
                {this.state.lang === this.state.langDefault && (
                  <Grid relaxed columns="1">
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          {this.state.form.stepId !== null &&
                          this.state.form.stepId > 0
                            ? 'Connected to Step'
                            : 'Select to Step'}
                        </label>
                        <Dropdown
                          disabled={this.state.isLoading}
                          value={this.state.form.stepId}
                          fluid
                          selection
                          search
                          clearable
                          onSearchChange={(event, { searchQuery }) => {
                            this.loadStepList(searchQuery, 'stepId')
                          }}
                          onChange={(event, { value }) => {
                            this.handleValueChange(value, 'stepId')
                            if (value === '') {
                              this.loadStepList('', 'stepId')
                            }
                          }}
                          options={this.state.stepList}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                )}
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.content = ''
                  state.formLang.answers = []
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ChallengeSave
