import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  TextArea,
  Message
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class SkillSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      group: '',
      category: '',
      title: '',
      description: ''
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.group = this.props.editData.group
          state.form.category = this.props.editData.category
          state.form.title = this.props.editData.title
          state.form.description = this.props.editData.description
          return state
        })
      } else {
        this.setState(function (state) {
          state.form.group = ''
          state.form.category = ''
          state.form.title = ''
          state.form.description = ''
          return state
        })
      }
    }
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        group: this.state.form.group,
        category: this.state.form.category,
        title: this.state.form.title,
        description: this.state.form.description
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/skill/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.title !== '' &&
      this.state.form.group !== '' &&
      this.state.form.category !== ''
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="trophy" />
          {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Skill
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Skill not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Group</label>
                  <Input
                    maxLength="80"
                    disabled={this.state.isLoading}
                    value={this.state.form.group}
                    onChange={(event) => this.handleFormChange(event, 'group')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Category</label>
                  <Input
                    maxLength="80"
                    disabled={this.state.isLoading}
                    value={this.state.form.category}
                    onChange={(event) =>
                      this.handleFormChange(event, 'category')
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid relaxed columns="1">
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="255"
                    disabled={this.state.isLoading}
                    value={this.state.form.title}
                    onChange={(event) => this.handleFormChange(event, 'title')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Description</label>
                  <TextArea
                    disabled={this.state.isLoading}
                    value={this.state.form.description}
                    onChange={(event) =>
                      this.handleFormChange(event, 'description')
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default SkillSave
