import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class WorkspaceSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      externalId: '',
      title: '',
      type: ''
    },
    workspaceTypeList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      var workspaceTypeList = []
      if (this.props.editData.workspaceTypes !== null) {
        for (var i = 0; i < this.props.editData.workspaceTypes.length; i++) {
          var workspaceType = this.props.editData.workspaceTypes[i]
          workspaceTypeList.push({
            key: workspaceType.type,
            value: workspaceType.type,
            text: workspaceType.label
          })
        }
      }
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.externalId = this.props.editData.externalId
          state.form.title = this.props.editData.title
          state.form.type = this.props.editData.type
          state.workspaceTypeList = workspaceTypeList
          return state
        })
      } else {
        this.setState(function (state) {
          state.form.externalId = ''
          state.form.title = ''
          state.form.type = ''
          state.workspaceTypeList = workspaceTypeList
          return state
        })
      }
    }
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        externalId: this.state.form.externalId,
        title: this.state.form.title,
        type: this.state.form.type
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/workspace/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.externalId !== '' &&
      this.state.form.type !== '' &&
      this.state.form.title !== ''
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="cog" />
          {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Environment
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Environment not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Code</label>
                  <Input
                    disabled={this.state.isLoading}
                    icon="key"
                    value={this.state.form.externalId}
                    onChange={(event) => {
                      this.handleFormChange(event, 'externalId')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Type</label>
                  <Dropdown
                    disabled={this.state.isLoading}
                    value={this.state.form.type}
                    fluid
                    selection
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'type')
                    }}
                    options={this.state.workspaceTypeList}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid relaxed columns="1">
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={this.state.isLoading}
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => {
                      this.handleFormChange(event, 'title')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default WorkspaceSave
