import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  TextArea,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class JourneySave extends React.Component {
  state = {
    id: this.props.id,
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      title: '',
      externalId: '',
      description: '',
      onlineHours: 0,
      workHours: 0,
      creditHours: 0,
      estimatedTime: 0,
      imageId: null
    },
    formLang: {
      title: '',
      description: ''
    },
    imageList: [],
    workspaceList: [],
    prerequisiteList: [],
    langList: this.props.langList,
    langDefault: this.props.langDefault,
    lang: this.props.lang
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadImageList = this.loadImageList.bind(this)
    this.receivedImageList = this.receivedImageList.bind(this)
    this.loadPrerequisiteList = this.loadPrerequisiteList.bind(this)
    this.receivedPrerequisiteList = this.receivedPrerequisiteList.bind(this)
    this.loadWorkspaceList = this.loadWorkspaceList.bind(this)
    this.receivedWorkspaceList = this.receivedWorkspaceList.bind(this)
    this.loadLangData = this.loadLangData.bind(this)
    this.receivedLangData = this.receivedLangData.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
  }

  loadWorkspaceList(searchText, fieldName) {
    RequestManager.requestResourceList(
      'workspace',
      searchText,
      this.receivedWorkspaceList
    )
  }

  receivedWorkspaceList(hasError, data) {
    var workspaces = []
    if (!hasError) {
      for (var index in data.workspaces) {
        workspaces.push({
          key: data.workspaces[index].id,
          value: data.workspaces[index].id,
          text:
            data.workspaces[index].externalId +
            ' - ' +
            data.workspaces[index].title
        })
      }
    }
    this.setState(function (state) {
      state.workspaceList = workspaces
      return state
    })
  }

  loadImageList(searchText, fieldName) {
    RequestManager.requestResourceList(
      'image',
      searchText,
      this.receivedImageList
    )
  }

  receivedImageList(hasError, data) {
    var images = []
    if (!hasError) {
      for (var index in data.images) {
        images.push({
          key: data.images[index].id,
          value: data.images[index].id,
          text: data.images[index].externalId + ' - ' + data.images[index].title
        })
      }
    }
    this.setState(function (state) {
      state.imageList = images
      return state
    })
  }

  loadPrerequisiteList(searchText, fieldName) {
    RequestManager.requestResourceList(
      'journey',
      searchText,
      this.receivedPrerequisiteList
    )
  }

  loadLangData(language) {
    RequestManager.requestAPI(
      '/admin/journey/view-lang',
      {
        id: this.state.id,
        language
      },
      this.receivedLangData
    )
  }

  receivedLangData(hasError, data) {
    if (!hasError) {
      this.setState((state) => {
        state.formLang.title = data.titleLang
        state.formLang.description = data.descriptionLang
        return state
      })
    }
  }

  receivedPrerequisiteList(hasError, data) {
    var journeys = []
    if (!hasError) {
      for (var index in data.journeys) {
        journeys.push({
          key: data.journeys[index].id,
          value: data.journeys[index].id,
          text:
            data.journeys[index].externalId + ' - ' + data.journeys[index].title
        })
      }
    }
    this.setState(function (state) {
      state.prerequisiteList = journeys
      return state
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.title = this.props.editData.title
          state.form.externalId = this.props.editData.externalId
          state.form.description = this.props.editData.description
          state.form.imageId = this.props.editData.imageId
          state.form.workspaceId = this.props.editData.workspaceId
          state.form.prerequisiteId = this.props.editData.prerequisiteId
          state.form.onlineHours = this.props.editData.onlineHours
          state.form.workHours = this.props.editData.workHours
          state.form.creditHours = this.props.editData.creditHours
          state.form.estimatedTime = this.props.editData.estimatedTime
          if (this.props.editData.imageId !== null) {
            var images = []
            images.push({
              key: this.props.editData.imageId,
              value: this.props.editData.imageId,
              text:
                this.props.editData.imageData.externalId +
                ' - ' +
                this.props.editData.imageData.title
            })
            state.imageList = images
          }
          if (this.props.editData.workspaceId !== null) {
            var workspaces = []
            workspaces.push({
              key: this.props.editData.workspaceId,
              value: this.props.editData.workspaceId,
              text:
                this.props.editData.workspaceExternalId +
                ' - ' +
                this.props.editData.workspaceTitle
            })
            state.workspaceList = workspaces
          }
          if (this.props.editData.prerequisiteId !== null) {
            var journeys = []
            journeys.push({
              key: this.props.editData.prerequisiteId,
              value: this.props.editData.prerequisiteId,
              text:
                this.props.editData.prerequisiteExternalId +
                ' - ' +
                this.props.editData.prerequisiteTitle
            })
            state.prerequisiteList = journeys
          }
          return state
        })
        if (this.props.editData.imageId === null) {
          this.loadImageList('')
        }
        if (this.props.editData.workspaceId === null) {
          this.loadWorkspaceList('')
        }
        if (this.props.editData.prerequisiteId === null) {
          this.loadPrerequisiteList('')
        }
      } else {
        this.setState(function (state) {
          state.form.title = ''
          state.form.description = ''
          state.form.imageId = null
          state.form.workspaceId = null
          state.form.prerequisiteId = null
          state.form.onlineHours = 0
          state.form.workHours = 0
          state.form.creditHours = 0
          state.form.estimatedTime = 0
          return state
        })
        this.loadImageList('')
        this.loadWorkspaceList('')
        this.loadPrerequisiteList('')
      }
    }
  }

  handleFormChange(event, name, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        title: this.state.form.title,
        externalId: this.state.form.externalId,
        description: this.state.form.description,
        onlineHours: parseFloat(this.state.form.onlineHours),
        workHours: parseFloat(this.state.form.workHours),
        creditHours: parseFloat(this.state.form.creditHours),
        estimatedTime: parseFloat(this.state.form.estimatedTime),
        imageId: parseInt(this.state.form.imageId),
        workspaceId: parseInt(this.state.form.workspaceId),
        prerequisiteId: parseInt(this.state.form.prerequisiteId)
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/journey/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (this.validateFields()) {
      var dataToSend = {
        id: this.state.id,
        language: this.state.lang,
        titleLang: this.state.formLang.title,
        descriptionLang: this.state.formLang.description
      }
      RequestManager.requestAPI(
        '/admin/journey/save-lang',
        dataToSend,
        this.receivedResponse
      ).then(()=> {
        this.setState(state => {
          state.lang = this.state.langDefault
          state.formLang.title = ''
          state.formLang.description = ''
          return state
        })
      })
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.title > ' ' &&
      this.state.form.externalId > ' ' &&
      this.state.form.description > ' ' &&
      this.state.form.onlineHours !== null &&
      this.state.form.onlineHours > 0 &&
      this.state.form.workHours !== null &&
      this.state.form.workHours > 0 &&
      this.state.form.creditHours !== null &&
      this.state.form.creditHours > 0 &&
      this.state.form.estimatedTime !== null &&
      this.state.form.estimatedTime > 0
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'small'
        }
      >
        <Modal.Header>
          <Grid
            relaxed
            columns={this.state.lang !== this.state.langDefault ? 2 : 1}
          >
            <Grid.Column width={9}>
              <Icon name="globe" />{' '}
              {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Journey
            </Grid.Column>
            {this.state.viewMode === 'EDIT' && (
              <Grid.Column textAlign="right" width={7}>
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="world"
                  size="tinny"
                  value={this.state.lang}
                  options={this.state.langList}
                  placeholder="Choose Language"
                  onChange={(event, { value }) => {
                    this.loadLangData(value)
                    this.setState((state) => {
                      state.lang = value
                      return state
                    })
                  }}
                />
              </Grid.Column>
            )}
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Journey not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            {this.state.lang === this.state.langDefault && (
              <Grid relaxed columns="2">
                <Grid.Column>
                  <Form.Field required>
                    <label>Code</label>
                    <Input
                      maxLength="80"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.form.externalId}
                      onChange={(event) => {
                        this.handleFormChange(event, 'externalId')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Project environment</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.workspaceId}
                      fluid
                      selection
                      search
                      clearable
                      onSearchChange={(event, { searchQuery }) => {
                        this.loadWorkspaceList(searchQuery, 'workspaceId')
                      }}
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'workspaceId')
                      }}
                      options={this.state.workspaceList}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => {
                      this.handleFormChange(event, 'title')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault ? (
                <Grid.Column>
                  <Form.Field required>
                    <label>Title</label>
                    <Input
                      maxLength="80"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.title}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'title')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              ) : (
                <Grid.Column>
                  <Form.Field>
                    <label>Prerequisite</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.prerequisiteId}
                      fluid
                      selection
                      search
                      clearable
                      onSearchChange={(event, { searchQuery }) => {
                        this.loadPrerequisiteList(searchQuery, 'prerequisiteId')
                      }}
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'prerequisiteId')
                      }}
                      options={this.state.prerequisiteList}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field required>
                  <label>Description</label>
                  <TextArea
                    maxLength="255"
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    value={this.state.form.description}
                    onChange={(event) => {
                      this.handleFormChange(event, 'description')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Description</label>
                    <TextArea
                      maxLength="255"
                      disabled={this.state.isLoading}
                      value={this.state.formLang.description}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'description')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            {this.state.lang === this.state.langDefault && (
              <>
                <Grid relaxed columns="3">
                  <Grid.Column>
                    <Form.Field required>
                      <label>Online Time</label>
                      <Input
                        disabled={this.state.isLoading}
                        icon="clock"
                        type="number"
                        value={this.state.form.onlineHours}
                        onChange={(event) =>
                          this.handleFormChange(event, 'onlineHours', 8)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field required>
                      <label>Working Time</label>
                      <Input
                        disabled={this.state.isLoading}
                        icon="clock"
                        type="number"
                        value={this.state.form.workHours}
                        onChange={(event) => {
                          this.handleFormChange(event, 'workHours', 8)
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field required>
                      <label>Estimated Time</label>
                      <Input
                        disabled={this.state.isLoading}
                        icon="clock"
                        type="number"
                        value={this.state.form.estimatedTime}
                        onChange={(event) => {
                          this.handleFormChange(event, 'estimatedTime', 8)
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field required>
                      <label>Credits (Hours)</label>
                      <Input
                        disabled={this.state.isLoading}
                        icon="clock"
                        type="number"
                        value={this.state.form.creditHours}
                        onChange={(event) => {
                          this.handleFormChange(event, 'creditHours', 8)
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Background Image</label>
                      <Dropdown
                        disabled={this.state.isLoading}
                        value={this.state.form.imageId}
                        fluid
                        selection
                        search
                        clearable
                        onSearchChange={(event, { searchQuery }) => {
                          this.loadImageList(searchQuery, 'imageId')
                        }}
                        onChange={(event, { value }) => {
                          this.handleValueChange(value, 'imageId')
                        }}
                        options={this.state.imageList}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.title = ''
                  state.formLang.description = ''
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default JourneySave
