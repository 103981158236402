import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Pagination,
  Segment,
  Table,
  Grid,
  Loader,
  Dimmer,
  Input,
  Dropdown
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './StudentList.css'

import RequestManager from '../RequestManager'

class StudentList extends React.Component {
  state = {
    isLoading: false,
    users: [],
    limit: 30,
    offset: 0,
    total: 0,
    totalPages: 0,
    activePage: 1,
    isListLoaded: false,
    search: '',
    filterVentureStage: '',
    filterStatus: ''
  }

  constructor() {
    super()
    this.paginationClickHandler = this.paginationClickHandler.bind(this)
    this.refreshActivePage = this.refreshActivePage.bind(this)
    this.receivedUserList = this.receivedUserList.bind(this)

    this.loadSearch = this.loadSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    this.loadUserList()
  }

  loadUserList() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'STDNT',
        offset: (this.state.activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedUserList
    )
  }

  receivedUserList(hasError, data) {
    this.updateStateUsers(data)
  }

  updateStateUsers(data) {
    this.setState(function (state) {
      state.isLoading = false
      state.isListLoaded = true
      state.users = data.users
      state.total = data.total
      state.totalPages = Math.ceil(data.count / this.state.limit)
      return state
    })
  }

  paginationClickHandler(e, { activePage }) {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'STDNT',
        ventureStage: this.state.filterVentureStage,
        status: this.state.filterStatus,
        search: this.state.search,
        offset: (activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedUserList
    )
    this.refreshActivePage(activePage)
  }

  loadSearch() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'STDNT',
        ventureStage: this.state.filterVentureStage,
        status: this.state.filterStatus,
        search: this.state.search,
        offset: 0,
        limit: this.state.limit
      },
      this.receivedUserList
    )
    this.refreshActivePage(1)
  }

  clearSearch() {
    RequestManager.requestAPI(
      '/admin/user/list',
      {
        profile: 'STDNT',
        offset: 0,
        limit: this.state.limit,
        search: ''
      },
      this.receivedUserList
    )
    this.setState(function (state) {
      state.search = ''
      return state
    })
    this.refreshActivePage(1)
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state[name] = data
      } else {
        state[name] = null
      }
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state[name] = data
      return state
    })
  }

  refreshActivePage(activePage) {
    this.setState(function (state) {
      state.activePage = activePage
      return state
    })
  }

  getVentureStageName(stage) {
    switch (stage) {
      case 'D':
        return 'Discovery'
      case 'I':
        return 'Ignite'
      case 'A':
        return 'Accelerate'
      case 'L':
        return 'Launch'
      default:
        return ''
    }
  }

  getStatusName(status) {
    switch (status) {
      case 'A':
        return 'Active'
      case 'B':
        return 'Blocked'
      case 'C':
        return 'Canceled'
      case 'E':
        return 'Expired'
      default:
        return ''
    }
  }

  getLastSignonInDays(lastSignonInDays) {
    if (lastSignonInDays === null) {
      return '-'
    }
    if (lastSignonInDays === 0) {
      return 'today'
    }
    if (lastSignonInDays === 1) {
      return 'yesterday'
    }
    if (lastSignonInDays < 21) {
      return lastSignonInDays + ' days'
    }
    return Math.floor(lastSignonInDays / 7) + ' weeks'
  }

  render() {
    document.title = 'Students :: Pragma School'
    if (this.state.isLoading)
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )

    return (
      <>
        <div className="PAGE_CONTAINER animated fadeIn">
          <Segment color="black">
            <Grid relaxed columns="equal" className="PAGE_HEADER">
              <Grid.Column floated="left">
                <Header as="h3">
                  <Icon name="graduation cap" />
                  <Header.Content>Students</Header.Content>
                </Header>
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment attached="top" color="black">
            <Grid relaxed>
              <Grid.Column width={3}>
                <Dropdown
                  value={this.state.filterVentureStage}
                  fluid
                  selection
                  placeholder="Step..."
                  onChange={(event, { value }) => {
                    this.handleValueChange(value, 'filterVentureStage')
                  }}
                  options={[
                    { key: 'D', value: 'D', text: 'Discovery' },
                    { key: 'I', value: 'I', text: 'Ignite' },
                    { key: 'A', value: 'A', text: 'Accelerate' },
                    { key: 'L', value: 'L', text: 'Launch' }
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Dropdown
                  value={this.state.filterStatus}
                  fluid
                  selection
                  placeholder="Status..."
                  onChange={(event, { value }) => {
                    this.handleValueChange(value, 'filterStatus')
                  }}
                  options={[
                    { key: 'A', value: 'A', text: 'Active' },
                    { key: 'B', value: 'B', text: 'Blocked' },
                    { key: 'C', value: 'C', text: 'Canceled' },
                    { key: 'E', value: 'E', text: 'Expired' }
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <form
                  onSubmit={(event) => {
                    event.preventDefault()
                  }}
                >
                  <Input size="mini" placeholder="Search" fluid action>
                    <input
                      value={this.state.search}
                      onChange={(event) => {
                        this.handleFormChange(event, 'search')
                      }}
                    />
                    <Button icon="search" onClick={this.loadSearch} />
                    <Button basic icon="eraser" onClick={this.clearSearch} />
                  </Input>
                </form>
              </Grid.Column>
              <Grid.Column floated="right">
                <Pagination
                  disabled={this.state.totalPages <= 0}
                  floated="right"
                  size="mini"
                  boundaryRange={0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={2}
                  totalPages={this.state.totalPages}
                  activePage={this.state.activePage}
                  onPageChange={this.paginationClickHandler}
                />
              </Grid.Column>
            </Grid>
          </Segment>
          {this.state.users &&
            <Table
              attached="bottom"
              compact
              singleLine
              color="black"
              size="small"
              className="LINK_UNDERLINE"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Email</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Carrier</Table.HeaderCell>
                  <Table.HeaderCell>Step</Table.HeaderCell>
                  <Table.HeaderCell>Mentor</Table.HeaderCell>
                  <Table.HeaderCell>Last access</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.users.length === 0 && this.state.isListLoaded && (
                  <Table.Row>
                    <Table.Cell colSpan="4" singleLine>
                      <Header
                        size="medium"
                        style={{ textAlign: 'center', padding: 10 }}
                      >
                        No records to be displayed.
                        <Header.Subheader>
                          Clear your search or enter a record.
                        </Header.Subheader>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                )}
                {this.state.users.map(function (user, index) {
                  return (
                    <Table.Row key={user.id}>
                      <Table.Cell>
                        <Link to={'/student/view/' + user.id}>{user.email}</Link>
                      </Table.Cell>
                      <Table.Cell>
                        {user.firstName + ' ' + user.lastName}
                      </Table.Cell>
                      <Table.Cell>
                        {(user.ventureTitle ? user.ventureTitle : '') +
                          (user.programTitle > ' '
                            ? ' / ' + user.programTitle
                            : '')}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getVentureStageName(user.ventureStage)}
                      </Table.Cell>
                      <Table.Cell>{user.mentorName}</Table.Cell>
                      <Table.Cell>
                        {this.getLastSignonInDays(user.lastSignonInDays)}
                      </Table.Cell>
                      <Table.Cell>{this.getStatusName(user.status)}</Table.Cell>
                    </Table.Row>
                  )
                }, this)}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    <Pagination
                      disabled={this.state.totalPages <= 0}
                      floated="right"
                      size="mini"
                      boundaryRange={0}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={2}
                      totalPages={this.state.totalPages}
                      activePage={this.state.activePage}
                      onPageChange={this.paginationClickHandler}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          }
        </div>
      </>
    )
  }
}

export default StudentList
