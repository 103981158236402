import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  TextArea,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class ProjectSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      externalId: '',
      title: '',
      description: ''
    },

    langList: this.props.langList,
    langDefault: this.props.langDefault,
    lang: this.props.lang,
    formLang: {
      title: '',
      description: ''
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
    this.receivedResponseLang = this.receivedResponseLang.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.externalId = this.props.editData.externalId
          state.form.title = this.props.editData.title
          state.form.description = this.props.editData.description
          return state
        })
      } else {
        this.setState(function (state) {
          state.form.externalId = ''
          state.form.title = ''
          state.form.description = ''
          return state
        })
      }
    }
  }

  handleFormChange(event, name, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        externalId: this.state.form.externalId,
        title: this.state.form.title,
        description: this.state.form.description
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/project/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  loadProjectLang(language) {
    RequestManager.requestAPI(
      '/admin/project/view-lang',
      {
        id: this.props.id,
        language
      },
      this.receivedResponseLang
    )
  }

  receivedResponseLang(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.formLang.title = data.titleLang
        state.formLang.description = data.descriptionLang
        return state
      })
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (this.validateFields()) {
      RequestManager.requestAPI(
        '/admin/project/save-lang',
        {
          id: this.props.id,
          language: this.state.lang,
          titleLang: this.state.formLang.title,
          descriptionLang: this.state.formLang.description
        },
        this.receivedResponse
      ).then(()=> {
        this.setState(state => {
          state.lang = this.state.langDefault
          state.formLang.title = ''
          state.formLang.description = ''
          return state
        })
      })
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.title !== '' &&
      this.state.form.externalId !== '' &&
      this.state.form.description !== ''
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'small'
        }
      >
        <Modal.Header>
          <Grid
            relaxed
            columns={this.state.lang !== this.state.langDefault ? 2 : 1}
          >
            <Grid.Column width={9}>
              <Icon name="file" />{' '}
              {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Project
            </Grid.Column>
            {this.state.viewMode === 'EDIT' && (
              <Grid.Column textAlign="right" width={7}>
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="world"
                  size="tinny"
                  value={this.state.lang}
                  options={this.state.langList}
                  placeholder="Choose Language"
                  onChange={(event, { value }) => {
                    this.loadProjectLang(value)
                    this.setState((state) => {
                      state.lang = value
                      return state
                    })
                  }}
                />
              </Grid.Column>
            )}
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Project not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            {this.state.lang === this.state.langDefault && (
              <Grid columns="1">
                <Grid.Column>
                  <Form.Field required>
                    <label>Code</label>
                    <Input
                      maxLength="100"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.form.externalId}
                      onChange={(event) =>
                        this.handleFormChange(event, 'externalId')
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}
            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => this.handleFormChange(event, 'title')}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Title</label>
                    <Input
                      maxLength="80"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.title}
                      onChange={(event) =>
                        this.handleFormLangChange(event, 'title')
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field required>
                  <label>Description</label>
                  <TextArea
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    icon="tag"
                    value={this.state.form.description}
                    onChange={(event) =>
                      this.handleFormChange(event, 'description')
                    }
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Description</label>
                    <TextArea
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.description}
                      onChange={(event) =>
                        this.handleFormLangChange(event, 'description')
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.title = ''
                  state.formLang.description = ''
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ProjectSave
