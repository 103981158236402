import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Button,
  Menu,
  Image,
  Dimmer,
  Loader,
  Dropdown
} from 'semantic-ui-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom'

import eIcon from './media/eLogo.png'
import profileIcon from './media/profile.png'
import './Dashboard.css'

import Tapume from './Tapume'
import NotFound from './NotFound'

import UserList from './user/UserList'
import UserView from './user/UserView'
import StudentList from './student/StudentList'
import StudentView from './student/StudentView'

import SendEmail from './email/SendEmail'
import SkillGenerator from './skill/SkillGenerator'

import VideoList from './video/VideoList'
import VideoView from './video/VideoView'
import ImageList from './image/ImageList'
import ImageView from './image/ImageView'
import ImportExport from './imp-exp/index'
import FileList from './file/FileList'
import FileView from './file/FileView'

import TrailList from './trail/TrailList'
import TrailView from './trail/TrailView'
import ProjectList from './project/ProjectList'
import ProjectView from './project/ProjectView'
import SkillList from './skill/SkillList'
import SkillView from './skill/SkillView'
import WorkspaceList from './workspace/WorkspaceList'
import WorkspaceView from './workspace/WorkspaceView'
import QuizQuestionList from './quiz/QuizQuestionList'
import QuizQuestionView from './quiz/QuizQuestionView'

import VentureList from './venture/VentureList'
import VentureView from './venture/VentureView'
import JourneyList from './journey/JourneyList'
import JourneyView from './journey/JourneyView'

import ChangePassword from './ChangePassword'
import RequestManager from './RequestManager'

class Dashboard extends React.Component {
  state = {
    userChecked: false,
    userData: {},
    languages: [],
    isChangePasswordModalOpen: false
  }

  constructor() {
    super()
    this.receivedLoadBaseData = this.receivedLoadBaseData.bind(this)
    this.mentorButtonClickHandler = this.mentorButtonClickHandler.bind(this)
    this.recruiterButtonClickHandler =
      this.recruiterButtonClickHandler.bind(this)
  }

  componentDidMount() {
    RequestManager.requestAPI(
      '/admin/loadbasedata',
      {},
      this.receivedLoadBaseData
    )
  }

  receivedLoadBaseData(hasError, data) {
    if (!hasError && data.userData.profile === 'ADMIN') {
      this.setState(function (state) {
        state.userChecked = true
        state.userData = data.userData
        state.languages = data.languages
        return state
      })
    } else {
      RequestManager.goBackToLogin()
    }
  }

  setModalChangePasswordVisible(isVisible) {
    this.setState(function (state) {
      state.isChangePasswordModalOpen = isVisible
      return state
    })
  }

  logout() {
    RequestManager.logout()
  }

  mentorButtonClickHandler() {
    window.open(process.env.REACT_APP_MENTOR_DOMAIN, '_blank')
  }

  recruiterButtonClickHandler() {
    window.open(process.env.REACT_APP_TALENT_DOMAIN, '_blank')
  }

  render() {
    if (!this.state.userChecked) {
      return (
        <Dimmer active className="animated fadeIn">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <Router>
            <Menu
              fixed="top"
              inverted
              borderless
              size="large"
              className="MENU_TOP"
            >
              <Menu.Item className="MENU_BRAND">
                <Link to="/">
                  <Image src={eIcon} />
                </Link>
              </Menu.Item>
              <Menu.Menu position="right">
                {this.state.userData.recruiterSupport && (
                  <Menu.Item fitted style={{ marginRight: '20px' }}>
                    <Button.Group
                      size="mini"
                      inverted
                      className="DD_MENU_CONTAINER_BUTTONS"
                    >
                      <Button
                        content="Recruiter"
                        icon="external alternate"
                        color="black"
                        onClick={this.recruiterButtonClickHandler}
                      />
                    </Button.Group>
                  </Menu.Item>
                )}

                {this.state.userData.journeyMentorship && (
                  <Menu.Item fitted style={{ marginRight: '20px' }}>
                    <Button.Group
                      size="mini"
                      inverted
                      className="DD_MENU_CONTAINER_BUTTONS"
                    >
                      <Button
                        content="Mentor"
                        icon="external alternate"
                        color="black"
                        onClick={this.mentorButtonClickHandler}
                      />
                    </Button.Group>
                  </Menu.Item>
                )}

                <Dropdown
                  item
                  icon={null}
                  trigger={
                    <>
                      <span className="HIDE_ON_MOBILE">
                        {this.state.userData.firstName +
                          ' ' +
                          this.state.userData.lastName}
                      </span>
                      <Image avatar src={profileIcon} className="DD_AVATAR" />
                    </>
                  }
                  className="DD_MENU_BUTTON"
                >
                  <Dropdown.Menu className="DD_MENU">
                    <Dropdown.Header
                      icon="user"
                      content={
                        this.state.userData.firstName +
                        ' ' +
                        this.state.userData.lastName
                      }
                      className="DD_MENU_LABEL"
                    />
                    <Dropdown.Header
                      icon="mail"
                      content={this.state.userData.email}
                      className="DD_MENU_LABEL"
                    />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      icon="lock"
                      text="Change Password"
                      className="DD_MENU_ITEM"
                      onClick={() => {
                        this.setModalChangePasswordVisible(true)
                      }}
                    />
                    <Dropdown.Item
                      icon="power off"
                      text="Log Out"
                      className="DD_MENU_ITEM"
                      onClick={this.logout}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
            <Menu
              size="large"
              fixed="left"
              vertical
              borderless
              inverted
              className="MENU_LEFT"
            >
              {(this.state.userData.securityManagement ||
                this.state.userData.studentAdministration) && (
                <Menu.Item>
                  <Menu.Header>
                    <Icon name="wrench" /> Management
                  </Menu.Header>
                  <Menu.Menu>
                    {this.state.userData.securityManagement && (
                      <Menu.Item
                        as={Link}
                        to="/user/list"
                        icon="users"
                        content="Users"
                      ></Menu.Item>
                    )}
                    {this.state.userData.studentAdministration && (
                      <Menu.Item
                        as={Link}
                        to="/student/list"
                        icon="graduation cap"
                        content="Students"
                      ></Menu.Item>
                    )}
                    {this.state.userData.studentAdministration && (
                      <Menu.Item
                        as={Link}
                        to="/email/send"
                        icon="mail"
                        content="Send email"
                      ></Menu.Item>
                    )}
                    {this.state.userData.studentAdministration && (
                      <Menu.Item
                        as={Link}
                        to="/skill/generator"
                        icon="crosshairs"
                        content="Generate skills"
                      ></Menu.Item>
                    )}
                    {this.state.userData.studentAdministration && (
                      <Menu.Item
                        as={Link}
                        to="/export-import"
                        icon="download"
                        content="Export/Import"
                      ></Menu.Item>
                    )}
                  </Menu.Menu>
                </Menu.Item>
              )}
              {this.state.userData.contentManagement && (
                <>
                  <Menu.Item>
                    <Menu.Header>
                      <Icon name="university" /> Content
                    </Menu.Header>
                    <Menu.Menu>
                      <Menu.Item
                        as={Link}
                        to="/venture/list"
                        icon="trophy"
                        content="Ventures"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/journey/list"
                        icon="globe"
                        content="Journeys"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/trail/list"
                        icon="road"
                        content="Trails"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/project/list"
                        icon="file"
                        content="Projects"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/workspace/list"
                        icon="cog"
                        content="Environments"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/skill/list"
                        icon="bolt"
                        content="Skills"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/quiz/question/list"
                        icon="question"
                        content="Questions"
                      ></Menu.Item>
                    </Menu.Menu>
                  </Menu.Item>
                  <Menu.Item>
                    <Menu.Header>
                      <Icon name="folder" /> Resources
                    </Menu.Header>
                    <Menu.Menu>
                      <Menu.Item
                        as={Link}
                        to="/video/list"
                        icon="film"
                        content="Videos"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/image/list"
                        icon="file image"
                        content="Images"
                      ></Menu.Item>
                      <Menu.Item
                        as={Link}
                        to="/file/list"
                        icon="file"
                        content="Documents"
                      ></Menu.Item>
                    </Menu.Menu>
                  </Menu.Item>
                </>
              )}
            </Menu>
            <div className="CONTENT_CONTAINER">
              <div className="SCROLL_Y_AUTO">
                <Switch>
                  {this.state.userData.securityManagement && (
                    <Route path="/user/list" component={UserList} />
                  )}
                  {this.state.userData.securityManagement && (
                    <Route path="/user/view/:id" component={UserView} />
                  )}
                  {this.state.userData.securityManagement && (
                    <Route path="/user">
                      <Redirect to="/user/list" />
                    </Route>
                  )}

                  {this.state.userData.studentAdministration && (
                    <Route path="/email/send" component={SendEmail} />
                  )}

                  {this.state.userData.studentAdministration && (
                    <Route path="/skill/generator" component={SkillGenerator} />
                  )}

                  {this.state.userData.studentAdministration && (
                    <Route path="/export-import" component={ImportExport} />
                  )}

                  {this.state.userData.studentAdministration && (
                    <Route path="/student/list" component={StudentList} />
                  )}
                  {this.state.userData.studentAdministration && (
                    <Route path="/student/view/:id" component={StudentView} />
                  )}
                  {this.state.userData.studentAdministration && (
                    <Route path="/student">
                      <Redirect to="/student/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/video/list" component={VideoList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/video/view/:id" component={VideoView} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/video">
                      <Redirect to="/video/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/image/list" component={ImageList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/image/view/:id" component={ImageView} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/image">
                      <Redirect to="/image/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/file/list" component={FileList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/file/view/:id" component={FileView} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/file">
                      <Redirect to="/file/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/trail/list" component={TrailList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route
                      path="/trail/view/:id"
                      render={(props) => (
                        <TrailView
                          languages={this.state.languages}
                          {...props}
                        />
                      )}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/trail">
                      <Redirect to="/trail/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/project/list" component={ProjectList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route
                      path="/project/view/:id"
                      render={(props) => (
                        <ProjectView
                          languages={this.state.languages}
                          {...props}
                        />
                      )}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/project">
                      <Redirect to="/project/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/skill/list" component={SkillList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/skill/view/:id" component={SkillView} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/skill">
                      <Redirect to="/skill/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/workspace/list" component={WorkspaceList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route
                      path="/workspace/view/:id"
                      component={WorkspaceView}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/workspace">
                      <Redirect to="/workspace/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route
                      path="/quiz/question/list"
                      component={QuizQuestionList}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route
                      path="/quiz/question/view/:id"
                      render={(props) => (
                        <QuizQuestionView
                          languages={this.state.languages}
                          {...props}
                        />
                      )}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/quiz/question">
                      <Redirect to="/quiz/question/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/venture/list" component={VentureList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/venture/view/:id" component={VentureView} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/venture">
                      <Redirect to="/venture/list" />
                    </Route>
                  )}

                  {this.state.userData.contentManagement && (
                    <Route path="/journey/list" component={JourneyList} />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route
                      path="/journey/view/:id"
                      render={(props) => (
                        <JourneyView
                          languages={this.state.languages}
                          {...props}
                        />
                      )}
                    />
                  )}
                  {this.state.userData.contentManagement && (
                    <Route path="/journey">
                      <Redirect to="/journey/list" />
                    </Route>
                  )}

                  {this.state.userData.studentAdministration && (
                    <Route path="/subscriber">
                      <Redirect to="/subscriber/list" />
                    </Route>
                  )}

                  <Route exact path="/" component={Tapume} />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </Router>

          <ChangePassword
            open={this.state.isChangePasswordModalOpen}
            onDone={this.onDoneChangePasswordModal}
            onCancelClick={() => {
              this.setModalChangePasswordVisible(false)
            }}
          />
        </>
      )
    }
  }
}

export default Dashboard
