import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Button,
  Form,
  Grid,
  Segment,
  TextArea,
  Label,
  Select,
  Message,
  Checkbox
} from 'semantic-ui-react'
import RequestManager from '../RequestManager'

class SendEmail extends React.Component {
  state = {
    isLoading: false,
    hasError: false,
    hasMore: false,
    allowSend: false,
    isSubscriberList: false,
    templates: [],
    emails: [],
    template: '',
    count: 0
  }

  constructor(state) {
    super(state)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.receivedResponseUsers = this.receivedResponseUsers.bind(this)
    this.receivedTemplateList = this.receivedTemplateList.bind(this)
  }

  componentDidMount() {
    this.loadTemplatesList()
  }

  loadTemplatesList() {
    RequestManager.requestAPI(
      '/admin/email/list',
      {},
      this.receivedTemplateList
    )
  }

  saveUsers() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/email/sendupload',
      {
        emails: this.state.emails,
        template: this.state.template,
        isSubscriberList: this.state.isSubscriberList
      },
      this.receivedResponseUsers
    )
  }

  sendEmail() {
    this.setLoadingState(true)
    const listSendEmail = setInterval(() => {
      if (this.state.hasMore) {
        RequestManager.requestAPI(
          '/admin/send-user-email',
          {},
          this.receivedResponse
        )
      } else {
        clearInterval(listSendEmail)
      }
    }, 2000)
  }

  receivedResponseUsers(hasError, data) {
    if (data.error === 0) {
      this.setState(function (state) {
        state.allowSend = true
        state.isLoading = false
        return state
      })
    }
  }

  receivedResponse(hasError, data) {
    const pre = document.getElementById('list')
    const div = document.createElement('div')

    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
    }

    this.setMoreState(data.hasMore)

    if (data.emailAddress) {
      div.appendChild(
        document.createTextNode(
          `Sent to: ${data.emailAddress} with the template: ${data.templateName}`
        )
      )
      pre.appendChild(div)
      this.setCountState(this.state.count + 1)
    }
  }

  receivedTemplateList(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.templates = data.templates
        return state
      })
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  setCountState(count) {
    this.setState(function (state) {
      state.count = count
      return state
    })
  }

  setMoreState(hasMore) {
    this.setState(function (state) {
      state.hasMore = hasMore
      return state
    })
  }

  setTemplate(template) {
    this.setState(function (state) {
      state.template = template
      return state
    })
  }

  setEmails(emails) {
    this.setState(function (state) {
      state.emails = emails.replace(/ /g, '').split(',')
      return state
    })
  }

  clearEmails() {
    const pre = document.getElementById('list')
    const emails = document.getElementById('emails')
    pre.innerText = ''
    emails.value = ''

    this.setCountState(0)
  }

  render() {
    const templateOptions =
      this.state.templates.length > 0
        ? this.state.templates.map((template, index) => {
            return { key: index, value: template, text: template }
          })
        : []

    return (
      <Segment placeholder>
        <Message>
          <Message.Header>To send emails you must!</Message.Header>
          <Message.List>
            <Message.Item>Insert emails separated by "," (comma)</Message.Item>
            <Message.Item>Choose an email template</Message.Item>
            <Message.Item>Click send</Message.Item>
          </Message.List>
        </Message>
        <Grid columns={3}>
          <Grid.Column width={9}>
            <Form>
              <TextArea
                placeholder="Example: hector@gmail.com, sophia@gmail.com..."
                rows={3}
                id="emails"
                style={{ display: 'flex', maxWidth: '100%' }}
                onBlur={(event) => this.setEmails(event.target.value)}
              />
            </Form>
          </Grid.Column>
          <Grid.Column width={5}>
            <Select
              placeholder="Select the template"
              id="template"
              options={templateOptions}
              style={{ display: 'flex', maxWidth: '100%' }}
              onChange={(event) => {
                this.setTemplate(event.currentTarget.innerText)
              }}
            />

            <Checkbox
              label={'Is subscriber'}
              style={{ marginTop: '1rem' }}
              onChange={(_, { checked }) => {
                this.setState(function (state) {
                  state.isSubscriberList = checked
                  return state
                })
              }}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            {!this.state.allowSend && (
              <Button
                loading={this.state.isLoading}
                onClick={() => {
                  this.saveUsers()
                }}
              >
                Save
              </Button>
            )}

            {this.state.allowSend && (
              <Button
                loading={this.state.isLoading}
                onClick={() => {
                  this.setMoreState(true)
                  this.sendEmail()
                }}
              >
                Send
              </Button>
            )}
          </Grid.Column>
        </Grid>

        <Segment.Group>
          <Segment>
            <Button
              compact
              size="small"
              floated="right"
              onClick={() => {
                this.clearEmails()

                this.setState(function (state) {
                  state.allowSend = false
                  return state
                })
              }}
            >
              Clear
            </Button>
            Sent emails <Label circular>{this.state.count}</Label>
          </Segment>
          <Segment secondary>
            <pre id="list"></pre>
          </Segment>
        </Segment.Group>
      </Segment>
    )
  }
}

export default SendEmail
