import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Form,
  Segment,
  Table,
  Grid,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import './StudentView.css'

import moment from 'moment'
import 'moment/locale/pt-br'

import RequestManager from '../RequestManager'

class StudentView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    userData: {
      firstName: '',
      lastName: ''
    },
    isModalOpen: false
  }

  constructor(props) {
    super(props)
    this.receivedUserData = this.receivedUserData.bind(this)
    moment.locale('pt-BR')
  }

  componentDidMount() {
    this.loadUserData()
  }

  loadUserData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/user/view',
      {
        id: this.state.id
      },
      this.receivedUserData
    )
  }

  receivedUserData(hasError, receivedData) {
    if (hasError) {
      this.goBackToUserList()
    } else {
      this.setState(function (state) {
        state.userData = receivedData
        state.isLoading = false
        return state
      })
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  goBackToUserList() {
    window.location.href = '/student/list'
  }

  getProfileName() {
    switch (this.state.userData.profile) {
      case 'ADMIN':
        return 'Admin'
      case 'STDNT':
        return 'Student'
      default:
        return ''
    }
  }

  getGenderName() {
    switch (this.state.userData.gender) {
      case 'F':
        return 'Female'
      case 'M':
        return 'Male'
      default:
        return 'Other'
    }
  }

  getStatusName() {
    switch (this.state.userData.status) {
      case 'A':
        return 'Active'
      case 'B':
        return 'Blocked'
      case 'C':
        return 'Canceled'
      case 'E':
        return 'Expired'
      default:
        return ''
    }
  }

  getVentureStageName() {
    switch (this.state.userData.ventureStage) {
      case 'D':
        return 'Discovery'
      case 'I':
        return 'Ignite'
      case 'A':
        return 'Accelerate'
      case 'L':
        return 'Launch'
      default:
        return '-'
    }
  }

  displayDatetime(fieldTimestamp) {
    return this.state.userData[fieldTimestamp] != null
      ? moment(this.state.userData[fieldTimestamp]).format('lll')
      : '-'
  }

  displayDuration(minutes) {
    if (minutes != null && minutes > 0) {
      var hours = 0
      if (minutes >= 60) {
        hours = parseInt(minutes / 60)
        minutes = minutes % 60
      }
      return (
        (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '') +
        (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's' : '') : '')
      )
    }
    return '-'
  }

  getLastUpdateInDays(lastUpdateInDays) {
    if (lastUpdateInDays === null) {
      return '-'
    }
    if (lastUpdateInDays === 0) {
      return 'today'
    }
    if (lastUpdateInDays === 1) {
      return 'yesterday'
    }
    if (lastUpdateInDays < 21) {
      return lastUpdateInDays + ' days'
    }
    return Math.floor(lastUpdateInDays / 7) + ' weeks'
  }

  getJourneyPercentCompletion(journeyData) {
    if (journeyData.isComplete === true) {
      return 'Yes'
    }
    if (
      journeyData.estimatedTime !== null &&
      journeyData.estimatedTime > 0 &&
      journeyData.executedTime !== null
    ) {
      var percentCompleted = Math.ceil(
        (journeyData.executedTime / journeyData.estimatedTime) * 100
      )
      return percentCompleted + ' %'
    }
    return '-'
  }

  renderStudentProfile() {
    if (this.state.userData.studentData === null) return <></>
    var data = this.state.userData.studentData
    return (
      <Segment attached>
        <Form>
          <Grid relaxed columns="3">
            <Grid.Column>
              <Form.Field>
                <label>Schooling</label>
                <div className="FORM_CONTENT">{data.educationalLevel}</div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>English Level</label>
                <div className="FORM_CONTENT">{data.englishLevel}</div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>LinkedIn</label>
                <div className="FORM_CONTENT">{data.linkedinURL}</div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Is working?</label>
                <div className="FORM_CONTENT">{data.currentEmployment}</div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Development Experience</label>
                <div className="FORM_CONTENT">{data.developmentExperience}</div>
              </Form.Field>
            </Grid.Column>
          </Grid>
          {data.professionalExperience !== null &&
            data.professionalExperience.length > 10 && (
              <Grid relaxed columns="1">
                <Grid.Column>
                  <Form.Field>
                    <label>Professional Experience</label>
                    <div>{data.professionalExperience}</div>
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}
        </Form>
      </Segment>
    )
  }

  render() {
    document.title =
      this.state.userData.firstName +
      ' ' +
      this.state.userData.lastName +
      ' :: Pragma School'
    if (this.state.isLoading)
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )

    return (
      <>
        <div className="PAGE_CONTAINER animated fadeIn">
          <Segment attached="top" color="black">
            <Grid relaxed columns="equal">
              <Grid.Column floated="left">
                <Header as="h3">
                  <Icon name="graduation cap" />
                  <Header.Content>
                    {this.state.userData.firstName}{' '}
                    {this.state.userData.lastName}
                    <Header.Subheader>Student</Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment attached>
            <Form>
              <Grid relaxed columns="3">
                <Grid.Column>
                  <Form.Field>
                    <label>Email</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.email}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Phone</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.studentData === null
                        ? '-'
                        : this.state.userData.studentData.phoneNumber}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Gender</label>
                    <div className="FORM_CONTENT">{this.getGenderName()}</div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Region / City</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.studentData === null
                        ? '-'
                        : this.state.userData.studentData.region +
                          ' / ' +
                          this.state.userData.studentData.city}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Program</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.programId === null
                        ? '-'
                        : this.state.userData.programTitle}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Status</label>
                    <div className="FORM_CONTENT">{this.getStatusName()}</div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Venture</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.ventureId === null
                        ? '-'
                        : this.state.userData.venture.title}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Step</label>
                    <div className="FORM_CONTENT">
                      {this.getVentureStageName()}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Mentor</label>
                    <div className="FORM_CONTENT">
                      {this.state.userData.mentorId === null
                        ? '-'
                        : this.state.userData.mentorName}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Last Login</label>
                    <div className="FORM_CONTENT">
                      {this.displayDatetime('lastSignon')}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Creation Date</label>
                    <div className="FORM_CONTENT">
                      {this.displayDatetime('creationDate')}
                    </div>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Last Update</label>
                    <div className="FORM_CONTENT">
                      {this.displayDatetime('lastUpdate')}
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>

          {this.renderStudentProfile()}

          {this.state.userData.journeys != null &&
            this.state.userData.journeys.length > 0 && (
              <Table
                compact
                singleLine
                color="black"
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Journey</Table.HeaderCell>
                    <Table.HeaderCell>Estimated Time</Table.HeaderCell>
                    <Table.HeaderCell>Time Executed</Table.HeaderCell>
                    <Table.HeaderCell>Points available</Table.HeaderCell>
                    <Table.HeaderCell>Points</Table.HeaderCell>
                    <Table.HeaderCell>Finished</Table.HeaderCell>
                    <Table.HeaderCell>Last access</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.userData.journeys.map(function (journey) {
                    return (
                      <Table.Row key={journey.id}>
                        <Table.Cell>{journey.title}</Table.Cell>
                        <Table.Cell>
                          {this.displayDuration(journey.estimatedTime)}
                        </Table.Cell>
                        <Table.Cell>
                          {this.displayDuration(journey.executedTime)}
                        </Table.Cell>
                        <Table.Cell>{journey.points}</Table.Cell>
                        <Table.Cell>{journey.score}</Table.Cell>
                        <Table.Cell>
                          {this.getJourneyPercentCompletion(journey)}
                        </Table.Cell>
                        <Table.Cell>
                          {this.getLastUpdateInDays(journey.lastUpdateInDays)}
                        </Table.Cell>
                      </Table.Row>
                    )
                  }, this)}
                </Table.Body>
              </Table>
            )}
          {this.state.userData.trails != null &&
            this.state.userData.trails.length > 0 && (
              <Table
                compact
                singleLine
                color="black"
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Trail</Table.HeaderCell>
                    <Table.HeaderCell>Estimated Time</Table.HeaderCell>
                    <Table.HeaderCell>Points</Table.HeaderCell>
                    <Table.HeaderCell>Punctuation</Table.HeaderCell>
                    <Table.HeaderCell>Finished</Table.HeaderCell>
                    <Table.HeaderCell>Last access</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.userData.trails.map(function (trail) {
                    return (
                      <Table.Row key={trail.id}>
                        <Table.Cell>{trail.title}</Table.Cell>
                        <Table.Cell>
                          {this.displayDuration(trail.estimatedTime)}
                        </Table.Cell>
                        <Table.Cell>{trail.points}</Table.Cell>
                        <Table.Cell>{trail.score}</Table.Cell>
                        <Table.Cell>
                          {trail.isComplete === true ? 'Yes' : '-'}
                        </Table.Cell>
                        <Table.Cell>
                          {this.getLastUpdateInDays(trail.lastUpdateInDays)}
                        </Table.Cell>
                      </Table.Row>
                    )
                  }, this)}
                </Table.Body>
              </Table>
            )}
        </div>
      </>
    )
  }
}

export default StudentView
