import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Table,
  Button,
  Input,
  Message,
  Dropdown,
  TextArea,
  Checkbox
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class QuizQuestionSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      externalId: '',
      type: '',
      content: '',
      answer: '',
      categoryId: null,
      answers: []
    },
    categoryList: [],
    questionTypeList: [
      { key: 'MM', value: 'MM', text: 'MultipleAnswer' },
      { key: 'MS', value: 'MS', text: 'SingleAnswer' },
      { key: 'BT', value: 'BT', text: 'TrueFalse' },
      { key: 'BY', value: 'BY', text: 'YesNo' },
      { key: 'RA', value: 'RA', text: 'Agreement' },
      { key: 'RI', value: 'RI', text: 'Importance' },
      { key: 'RS', value: 'RS', text: 'Satisfaction' },
      { key: 'R0', value: 'R0', text: 'Scale 0-5' },
      { key: 'R1', value: 'R1', text: 'Scale 1-10' },
      { key: 'OL', value: 'OL', text: 'Open Line' },
      { key: 'OT', value: 'OT', text: 'Open Text' }
    ],
    formLang: {
      content: '',
      answer: '',
      answers: []
    },
    langList: this.props.langList,
    langDefault: this.props.langDefault,
    lang: this.props.lang
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadCategoryList = this.loadCategoryList.bind(this)
    this.receivedCategoryList = this.receivedCategoryList.bind(this)
    this.addNewLine = this.addNewLine.bind(this)
    this.removeLine = this.removeLine.bind(this)
    this.handleFormIndexChange = this.handleFormIndexChange.bind(this)
    this.handleFormIndexCheck = this.handleFormIndexCheck.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
    this.loadQuestionsLang = this.loadQuestionsLang.bind(this)
    this.receivedQuestionsLang = this.receivedQuestionsLang.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      var answers, i
      if (this.state.viewMode === 'EDIT') {
        answers = []
        const answersLang = []

        if (this.props.editData.answers !== null) {
          for (i = 0; i < this.props.editData.answers.length; i++) {
            answers.push({
              answer: this.props.editData.answers[i].answer,
              isCorrect: this.props.editData.answers[i].isCorrect
            })
            answersLang.push({
              answer: '',
              isCorrect: this.props.editData.answers[i].isCorrect
            })
          }
        }
        this.setState(function (state) {
          state.form.externalId = this.props.editData.externalId
          state.form.type = this.props.editData.type
          state.form.content = this.props.editData.content
          state.form.answer = this.props.editData.answer
          state.form.categoryId = this.props.editData.categoryId
          state.form.answers = answers
          state.formLang.answers = answersLang

          return state
        })
        this.loadCategoryList(
          this.props.editData.categoryId === null
            ? ''
            : this.props.editData.categoryExternalId
        )
      } else {
        answers = []
        for (i = 0; i < 5; i++) {
          answers.push({ answer: '', isCorrect: false })
        }
        this.setState(function (state) {
          state.form.externalId = ''
          state.form.type = ''
          state.form.content = ''
          state.form.answer = ''
          state.form.categoryId = null
          state.form.answers = answers
          return state
        })
        this.loadCategoryList('')
      }
    }
  }

  loadCategoryList(searchText) {
    RequestManager.requestResourceList(
      'quiz/category',
      searchText,
      this.receivedCategoryList
    )
  }

  receivedCategoryList(hasError, data) {
    var categories = []
    if (!hasError) {
      for (var item in data.categories) {
        categories.push({
          key: data.categories[item].id,
          value: data.categories[item].id,
          text:
            data.categories[item].externalId +
            ' - ' +
            data.categories[item].title
        })
      }
    }
    this.setState(function (state) {
      state.categoryList = categories
      return state
    })
  }

  loadQuestionsLang(language) {
    RequestManager.requestAPI(
      '/admin/quiz/question/view-lang',
      {
        id: this.props.id,
        language
      },
      this.receivedQuestionsLang
    )
  }

  receivedQuestionsLang(hasError, data) {
    if (!hasError) {
      const answers = []
      data.answers.forEach((answer) => {
        answers.push({
          sequence: answer.sequence,
          answer: answer.answerLang !== null ? answer.answerLang : ''
        })
      })
      this.setState((state) => {
        state.formLang.answers = answers
        state.formLang.answer = data.answerLang !== null ? data.answerLang : ''
        state.formLang.content =
          data.contentLang !== null ? data.contentLang : ''
        return state
      })
    }
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleFormIndexChange(event, index, fieldName, maxLength) {
    var data = event.target.value
    if (typeof maxLength != 'undefined') {
      data = data.substring(0, maxLength)
    }
    this.setState(function (state) {
      state.form.answers[index][fieldName] = data
      return state
    })
  }

  handleFormIndexCheck(event, index, fieldName) {
    this.setState(function (state) {
      state.form.answers[index][fieldName] =
        !state.form.answers[index][fieldName]
      return state
    })
  }

  handleFormIndexLangChange(event, index, fieldName, maxLength) {
    var data = event.target.value
    if (typeof maxLength != 'undefined') {
      data = data.substring(0, maxLength)
    }
    this.setState((state) => {
      state.formLang.answers[index][fieldName] = data
      return state
    })
  }

  indexToChar(index) {
    return String.fromCharCode(65 + index)
  }

  addNewLine() {
    this.setState(function (state) {
      state.form.answers.push({ answer: '', isCorrect: false })
      return state
    })
  }

  removeLine(index) {
    this.setState(function (state) {
      state.form.answers.splice(index, 1)
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  validateFields() {
    if (
      this.state.form.externalId === '' ||
      this.state.form.type === '' ||
      this.state.form.content === '' ||
      this.state.form.categoryId === null
    ) {
      return false
    }
    if (this.state.form.type === 'MM' || this.state.form.type === 'MS') {
      var countAnswers = 0
      var correctAnswers = 0
      for (var i = 0; i < this.state.form.answers.length; i++) {
        if (this.state.form.answers[i].answer > ' ') {
          countAnswers++
          if (this.state.form.answers[i].isCorrect) correctAnswers++
        }
      }
      if (countAnswers < 2) return false
      if (this.state.form.type === 'MM' && correctAnswers < 2) return false
      if (this.state.form.type === 'MS' && correctAnswers !== 1) return false
    }
    return true
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var answers = []
      if (this.state.form.type === 'MM' || this.state.form.type === 'MS') {
        for (var i = 0; i < this.state.form.answers.length; i++) {
          if (this.state.form.answers[i].answer > ' ') {
            answers.push({
              sequence: answers.length + 1,
              answer: this.state.form.answers[i].answer,
              isCorrect: this.state.form.answers[i].isCorrect
            })
          }
        }
      }
      var dataToSend = {
        externalId: this.state.form.externalId,
        type: this.state.form.type,
        categoryId: this.state.form.categoryId,
        content: this.state.form.content,
        answers: answers,
        answer: this.state.form.answer
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/quiz/question/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      const answers = []

      this.state.formLang.answers.forEach((answer, index) => {
        answers.push({
          sequence: answer.sequence,
          answerLang: answer.answer
        })
      })

      var dataToSend = {
        id: this.props.id,
        language: this.state.lang,
        contentLang: this.state.formLang.content,
        answers,
        answerLang: this.state.formLang.answer
      }

      RequestManager.requestAPI(
        '/admin/quiz/question/save-lang',
        dataToSend,
        this.receivedResponse
      ).then(()=> {
        this.setState(state => {
          state.lang = this.state.langDefault
          state.formLang.content = ''
          state.formLang.answer = ''
          state.formLang.answers = []
          return state
        })
      })
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'small'
        }
      >
        <Modal.Header>
          <Grid
            relaxed
            columns={this.state.lang !== this.state.langDefault ? 2 : 1}
          >
            <Grid.Column width={9}>
              <Icon name="question" />{' '}
              {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Question
            </Grid.Column>
            {this.state.viewMode === 'EDIT' && (
              <Grid.Column textAlign="right" width={7}>
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="world"
                  size="tinny"
                  value={this.state.lang}
                  options={this.state.langList}
                  placeholder="Choose Language"
                  onChange={(event, { value }) => {
                    this.loadQuestionsLang(value)
                    this.setState((state) => {
                      state.lang = value
                      return state
                    })
                  }}
                />
              </Grid.Column>
            )}
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Question not recorded</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            {this.state.lang === this.state.langDefault && (
              <Grid relaxed columns="2">
                <Grid.Column>
                  <Form.Field required>
                    <label>Code</label>
                    <Input
                      maxLength="100"
                      disabled={this.state.isLoading}
                      value={this.state.form.externalId}
                      onChange={(event) => {
                        this.handleFormChange(event, 'externalId')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field required>
                    <label>Type</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.type}
                      fluid
                      selection
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'type')
                      }}
                      options={this.state.questionTypeList}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}
            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              {this.state.lang === this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Category</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.categoryId}
                      fluid
                      selection
                      search
                      clearable
                      onSearchChange={(event, { searchQuery }) => {
                        this.loadCategoryList(searchQuery)
                      }}
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'categoryId')
                      }}
                      options={this.state.categoryList}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
              <Grid.Column>
                <Form.Field required>
                  <label>Question</label>
                  <TextArea
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    value={this.state.form.content}
                    onChange={(event) => {
                      this.handleFormChange(event, 'content')
                    }}
                    style={{ minHeight: 100 }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Question</label>
                    <TextArea
                      disabled={this.state.isLoading}
                      value={this.state.formLang.content}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'content')
                      }}
                      style={{ minHeight: 100 }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}

              {(this.state.form.type === 'MM' ||
                this.state.form.type === 'MS') && (
                <Grid.Column>
                  <Table
                    attached
                    compact="very"
                    singleLine
                    size="small"
                    className="LINK_UNDERLINE TABLE_FIXED"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>?</Table.HeaderCell>
                        <Table.HeaderCell>Answer</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Correct</Table.HeaderCell>
                        {this.state.lang === this.state.langDefault && (
                          <Table.HeaderCell width={1}>
                            <Button
                              floated="right"
                              basic
                              icon="plus"
                              color="black"
                              size="tiny"
                              onClick={this.addNewLine}
                            />
                          </Table.HeaderCell>
                        )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.form.answers.map((elem, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width={1}>
                            {this.indexToChar(index)}
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              disabled={
                                this.state.isLoading ||
                                this.state.lang !== this.state.langDefault
                              }
                              maxLength="255"
                              value={elem.answer}
                              onChange={(event) => {
                                this.handleFormIndexChange(
                                  event,
                                  index,
                                  'answer',
                                  255
                                )
                              }}
                              fluid
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              disabled={
                                this.state.isLoading ||
                                this.state.lang !== this.state.langDefault
                              }
                              checked={elem.isCorrect}
                              onChange={(event) => {
                                this.handleFormIndexCheck(
                                  event,
                                  index,
                                  'isCorrect'
                                )
                              }}
                            />
                          </Table.Cell>
                          {this.state.lang === this.state.langDefault && (
                            <Table.Cell width={1}>
                              <Button
                                icon="trash"
                                basic
                                size="tiny"
                                floated="right"
                                color="red"
                                onClick={(e) => {
                                  this.removeLine(index)
                                }}
                              />
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}

              {this.state.formLang.answers.length > 0 &&
                this.state.lang !== this.state.langDefault &&
                (this.state.form.type === 'MM' ||
                  this.state.form.type === 'MS') && (
                  <Grid.Column>
                    <Table
                      attached
                      compact="very"
                      singleLine
                      size="small"
                      className="LINK_UNDERLINE TABLE_FIXED"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={1}>?</Table.HeaderCell>
                          <Table.HeaderCell>Answer</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Correct</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.formLang.answers.map((elem, index) => (
                          <Table.Row key={index}>
                            <Table.Cell width={1}>
                              {this.indexToChar(index)}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                disabled={this.state.isLoading}
                                maxLength="255"
                                value={elem.answer}
                                onChange={(event) => {
                                  this.handleFormIndexLangChange(
                                    event,
                                    index,
                                    'answer',
                                    255
                                  )
                                }}
                                fluid
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Checkbox
                                disabled={
                                  this.state.isLoading ||
                                  this.state.lang !== this.state.langDefault
                                }
                                checked={elem.isCorrect}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                )}

              <Grid.Column>
                <Form.Field>
                  <label>Answer</label>
                  <TextArea
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    value={this.state.form.answer}
                    onChange={(event) =>
                      this.handleFormLangChange(event, 'answer')
                    }
                    style={{ minHeight: 200 }}
                  />
                </Form.Field>
              </Grid.Column>

              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field>
                    <label>Answer</label>
                    <TextArea
                      disabled={this.state.isLoading}
                      value={this.state.formLang.answer}
                      onChange={(event) =>
                        this.handleFormLangChange(event, 'answer')
                      }
                      style={{ minHeight: 200 }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.content = ''
                  state.formLang.answer = ''
                  state.formLang.answers = []
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default QuizQuestionSave
