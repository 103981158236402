import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class StepSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    id: this.props.id,
    trailId: this.props.trailId,
    type: this.props.type,
    isLoading: false,
    form: {
      title: '',
      hasError: false,
      type: '',
      videoId: null,
      challengePoints: 0,
      content: '',
      activity: '',
      lang: this.props.lang,
      estimatedTime: 0,
      parentStepId: null
    },
    formLang: {
      title: '',
      videoId: null,
      content: '',
      activity: ''
    },
    lang: this.props.lang,
    langList: this.props.langList,
    langDefault: this.props.langDefault,
    videoList: [],
    stepList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadVideoList = this.loadVideoList.bind(this)
    this.receivedVideoList = this.receivedVideoList.bind(this)
    this.loadStepList = this.loadStepList.bind(this)
    this.receivedStepList = this.receivedStepList.bind(this)
    this.receivedStepViewLang = this.receivedStepViewLang.bind(this)
    this.validateLangFields = this.validateLangFields.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.type = this.props.type
          if (this.props.editData != null) {
            state.id = this.props.id
            state.form.title = this.props.editData.title
            state.form.type = this.props.editData.type
            state.form.videoId = this.props.editData.videoId
            state.form.challengePoints = this.props.editData.challengePoints
            state.form.sequence = this.props.editData.sequence
            state.form.content = this.props.editData.content
            state.form.activity = this.props.editData.activity
            state.form.estimatedTime = this.props.editData.estimatedTime
            state.form.parentStepId = this.props.editData.parentStepId
          }
          return state
        })
        this.loadVideoList(
          this.props.editData.videoId === null
            ? ''
            : this.props.editData.videoData.externalId
        )
        this.loadStepList(
          this.props.editData.parentStepId === null
            ? ''
            : this.props.editData.parentStepTitle
        )
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.type = this.props.type
          state.form.title = ''
          state.form.type = ''
          state.form.videoId = null
          state.form.challengePoints = 0
          state.form.sequence = this.props.nextSequence
          state.form.content = ''
          state.form.activity = ''
          state.form.estimatedTime = 0
          state.form.parentStepId = null
          state.formLang.title = ''
          state.formLang.videoId = null
          state.formLang.content = ''
          state.formLang.activity = ''
          return state
        })
        this.loadVideoList('')
        this.loadStepList('')
      }
    }
  }

  loadVideoList(searchText, fieldName) {
    RequestManager.requestResourceList(
      'video',
      searchText,
      this.receivedVideoList
    )
  }

  receivedVideoList(hasError, data) {
    var videos = []
    if (!hasError) {
      for (var image in data.videos) {
        videos.push({
          key: data.videos[image].id,
          value: data.videos[image].id,
          text: data.videos[image].externalId + ' - ' + data.videos[image].title
        })
      }
    }
    this.setState(function (state) {
      state.videoList = videos
      return state
    })
  }

  loadStepList(searchText, fieldName) {
    RequestManager.requestAPI(
      '/admin/trailstep/list',
      {
        trailId: this.state.trailId,
        limit: 5,
        search: searchText
      },
      this.receivedStepList
    )
  }

  viewStepLang(language) {
    RequestManager.requestAPI(
      '/admin/trailstep/view-lang',
      {
        id: this.state.id,
        language
      },
      this.receivedStepViewLang
    )
  }

  receivedStepViewLang(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        state.formLang.title = data.titleLang !== null ? data.titleLang : ''
        state.formLang.videoId =
          data.videoIdLang !== null ? data.videoIdLang : null
        state.formLang.content =
          data.contentLang !== null ? data.contentLang : ''
        state.formLang.activity =
          data.activityLang !== null ? data.activityLang : ''
        return state
      })
    }
  }

  receivedStepList(hasError, data) {
    var steps = []
    if (!hasError) {
      for (var index in data.steps) {
        var step = data.steps[index]
        if (step.type === 'K') {
          steps.push({
            key: step.id,
            value: step.id,
            text: step.title
          })
        }
      }
    }
    this.setState(function (state) {
      state.stepList = steps
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleValueLangChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.formLang[name] = data
      } else {
        state.formLang[name] = null
      }
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        title: this.state.form.title,
        trailId: this.state.trailId,
        type: this.state.form.type,
        videoId: this.state.form.videoId,
        content: this.state.form.content,
        activity: this.state.form.activity,
        sequence: this.state.form.sequence,
        parentStepId: this.state.form.parentStepId,
        estimatedTime: parseFloat(this.state.form.estimatedTime),
        challengePoints: parseFloat(this.state.form.challengePoints)
      }
      if (this.state.type === 'knowledgeSteps') {
        dataToSend.type = 'K'
      }
      if (this.state.type === 'resourcesSteps') {
        dataToSend.type = 'R'
      }
      if (this.props.viewMode === 'EDIT') {
        dataToSend.id = this.state.id
      }
      RequestManager.requestAPI(
        '/admin/trailstep/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    if (!this.validateLangFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        id: this.state.id,
        language: this.state.lang,
        titleLang: this.state.formLang.title,
        contentLang: this.state.formLang.content,
        videoIdLang: this.state.formLang.videoId,
        activityLang: this.state.formLang.activity
      }
      RequestManager.requestAPI(
        '/admin/trailstep/save-lang',
        dataToSend,
        this.receivedResponse
      ).then(() => {
        this.setState(function (state) {
          state.lang = this.state.langDefault
          state.formLang.title = ''
          state.formLang.videoId = null
          state.formLang.content = ''
          state.formLang.activity = ''
          return state
        })
      })
    }
  }

  getTitleIcon(type) {
    switch (type) {
      case 'knowledgeSteps':
        return 'play'
      case 'resourcesSteps':
        return 'linkify'
      // case 'challengeSteps': return 'flag';
      default:
        return ''
    }
  }

  getTitleName(type) {
    switch (type) {
      case 'knowledgeSteps':
        return 'Step'
      case 'resourcesSteps':
        return 'Resource'
      default:
        return ''
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return this.state.form.title !== '' && this.state.form.content !== ''
  }

  validateLangFields() {
    return (
      this.state.formLang.title !== '' && this.state.formLang.content !== ''
    )
  }

  render() {
    var isKnowledge = this.state.type === 'knowledgeSteps'
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault ? 'fullscreen' : 'large'
        }
      >
        <Modal.Header>
          <Grid relaxed columns="2">
            <Grid.Column width={9}>
              <Icon name={this.getTitleIcon(this.props.type)} />
              {(this.state.viewMode === 'EDIT' ? 'Edit' : 'New') +
                ' ' +
                this.getTitleName(this.props.type)}
            </Grid.Column>
            <Grid.Column textAlign="right" width={7}>
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon="world"
                size="tinny"
                value={this.state.lang}
                options={this.state.langList}
                placeholder="Choose Language"
                onChange={(event, { value }) => {
                  this.viewStepLang(value)

                  this.setState((state) => {
                    state.lang = value
                    return state
                  })
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>
                {this.getTitleName(this.props.type)} no registration
              </Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={
                      this.state.isLoading ||
                      this.state.lang !== this.state.langDefault
                    }
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => {
                      this.handleFormChange(event, 'title')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Title</label>
                    <Input
                      maxLength="80"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.title}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'title')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>

            <Grid
              relaxed
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              {isKnowledge && (
                <>
                  <Grid.Column>
                    <Form.Field>
                      <label>Video</label>
                      <Dropdown
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        value={this.state.form.videoId}
                        fluid
                        selection
                        search
                        clearable
                        onSearchChange={(event, { searchQuery }) => {
                          this.loadVideoList(searchQuery, 'videoId')
                        }}
                        onChange={(event, { value }) => {
                          this.handleValueChange(value, 'videoId')
                        }}
                        options={this.state.videoList}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field>
                        <label>Video</label>
                        <Dropdown
                          disabled={this.state.isLoading}
                          value={this.state.formLang.videoId}
                          fluid
                          selection
                          search
                          clearable
                          onSearchChange={(event, { searchQuery }) => {
                            this.loadVideoList(searchQuery, 'videoId')
                          }}
                          onChange={(event, { value }) => {
                            this.handleValueLangChange(value, 'videoId')
                          }}
                          options={this.state.videoList}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </>
              )}
            </Grid>
            {isKnowledge ? (
              <>
                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Form.Field required>
                      <label>Content</label>
                      <TextArea
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        icon="tag"
                        value={this.state.form.content}
                        onChange={(event) => {
                          this.handleFormChange(event, 'content')
                        }}
                        style={{ minHeight: 180 }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field required>
                        <label>Content</label>
                        <TextArea
                          disabled={this.state.isLoading}
                          icon="tag"
                          value={this.state.formLang.content}
                          onChange={(event) => {
                            this.handleFormLangChange(event, 'content')
                          }}
                          style={{ minHeight: 180 }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>
                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Form.Field>
                      <label>Activity</label>
                      <TextArea
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        icon="tag"
                        value={this.state.form.activity}
                        onChange={(event) => {
                          this.handleFormChange(event, 'activity')
                        }}
                        style={{ minHeight: 180 }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field>
                        <label>Activity</label>
                        <TextArea
                          disabled={this.state.isLoading}
                          icon="tag"
                          value={this.state.formLang.activity}
                          onChange={(event) => {
                            this.handleFormLangChange(event, 'activity')
                          }}
                          style={{ minHeight: 180 }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  relaxed
                  columns={this.state.lang !== this.state.langDefault ? 2 : 1}
                >
                  <Grid.Column>
                    <Form.Field required>
                      <label>URL to access</label>
                      <TextArea
                        disabled={
                          this.state.isLoading ||
                          this.state.lang !== this.state.langDefault
                        }
                        icon="tag"
                        value={this.state.form.content}
                        onChange={(event) => {
                          this.handleFormChange(event, 'content')
                        }}
                        style={{ minHeight: 80 }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {this.state.lang !== this.state.langDefault && (
                    <Grid.Column>
                      <Form.Field required>
                        <label>URL to access</label>
                        <TextArea
                          disabled={this.state.isLoading}
                          icon="tag"
                          value={this.state.formLang.content}
                          onChange={(event) => {
                            this.handleFormLangChange(event, 'content')
                          }}
                          style={{ minHeight: 80 }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>
                {this.state.lang === this.state.langDefault && (
                  <>
                    <Grid relaxed columns="1">
                      <Grid.Column>
                        <Form.Field>
                          <label>
                            {this.state.form.parentStepId !== null &&
                            this.state.form.parentStepId > 0
                              ? 'Connected to Step'
                              : 'Select to Step'}
                          </label>
                          <Dropdown
                            disabled={
                              this.state.isLoading ||
                              this.state.lang !== this.state.langDefault
                            }
                            value={this.state.form.parentStepId}
                            fluid
                            selection
                            search
                            clearable
                            onSearchChange={(event, { searchQuery }) => {
                              this.loadStepList(searchQuery, 'parentStepId')
                            }}
                            onChange={(event, { value }) => {
                              this.handleValueChange(value, 'parentStepId')
                              if (value === '') {
                                this.loadStepList('', 'parentStepId')
                              }
                            }}
                            options={this.state.stepList}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid>
                    <Grid relaxed columns="3">
                      <Grid.Column>
                        <Form.Field>
                          <label>Estimated time</label>
                          <Input
                            disabled={this.state.isLoading}
                            icon="clock"
                            type="number"
                            value={this.state.form.estimatedTime}
                            onChange={(event) => {
                              this.handleFormChange(event, 'estimatedTime', 8)
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.title = ''
                  state.formLang.videoId = null
                  state.formLang.content = ''
                  state.formLang.activity = ''
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default StepSave
