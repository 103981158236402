import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  Modal,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import './SkillView.css'

import moment from 'moment'
import 'moment/locale/pt-br'

import RequestManager from '../RequestManager'
import SkillSave from './SkillSave'

class SkillView extends React.Component {
  state = {
    id: parseInt(this.props.match.params.id),
    isLoading: true,
    skillData: {},
    deleteModal: {
      isOpen: false
    },
    isModalOpen: false
  }

  constructor(props) {
    super(props)
    this.setModalVisible = this.setModalVisible.bind(this)
    this.openDeleteModal = this.openDeleteModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveSkill = this.confirmRemoveSkill.bind(this)
    this.skillDeleted = this.skillDeleted.bind(this)
    this.receivedSkillData = this.receivedSkillData.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    moment.locale('pt-BR')
  }

  componentDidMount() {
    this.loadSkillData()
  }

  loadSkillData() {
    this.setLoadingState(true)
    RequestManager.requestAPI(
      '/admin/skill/view',
      {
        id: this.state.id
      },
      this.receivedSkillData
    )
  }

  receivedSkillData(hasError, receivedData) {
    if (!hasError) {
      this.setState(function (state) {
        state.skillData = receivedData
        state.isLoading = false
        return state
      })
    }
  }

  setLoadingState(status) {
    this.setState(function (state) {
      state.isLoading = status
      return state
    })
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  removeSkill(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveSkill() {
    RequestManager.requestAPI(
      '/admin/skill/remove',
      {
        id: this.state.id
      },
      this.skillDeleted
    )
  }

  skillDeleted(hasError, data) {
    this.goBackToSkillList()
  }

  openDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  goBackToSkillList() {
    window.location.href = '/skill/list'
  }

  displayDatetime(value) {
    return value != null ? moment(value).format('lll') : '-'
  }

  onDoneModal() {
    this.loadSkillData()
  }

  render() {
    document.title = 'Skills :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment attached="top" color="black">
              <Grid relaxed columns="equal">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="bolt" />
                    <Header.Content>
                      {this.state.skillData.title}
                      <Header.Subheader>Skill</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column size={2}>
                  <Button
                    floated="right"
                    icon="trash"
                    color="red"
                    size="small"
                    onClick={this.openDeleteModal}
                  />
                  <Button
                    floated="right"
                    icon="edit"
                    content="Edit"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Form>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Group</label>
                      <div className="FORM_CONTENT">
                        {this.state.skillData.group}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Category</label>
                      <div className="FORM_CONTENT">
                        {this.state.skillData.category}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="1">
                  <Grid.Column>
                    <Form.Field>
                      <label>Title</label>
                      <div className="FORM_CONTENT">
                        {this.state.skillData.title}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Description</label>
                      <div>{this.state.skillData.description}</div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid relaxed columns="2">
                  <Grid.Column>
                    <Form.Field>
                      <label>Updated by</label>
                      <div className="FORM_CONTENT">
                        {this.state.skillData.updatedBy}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Last update</label>
                      <div className="FORM_CONTENT">
                        {this.displayDatetime(this.state.skillData.lastUpdate)}
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </Segment>
          </div>

          <SkillSave
            viewMode="EDIT"
            editData={this.state.skillData}
            id={this.state.id}
            open={this.state.isModalOpen}
            onDone={this.onDoneModal}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this Skill?"
            />
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button color="green" inverted onClick={this.confirmRemoveSkill}>
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default SkillView
