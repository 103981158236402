import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Dropdown,
  Button,
  Segment,
  Header,
  Input,
  Message
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class UserSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      gender: '',
      status: 'A',
      security: {
        user: false,
        student: false,
        mentorship: false,
        recruiter: false,
        content: false
      }
    }
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.firstName = this.props.editData.firstName
          state.form.lastName = this.props.editData.lastName
          state.form.email = this.props.editData.email
          state.form.gender = this.props.editData.gender
          state.form.status = this.props.editData.status
          state.form.security.user = this.props.editData.securityManagement
          state.form.security.student =
            this.props.editData.studentAdministration
          state.form.security.mentorship = this.props.editData.journeyMentorship
          state.form.security.recruiter = this.props.editData.recruiterSupport
          state.form.security.content = this.props.editData.contentManagement
          return state
        })
      } else {
        this.setState(function (state) {
          state.form.firstName = ''
          state.form.lastName = ''
          state.form.email = ''
          state.form.gender = ''
          state.form.status = ''
          state.form.security.user = false
          state.form.security.student = false
          state.form.security.mentorship = false
          state.form.security.recruiter = false
          state.form.security.content = false
          return state
        })
      }
    }
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleCheck(event, name) {
    this.setState(function (state) {
      state.form.security[name] = !state.form.security[name]
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        firstName: this.state.form.firstName,
        lastName: this.state.form.lastName,
        email: this.state.form.email,
        password: this.state.form.password,
        gender: this.state.form.gender,
        status: this.state.form.status,
        profile: 'ADMIN',
        securityManagement: this.state.form.security.user,
        studentAdministration: this.state.form.security.student,
        journeyMentorship: this.state.form.security.mentorship,
        recruiterSupport: this.state.form.security.recruiter,
        contentManagement: this.state.form.security.content
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/user/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.firstName !== '' &&
      this.state.form.lastName !== '' &&
      this.state.form.gender !== '' &&
      this.state.form.email !== '' &&
      (this.state.viewMode === 'NEW' || this.state.form.status !== '') &&
      (this.state.viewMode === 'EDIT' || this.state.form.password !== '')
    )
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="user" />
          {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} User
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>User not registered</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>First Name</label>
                  <Input
                    maxLength="20"
                    disabled={this.state.isLoading}
                    icon="address book"
                    value={this.state.form.firstName}
                    onChange={(event) => {
                      this.handleFormChange(event, 'firstName')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Last Name</label>
                  <Input
                    maxLength="40"
                    disabled={this.state.isLoading}
                    icon="address book"
                    value={this.state.form.lastName}
                    onChange={(event) => {
                      this.handleFormChange(event, 'lastName')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Email</label>
                  <Input
                    disabled={this.state.isLoading}
                    icon="at"
                    value={this.state.form.email}
                    onChange={(event) => {
                      this.handleFormChange(event, 'email')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Gender</label>
                  <Dropdown
                    disabled={this.state.isLoading}
                    value={this.state.form.gender}
                    fluid
                    selection
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'gender')
                    }}
                    options={[
                      { key: 'F', value: 'F', text: 'Female' },
                      { key: 'M', value: 'M', text: 'Male' }
                    ]}
                  />
                </Form.Field>
              </Grid.Column>

              {this.state.viewMode === 'NEW' && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Password</label>
                    <Input
                      disabled={this.state.isLoading}
                      icon="lock"
                      type="password"
                      value={this.state.form.password}
                      onChange={(event) => {
                        this.handleFormChange(event, 'password')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
              {this.state.viewMode === 'EDIT' && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Status</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.status}
                      fluid
                      selection
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'status')
                      }}
                      options={[
                        { key: 'A', value: 'A', text: 'Active' },
                        { key: 'B', value: 'B', text: 'Blocked' },
                        { key: 'C', value: 'C', text: 'Canceled' },
                        { key: 'E', value: 'E', text: 'Expired' }
                      ]}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            <Segment disabled={this.state.isLoading}>
              <Header as="h3">Permissões</Header>
              <Form.Checkbox
                label="Users"
                checked={this.state.form.security.user}
                onChange={(event) => {
                  this.handleCheck(event, 'user')
                }}
                disabled={this.state.isLoading}
              />
              <Form.Checkbox
                label="Students"
                checked={this.state.form.security.student}
                onChange={(event) => {
                  this.handleCheck(event, 'student')
                }}
                disabled={this.state.isLoading}
              />
              <Form.Checkbox
                label="Mentor"
                checked={this.state.form.security.mentorship}
                onChange={(event) => {
                  this.handleCheck(event, 'mentorship')
                }}
                disabled={this.state.isLoading}
              />
              <Form.Checkbox
                label="Recruiter"
                checked={this.state.form.security.recruiter}
                onChange={(event) => {
                  this.handleCheck(event, 'recruiter')
                }}
                disabled={this.state.isLoading}
              />
              <Form.Checkbox
                label="Content"
                checked={this.state.form.security.content}
                onChange={(event) => {
                  this.handleCheck(event, 'content')
                }}
                disabled={this.state.isLoading}
              />
            </Segment>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default UserSave
