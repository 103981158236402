import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Message,
  Dropdown
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class TrailSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    id: this.props.id,
    journeyId: this.props.journeyId,
    usedTrailsList: this.props.usedTrailsList,
    isLoading: false,
    form: {
      hasError: false,
      trailId: null,
      tagAlong: false,
      sequence: null
    },
    trailList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadTrailList = this.loadTrailList.bind(this)
    this.receivedTrailList = this.receivedTrailList.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.props.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          if (this.props.editData != null) {
            state.id = this.props.id
            state.form.trailId = this.props.editData.trailId
            state.form.sequence = this.props.editData.sequence
            state.form.tagAlong = this.props.editData.tagAlong
            state.trailList = [
              {
                key: this.props.editData.trailId,
                value: this.props.editData.trailId,
                text:
                  this.props.editData.trailExternalId +
                  ' - ' +
                  this.props.editData.trailTitle
              }
            ]
          }
          return state
        })
      } else {
        this.setState(function (state) {
          state.viewMode = this.props.viewMode
          state.form.trailId = null
          state.form.sequence = this.props.nextSequence
          state.form.tagAlong = false
          return state
        })
        this.loadTrailList('')
      }
    }
  }

  loadTrailList(searchText) {
    RequestManager.requestResourceList(
      'trail',
      searchText,
      this.receivedTrailList,
      50
    )
  }

  receivedTrailList(hasError, data) {
    var trails = []
    if (!hasError) {
      for (var trail in data.trails) {
        if (
          (this.props.viewMode === 'NEW' &&
            this.props.usedTrailsList.indexOf(data.trails[trail].id) < 0) ||
          this.props.viewMode === 'EDIT'
        ) {
          trails.push({
            key: data.trails[trail].id,
            value: data.trails[trail].id,
            text:
              data.trails[trail].externalId + ' - ' + data.trails[trail].title
          })
        }
      }
    }
    this.setState(function (state) {
      state.trailList = trails
      return state
    })
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleCheck(event, name) {
    this.setState(function (state) {
      state.form[name] = !state.form[name]
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        sequence: parseInt(this.state.form.sequence),
        tagAlong: this.state.form.tagAlong,
        journeyId: parseInt(this.state.journeyId),
        trailId: parseInt(this.state.form.trailId)
      }
      RequestManager.requestAPI(
        '/admin/journeytrail/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return this.state.form.trailId !== null
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size="small"
      >
        <Modal.Header>
          <Icon name="globe" />
          {(this.state.viewMode === 'EDIT' ? 'Edit' : 'New') + ' Trail'}
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Unregistered trail</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            <Grid relaxed columns="1">
              <Grid.Column>
                <Form.Field>
                  <label>Trail</label>
                  <Dropdown
                    disabled={
                      this.state.isLoading || this.state.viewMode === 'EDIT'
                    }
                    value={this.state.form.trailId}
                    fluid
                    selection
                    search
                    clearable
                    onSearchChange={(event, { searchQuery }) => {
                      this.loadTrailList(searchQuery)
                    }}
                    onChange={(event, { value }) => {
                      this.handleValueChange(value, 'trailId')
                    }}
                    options={this.state.trailList}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Checkbox
                  disabled={this.state.isLoading}
                  label="Release with previous"
                  checked={this.state.form.tagAlong}
                  onChange={(event) => {
                    this.handleCheck(event, 'tagAlong')
                  }}
                />
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={this.saveButtonHandler}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default TrailSave
