import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Icon,
  Header,
  Button,
  Pagination,
  Segment,
  Table,
  Grid,
  Loader,
  Dimmer,
  Modal,
  Input
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './ProjectList.css'

import RequestManager from '../RequestManager'
import DatetimeManager from '../DatetimeManager'

import ProjectSave from './ProjectSave'

class ProjectList extends React.Component {
  state = {
    isModalOpen: false,
    isLoading: false,
    projects: [],
    limit: 30,
    offset: 0,
    total: 0,
    totalPages: 0,
    activePage: 1,

    isListLoaded: false,
    search: '',

    deleteModal: {
      isOpen: false,
      id: null,
      name: '',
      email: ''
    }
  }

  constructor() {
    super()
    this.setModalVisible = this.setModalVisible.bind(this)
    this.receivedProjectList = this.receivedProjectList.bind(this)
    this.paginationClickHandler = this.paginationClickHandler.bind(this)
    this.refreshActivePage = this.refreshActivePage.bind(this)
    this.onDoneModal = this.onDoneModal.bind(this)
    this.closeDeleteModal = this.closeDeleteModal.bind(this)
    this.confirmRemoveProject = this.confirmRemoveProject.bind(this)
    this.projectDeleted = this.projectDeleted.bind(this)

    this.loadSearch = this.loadSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  setModalVisible(isVisible) {
    this.setState(function (state) {
      state.isModalOpen = isVisible
      return state
    })
  }

  onDoneModal() {
    this.loadProjectList()
  }

  componentDidMount() {
    this.loadProjectList()
  }

  loadProjectList() {
    RequestManager.requestAPI(
      '/admin/project/list',
      {
        offset: (this.state.activePage - 1) * this.state.limit,
        limit: this.state.limit
      },
      this.receivedProjectList
    )
  }

  receivedProjectList(hasError, data) {
    this.updateStateProjects(data)
  }

  updateStateProjects(data) {
    this.setState(function (state) {
      state.isLoading = false
      state.isListLoaded = true
      state.projects = data.projects
      state.total = data.total
      state.totalPages = Math.ceil(data.count / this.state.limit)
      return state
    })
  }

  paginationClickHandler(e, { activePage }) {
    RequestManager.requestAPI(
      '/admin/project/list',
      {
        offset: (activePage - 1) * this.state.limit,
        limit: this.state.limit,
        search: this.state.search
      },
      this.receivedProjectList
    )
    this.refreshActivePage(activePage)
  }

  loadSearch() {
    RequestManager.requestAPI(
      '/admin/project/list',
      {
        offset: 0,
        limit: this.state.limit,
        search: this.state.search
      },
      this.receivedProjectList
    )
    this.refreshActivePage(1)
  }

  clearSearch() {
    RequestManager.requestAPI(
      '/admin/project/list',
      {
        offset: 0,
        limit: this.state.limit,
        search: ''
      },
      this.receivedProjectList
    )
    this.setState(function (state) {
      state.search = ''
      return state
    })
    this.refreshActivePage(1)
  }

  handleFormChange(event, name) {
    const data = event.target.value
    this.setState(function (state) {
      state[name] = data
      return state
    })
  }

  refreshActivePage(activePage) {
    this.setState(function (state) {
      state.activePage = activePage
      return state
    })
  }

  removeAction(index) {
    this.openDeleteModal(index)
  }

  confirmRemoveProject() {
    RequestManager.requestAPI(
      '/admin/project/remove',
      {
        id: this.state.deleteModal.id
      },
      this.projectDeleted
    )
  }

  projectDeleted(hasError, data) {
    this.loadProjectList()
    this.closeDeleteModal()
  }

  openDeleteModal(index) {
    var selectedProject = this.state.projects[index]
    this.setState(function (state) {
      state.deleteModal.isOpen = true
      state.deleteModal.name = selectedProject.externalId
      state.deleteModal.id = selectedProject.id
      return state
    })
  }

  closeDeleteModal() {
    this.setState(function (state) {
      state.deleteModal.isOpen = false
      return state
    })
  }

  truncateText(text, length) {
    if (text != null && length > 10 && text.length > length) {
      return text.substring(0, length - 3) + '...'
    }
    return text
  }

  displayDatetime(timestamp) {
    return timestamp != null ? DatetimeManager.formatTimestamp(timestamp) : '-'
  }

  displayType(type) {
    switch (type) {
      case 'D':
        return 'Discover'
      case 'C':
        return 'Challenge'
      default:
        return '-'
    }
  }

  render() {
    document.title = 'Projects :: Pragma School'
    if (this.state.isLoading) {
      return (
        <Dimmer active className="animated fadeIn fast">
          <Loader />
        </Dimmer>
      )
    } else {
      return (
        <>
          <div className="PAGE_CONTAINER animated fadeIn">
            <Segment color="black">
              <Grid relaxed columns="equal" className="PAGE_HEADER">
                <Grid.Column floated="left">
                  <Header as="h3">
                    <Icon name="file" />
                    <Header.Content>
                      Projects
                      <Header.Subheader>All Projects</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    floated="right"
                    icon="plus"
                    content="New"
                    color="black"
                    size="small"
                    onClick={() => {
                      this.setModalVisible(true)
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Segment attached="top" color="black">
              <Grid relaxed>
                <Grid.Column width={6}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                    }}
                  >
                    <Input size="mini" placeholder="Search" fluid action>
                      <input
                        value={this.state.search}
                        onChange={(event) => {
                          this.handleFormChange(event, 'search')
                        }}
                      />
                      <Button icon="search" onClick={this.loadSearch} />
                      <Button basic icon="eraser" onClick={this.clearSearch} />
                    </Input>
                  </form>
                </Grid.Column>
                <Grid.Column floated="right">
                  <Pagination
                    disabled={this.state.totalPages <= 0}
                    floated="right"
                    size="mini"
                    boundaryRange={0}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={2}
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.paginationClickHandler}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            {this.state.projects &&
              <Table
                attached="bottom"
                compact
                singleLine
                color="black"
                size="small"
                className="LINK_UNDERLINE"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Title</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Description</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.projects.length === 0 && this.state.isListLoaded && (
                    <Table.Row>
                      <Table.Cell colSpan="4" singleLine>
                        <Header
                          size="medium"
                          style={{ textAlign: 'center', padding: 10 }}
                        >
                          No records to be displayed.
                          <Header.Subheader>
                            Clear your search or enter a record.
                          </Header.Subheader>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {this.state.projects.map(function (project, index) {
                    return (
                      <Table.Row key={project.id}>
                        <Table.Cell>{project.externalId}</Table.Cell>
                        <Table.Cell>
                          <Link to={'/project/view/' + project.id}>
                            {project.title}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          {this.truncateText(project.description, 80)}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            icon="trash"
                            basic
                            size="tiny"
                            floated="right"
                            color="red"
                            onClick={() => {
                              this.removeAction(index)
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }, this)}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="7">
                      <Pagination
                        disabled={this.state.totalPages <= 0}
                        floated="right"
                        size="mini"
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={2}
                        totalPages={this.state.totalPages}
                        activePage={this.state.activePage}
                        onPageChange={this.paginationClickHandler}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            }
          </div>

          <ProjectSave
            viewMode="NEW"
            open={this.state.isModalOpen}
            onDone={(id) => {
              this.onDoneModal(id)
            }}
            onCancelClick={() => {
              this.setModalVisible(false)
            }}
          />

          <Modal
            open={this.state.deleteModal.isOpen}
            basic
            size="small"
            className="animated fadeIn"
          >
            <Header
              icon="trash"
              content="Do you really want to delete this project?"
            />
            <Modal.Content>
              <p>
                <b>
                  {this.state.deleteModal.name}{' '}
                  <small>(#{this.state.deleteModal.id})</small>
                </b>
                <br />
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                color="red"
                inverted
                onClick={this.closeDeleteModal}
              >
                <Icon name="remove" /> No
              </Button>
              <Button
                color="green"
                inverted
                onClick={this.confirmRemoveProject}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )
    }
  }
}

export default ProjectList
