import React from 'react'
import 'animate.css'
import 'semantic-ui-css/semantic.min.css'
import {
  Modal,
  Icon,
  Form,
  Grid,
  Button,
  Input,
  Message,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

import RequestManager from '../RequestManager'

class TrailSave extends React.Component {
  state = {
    viewMode: this.props.viewMode,
    isLoading: false,
    hasError: false,
    form: {
      title: '',
      externalId: '',
      type: 'D',
      onlineTime: 0,
      workTime: 0,
      estimatedTime: 0,
      backgroundImageId: null,
      challengeTitle: '',
      challengeIntro: '',
      challengePoints: 0,
      quizType: '',
      quizQuestionOrder: '',
      quizPassingScore: 0,
      quizTimeLimit: 0,
      quizRetakeAllowed: false
    },
    formLang: {
      title: '',
      challengeTitle: '',
      challengeIntro: ''
    },
    lang: this.props.lang,
    langDefault: this.props.langDefault,
    langList: this.props.langList,
    imageList: [],
    projectList: []
  }

  previousVisible = null

  constructor(state) {
    super(state)
    this.saveButtonHandler = this.saveButtonHandler.bind(this)
    this.saveButtonLangHandler = this.saveButtonLangHandler.bind(this)
    this.receivedResponse = this.receivedResponse.bind(this)
    this.loadImageList = this.loadImageList.bind(this)
    this.receivedImageList = this.receivedImageList.bind(this)
    this.loadProjectList = this.loadProjectList.bind(this)
    this.receivedProjectList = this.receivedProjectList.bind(this)
    this.viewTrailLang = this.viewTrailLang.bind(this)
    this.receivedTrailViewLang = this.receivedTrailViewLang.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.previousVisible = this.props.open
    return true
  }

  componentDidUpdate() {
    if (this.previousVisible !== this.props.open && this.props.open) {
      if (this.state.viewMode === 'EDIT') {
        this.setState(function (state) {
          state.form.title = this.props.editData.title
          state.form.externalId = this.props.editData.externalId
          state.form.projectId = this.props.editData.projectId
          state.form.type = this.props.editData.type
          state.form.onlineTime = this.props.editData.onlineTime
          state.form.workTime = this.props.editData.workTime
          state.form.estimatedTime = this.props.editData.estimatedTime
          state.form.backgroundImageId = this.props.editData.backgroundImageId
          state.form.challengeTitle = this.props.editData.challengeTitle
          state.form.challengeIntro = this.props.editData.challengeIntro
          state.form.challengePoints = this.props.editData.challengePoints
          state.form.quizType = this.props.editData.quizType
          state.form.quizQuestionOrder = this.props.editData.quizQuestionOrder
          state.form.quizPassingScore = this.props.editData.quizPassingScore
          state.form.quizTimeLimit = this.props.editData.quizTimeLimit
          state.form.quizRetakeAllowed = this.props.editData.quizRetakeAllowed
          if (this.props.editData.backgroundImageId !== null) {
            var images = []
            images.push({
              key: this.props.editData.imageId,
              value: this.props.editData.imageId,
              text:
                this.props.editData.backgroundImageData.externalId +
                ' - ' +
                this.props.editData.backgroundImageData.title
            })
            state.imageList = images
          }
          if (this.props.editData.projectId !== null) {
            var projects = []
            projects.push({
              key: this.props.editData.projectId,
              value: this.props.editData.projectId,
              text: this.props.editData.projectTitle
            })
            state.projectList = projects
          }
          return state
        })
        if (this.props.editData.backgroundImageId === null) {
          this.loadImageList('')
        }
        if (this.props.editData.projectId === null) {
          this.loadProjectList('')
        }
      } else {
        this.setState(function (state) {
          state.form.title = ''
          state.form.externalId = ''
          state.form.projectId = null
          state.form.type = 'D'
          state.form.onlineTime = 0
          state.form.workTime = 0
          state.form.estimatedTime = 0
          state.form.backgroundImageId = null
          state.form.challengeTitle = ''
          state.form.challengeIntro = ''
          state.form.quizType = ''
          state.form.quizQuestionOrder = ''
          state.form.quizPassingScore = 0
          state.form.quizTimeLimit = 0
          state.form.quizRetakeAllowed = false
          return state
        })
        this.loadImageList('')
        this.loadProjectList('')
      }
    }
  }

  imageListTimeout = null
  loadImageList(searchText, fieldName) {
    RequestManager.requestResourceList(
      'image',
      searchText,
      this.receivedImageList
    )
  }

  receivedImageList(hasError, data) {
    var images = []
    if (!hasError) {
      for (var image in data.images) {
        images.push({
          key: data.images[image].id,
          value: data.images[image].id,
          text: data.images[image].externalId + ' - ' + data.images[image].title
        })
      }
    }
    this.setState(function (state) {
      state.imageList = images
      return state
    })
  }

  viewTrailLang(language) {
    RequestManager.requestAPI(
      '/admin/trail/view-lang',
      {
        id: this.props.id,
        language
      },
      this.receivedTrailViewLang
    )
  }

  loadProjectList(searchText) {
    RequestManager.requestResourceList(
      'project',
      searchText,
      this.receivedProjectList
    )
  }

  receivedProjectList(hasError, data) {
    var projects = []
    if (!hasError) {
      for (var index in data.projects) {
        projects.push({
          key: data.projects[index].id,
          value: data.projects[index].id,
          text:
            data.projects[index].externalId + ' - ' + data.projects[index].title
        })
      }
    }
    this.setState(function (state) {
      state.projectList = projects
      return state
    })
  }

  receivedTrailViewLang(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        state.formLang.title = data.titleLang ? data.titleLang : ''
        state.formLang.challengeTitle = data.challengeTitleLang
          ? data.challengeTitleLang
          : ''
        state.formLang.challengeIntro = data.challengeIntroLang
          ? data.challengeIntroLang
          : ''

        return state
      })
    }
  }

  handleFormChange(event, name, substring) {
    var data = event.target.value
    if (typeof substring != 'undefined') {
      data = data.substring(0, substring)
    }
    this.setState(function (state) {
      state.form[name] = data
      return state
    })
  }

  handleFormLangChange(event, name) {
    var data = event.target.value
    this.setState(function (state) {
      state.formLang[name] = data
      return state
    })
  }

  handleValueChange(value, name) {
    const data = value
    this.setState(function (state) {
      if (data > ' ') {
        state.form[name] = data
      } else {
        state.form[name] = null
      }
      return state
    })
  }

  handleCheck(event, name) {
    this.setState(function (state) {
      state.form[name] = !state.form[name]
      return state
    })
  }

  closePopUP() {
    this.setState(function (state) {
      state.isLoading = false
      state.hasError = false
      return state
    })
  }

  saveButtonHandler() {
    this.setLoadingState(true)
    if (!this.validateFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        title: this.state.form.title,
        externalId: this.state.form.externalId,
        projectId: this.state.form.projectId,
        type: this.state.form.type,
        onlineTime: parseFloat(this.state.form.onlineTime),
        workTime: parseFloat(this.state.form.workTime),
        estimatedTime: parseFloat(this.state.form.estimatedTime),
        backgroundImageId: parseFloat(this.state.form.backgroundImageId),
        challengeTitle: this.state.form.challengeTitle,
        challengeIntro: this.state.form.challengeIntro,
        challengePoints: parseFloat(this.state.form.challengePoints),
        quizType: this.state.form.quizType,
        quizQuestionOrder: this.state.form.quizQuestionOrder,
        quizPassingScore: parseFloat(this.state.form.quizPassingScore),
        quizTimeLimit: parseFloat(this.state.form.quizTimeLimit),
        quizRetakeAllowed: this.state.form.quizRetakeAllowed
      }
      if (this.state.viewMode === 'EDIT') {
        dataToSend.id = this.props.id
      }
      RequestManager.requestAPI(
        '/admin/trail/save',
        dataToSend,
        this.receivedResponse
      )
    }
  }

  saveButtonLangHandler() {
    this.setLoadingState(true)
    if (!this.validateLangFields()) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = true
        return state
      })
    } else {
      var dataToSend = {
        id: this.props.id,
        titleLang: this.state.formLang.title,
        language: this.state.lang,
        challengeTitleLang: this.state.formLang.challengeTitle,
        challengeIntroLang: this.state.formLang.challengeIntro
      }
      RequestManager.requestAPI(
        '/admin/trail/save-lang',
        dataToSend,
        this.receivedResponse
      ).then(() => {
        this.setState((state) => {
          state.lang = this.state.langDefault
          state.formLang.title = ''
          state.formLang.challengeTitle = ''
          state.formLang.challengeIntro = ''
          return state
        })
      })
    }
  }

  receivedResponse(hasError, data) {
    if (!hasError) {
      this.setState(function (state) {
        state.isLoading = false
        state.hasError = false
        return state
      })
      this.props.onCancelClick()
      if (typeof this.props.onDone === 'function') {
        this.props.onDone(data.id)
      }
      this.closePopUP()
    }
  }

  setLoadingState(isLoading) {
    this.setState(function (state) {
      state.isLoading = isLoading
      return state
    })
  }

  validateFields() {
    return (
      this.state.form.title !== null &&
      this.state.form.title.length > 3 &&
      this.state.form.externalId !== null &&
      this.state.form.externalId.length > 3 &&
      this.state.form.type !== null &&
      this.state.form.type > ' ' &&
      this.state.form.estimatedTime !== null &&
      this.state.form.estimatedTime > 0
    )
  }

  validateLangFields() {
    return this.state.formLang.title !== null
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        className="animated fadeInDown"
        size={
          this.state.lang !== this.state.langDefault
            ? 'fullscreen'
            : 'large'
        }
      >
        <Modal.Header>
          <Grid
            relaxed
            columns={this.state.lang !== this.state.langDefault ? 2 : 1}
          >
            <Grid.Column width={9}>
              <Icon name="road" />
              {this.state.viewMode === 'EDIT' ? 'Edit' : 'New'} Trail
            </Grid.Column>
            <Grid.Column textAlign="right" width={7}>
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon="world"
                size="tinny"
                value={this.state.lang}
                options={this.state.langList}
                placeholder="Choose Language"
                onChange={(event, { value }) => {
                  this.viewTrailLang(value)
                  this.setState(state => {
                    state.lang = value
                    return state
                  })
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          {this.state.hasError && (
            <Message negative className="animated fadeInDown">
              <Message.Header>Unregistered trail</Message.Header>
              <p>Check the required fields and try again</p>
            </Message>
          )}
          <Form>
            {this.state.lang === this.state.langDefault && (
              <Grid columns="2">
                <Grid.Column>
                  <Form.Field required>
                    <label>Code</label>
                    <Input
                      maxLength="100"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.form.externalId}
                      onChange={(event) => {
                        this.handleFormChange(event, 'externalId')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field required>
                    <label>Type</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.type}
                      fluid
                      selection
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'type')
                      }}
                      options={[
                        { key: 'D', value: 'D', text: 'Discovery' },
                        { key: 'C', value: 'C', text: 'Challenge' },
                        { key: 'Q', value: 'Q', text: 'Quiz' }
                      ]}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}

            <Grid columns="2">
              <Grid.Column>
                <Form.Field required>
                  <label>Title</label>
                  <Input
                    maxLength="80"
                    disabled={this.state.isLoading || this.state.lang !== this.state.langDefault}
                    icon="tag"
                    value={this.state.form.title}
                    onChange={(event) => {
                      this.handleFormChange(event, 'title')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field required>
                    <label>Title</label>
                    <Input
                      maxLength="80"
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.title}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'title')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
              {this.state.lang === this.state.langDefault && (
                <Grid.Column>
                  <Form.Field>
                    <label>Background image</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.backgroundImageId}
                      fluid
                      selection
                      search
                      clearable
                      onSearchChange={(event, { searchQuery }) => {
                        this.loadImageList(searchQuery, 'backgroundImageId')
                      }}
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'backgroundImageId')
                      }}
                      options={this.state.imageList}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            {this.state.lang === this.state.langDefault &&
            this.state.form.type !== 'Q' ? (
              <Grid relaxed columns="1">
                <Grid.Column>
                  <Form.Field>
                    <label>Project</label>
                    <Dropdown
                      disabled={this.state.isLoading}
                      value={this.state.form.projectId}
                      fluid
                      selection
                      search
                      clearable
                      onSearchChange={(event, { searchQuery }) => {
                        this.loadProjectList(searchQuery)
                      }}
                      onChange={(event, { value }) => {
                        this.handleValueChange(value, 'projectId')
                        if (value === '') {
                          this.loadProjectList('')
                        }
                      }}
                      options={this.state.projectList}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            ) : (
              this.state.lang === this.state.langDefault && (
                <>
                  <Grid relaxed columns="3">
                    <Grid.Column>
                      <Form.Field required>
                        <label>Quiz</label>
                        <Dropdown
                          disabled={this.state.isLoading}
                          value={this.state.form.quizType}
                          fluid
                          selection
                          onChange={(event, { value }) => {
                            this.handleValueChange(value, 'quizType')
                          }}
                          options={[
                            { key: 'A', value: 'A', text: 'Assessment' },
                            { key: 'E', value: 'E', text: 'Exam' },
                            { key: 'P', value: 'P', text: 'Practice' },
                            { key: 'S', value: 'P', text: 'Survey' }
                          ]}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field required>
                        <label>Order</label>
                        <Dropdown
                          disabled={this.state.isLoading}
                          value={this.state.form.quizQuestionOrder}
                          fluid
                          selection
                          onChange={(event, { value }) => {
                            this.handleValueChange(value, 'quizQuestionOrder')
                          }}
                          options={[
                            { key: 'C', value: 'C', text: 'Category' },
                            { key: 'R', value: 'R', text: 'Random' },
                            { key: 'S', value: 'S', text: 'Sequence' }
                          ]}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>&nbsp;</label>
                        <Form.Checkbox
                          disabled={this.state.isLoading}
                          label="Allows retake"
                          checked={this.state.form.quizRetakeAllowed}
                          onChange={(event) => {
                            this.handleCheck(event, 'quizRetakeAllowed')
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid relaxed columns="3">
                    <Grid.Column>
                      <Form.Field>
                        <label>Time limit</label>
                        <Input
                          disabled={this.state.isLoading}
                          icon="clock"
                          type="number"
                          value={this.state.form.quizTimeLimit}
                          onChange={(event) => {
                            this.handleFormChange(event, 'quizTimeLimit', 8)
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Score for Approval</label>
                        <Input
                          disabled={this.state.isLoading}
                          icon="clock"
                          type="number"
                          value={this.state.form.quizPassingScore}
                          onChange={(event) => {
                            this.handleFormChange(event, 'quizPassingScore', 8)
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Challenge Points</label>
                        <Input
                          disabled={this.state.isLoading}
                          icon="clock"
                          type="number"
                          value={this.state.form.challengePoints}
                          onChange={(event) => {
                            this.handleFormChange(event, 'challengePoints', 8)
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </>
              )
            )}

            {this.state.lang === this.state.langDefault && (
              <Grid relaxed columns="3">
                <Grid.Column>
                  <Form.Field>
                    <label>Online time</label>
                    <Input
                      disabled={this.state.isLoading}
                      icon="clock"
                      type="number"
                      value={this.state.form.onlineTime}
                      onChange={(event) => {
                        this.handleFormChange(event, 'onlineTime', 8)
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Working time</label>
                    <Input
                      disabled={this.state.isLoading}
                      icon="clock"
                      type="number"
                      value={this.state.form.workTime}
                      onChange={(event) => {
                        this.handleFormChange(event, 'workTime', 8)
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field required>
                    <label>Estimated time</label>
                    <Input
                      disabled={this.state.isLoading}
                      icon="clock"
                      type="number"
                      value={this.state.form.estimatedTime}
                      onChange={(event) => {
                        this.handleFormChange(event, 'estimatedTime', 8)
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            )}

            <Grid
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field>
                  <label>
                    Title of{' '}
                    {this.state.form.type !== 'Q' ? 'Challenge' : 'Quiz'}
                  </label>
                  <Input
                    disabled={this.state.isLoading || this.state.lang !== this.state.langDefault}
                    icon="tag"
                    value={this.state.form.challengeTitle}
                    onChange={(event) => {
                      this.handleFormChange(event, 'challengeTitle')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field>
                    <label>
                      Title of{' '}
                      {this.state.form.type !== 'Q' ? 'Challenge' : 'Quiz'}
                    </label>
                    <Input
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.challengeTitle}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'challengeTitle')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
            <Grid
              columns={this.state.lang !== this.state.langDefault ? 2 : 1}
            >
              <Grid.Column>
                <Form.Field>
                  <label>
                    Introduction of{' '}
                    {this.state.form.type !== 'Q' ? 'Challenge' : 'Quiz'}
                  </label>
                  <TextArea
                    disabled={this.state.isLoading || this.state.lang !== this.state.langDefault}
                    icon="tag"
                    value={this.state.form.challengeIntro}
                    onChange={(event) => {
                      this.handleFormChange(event, 'challengeIntro')
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              {this.state.lang !== this.state.langDefault && (
                <Grid.Column>
                  <Form.Field>
                    <label>
                      Introduction of{' '}
                      {this.state.form.type !== 'Q' ? 'Challenge' : 'Quiz'}
                    </label>
                    <TextArea
                      disabled={this.state.isLoading}
                      icon="tag"
                      value={this.state.formLang.challengeIntro}
                      onChange={(event) => {
                        this.handleFormLangChange(event, 'challengeIntro')
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            onClick={() => {
              this.props.onCancelClick()
              this.closePopUP()
              if(this.state.lang !== this.state.langDefault) {
                this.setState((state) => {
                  state.lang = this.state.langDefault
                  state.formLang.title = ''
                  state.formLang.challengeTitle = ''
                  state.formLang.challengeIntro = ''
                  return state
                })
              }
            }}
          >
            Cancel
          </Button>
          <Button
            loading={this.state.isLoading}
            primary
            onClick={() => {
              if(this.state.lang !== this.state.langDefault) {
                this.saveButtonLangHandler()
              } else {
                this.saveButtonHandler()
              }
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default TrailSave
